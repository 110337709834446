import * as restfulService from '@app/services/restfulService'
import {currencyParser} from '@mixins/helpers'

export const defaultProduct = {
    type: 'product',
    category_id: '',
    unit: 'UN',
    code: '',
    barcode: '',
    order: 0,
    description: '',
    purchase_price: '',
    price: '',
    control_stock: true,
    stock: 0,
    alert_minimum_stock: 0,
    active: true,
    kitchen_enabled: false,
    totem_enabled: false,
    use_plan_discount: true,
    nf_enabled: false,
    nf_validation_data: {
        errors: ''
    },
    nf_fields: {
      icms_origem: '',
      icms_situacao_tributaria: '',
      codigo_ncm: '',
      cfop: '',
      cest: '',
      iss_retido: false,
      aliquota: 0,
      item_lista_servico: '',
      codigo_tributario_municipio: '',
      codigo_cnae: '',
      pis_situacao_tributaria: '',
      cofins_situacao_tributaria: '',
    },
    tags: [],
  }

  export const save = (data) => {

    data.purchase_price = currencyParser(data.purchase_price)
    data.price = currencyParser(data.price)

    const nfseFields = [
      'iss_retido',
      'aliquota',
      'item_lista_servico',
      'codigo_tributario_municipio',
      'codigo_cnae'
    ]

    Object.keys(data.nf_fields).map(item => {
      if (data.type === 'service') {
        if (!nfseFields.includes(item)) {
          delete data.nf_fields[item]
        }
      } else {
        if (nfseFields.includes(item)) {
          delete data.nf_fields[item]
        }
      }
    })

    return restfulService.save('product', null, null, data)
  }