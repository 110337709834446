<script>
import GripoDialog from '@components/common/Dialog'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'
import DataService from '@app/services/dataService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {required} from 'vuelidate/lib/validators'
import {cloneDeep} from 'lodash'
import {defaultConfigData} from '@app/domains/system/tenant/service'
import {confirmDialog} from '@utils/flash'
import {transactionFeePercentage, withdrawAmount} from '@utils/pagarMe'
import {emitReloadSettingsType, SETTINGS_TYPES} from "../../../service";

const defaultPaymentAccount = {
  id: '',
  cpf_cnpj: '',
  bank_code: '',
  agency: '',
  account: '',
  digit: '',
  main: false,
  is_edit: false,
}

export default {
  components: {GripoDialog},
  name: 'online-payment',
  data() {
    return {
      isComponentLoading: true,
      isSubmitted: false,
      termsConditionsLink: 'https://documents.gripo.com.br/terms-and-conditions/payment-account',
      localForm: {...cloneDeep(defaultConfigData)},
      dependencies: {
        banks: [],
        tenant_payment_accounts: [],
      },
      paymentAccounts: [],
      paymentAccount: {
        data: {...defaultPaymentAccount},
        showDialog: false
      }
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    wsKeyTenantPaymentAccountUpdateChannel() {
      const event = 'tenantPaymentAccountUpdate'
      return {name: `${event}.${this.tenant.id}`, listen: `.${event}`}
    },
    isCountryBrazil() {
      return (this.tenant?.country_id || 1 === 1)
    },
    canUseGatewayPayments() {
      return this.isCountryBrazil
    },
    pagarMeTransactionFeePercentage() {
      return parseFloat(transactionFeePercentage || 0)
    },
    pagarMeWithdrawAmount() {
      return parseFloat(withdrawAmount || 0)
    },
    hasBasicPlan() {
      return (this.tenant?.modules?.basic ?? false)
    },
    hasProfessionalPlan() {
      return (this.tenant?.modules?.professional ?? false)
    },
    activePaymentAccounts() {
      return this.dependencies.tenant_payment_accounts.filter(item => item.status === 'active')
    },
  },
  validations: {
    paymentAccount: {
      data: {
        cpf_cnpj: {required},
        bank_code: {required},
        agency: {required},
        account: {required},
        digit: {required},
      }
    }
  },
  async mounted() {
    this.isComponentLoading = true
    this.startWebsocket()
    this.getDependencies()
    this.getPaymentAccounts()
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.wsKeyTenantPaymentAccountUpdateChannel.name)
          .listen(this.wsKeyTenantPaymentAccountUpdateChannel.listen, () => {
            this.getPaymentAccounts()
          })
    },
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'tenant_payment_account'},
          {domain: 'bank'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
        })
        resolve()
      })
    },
    getPaymentAccounts() {
      DataService.get([
        {domain: 'tenant_payment_account'},
      ]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
        this.isComponentLoading = false
      })
    },
    resetPaymentAccount(paymentAccount) {
      this.paymentAccount.data = {...defaultPaymentAccount}
      this.paymentAccount.is_edit = false
      if (paymentAccount) {
        this.paymentAccount.is_edit = true
        this.paymentAccount.data = {...this.paymentAccount.data, ...paymentAccount}
      }
    },
    handlePaymentAccount(paymentAccount) {
      if (!this.paymentAccount.showDialog) {
        this.resetPaymentAccount(paymentAccount)
      }
      this.paymentAccount.showDialog = !this.paymentAccount.showDialog
    },
    async savePaymentAccount() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente gravar essa conta?',
        text: 'Confira os dados para garantir que está tudo correto!',
        icon: 'warning'
      })
      if (confirm) {
        this.isComponentLoading = true
        restfulService.post('tenant', 'save-payment-account', null, {...this.paymentAccount.data})
            .then((response) => {
              notifyService.success()
              this.dependencies.tenant_payment_accounts = this.dependencies.tenant_payment_accounts.filter(item => item.id !== response.id)
              if (response.main && this.dependencies.tenant_payment_accounts.length) {
                this.dependencies.tenant_payment_accounts = this.dependencies.tenant_payment_accounts.map(item => {
                  item.main = false
                  return item
                })
              }
              this.dependencies.tenant_payment_accounts.push(response)
              this.handlePaymentAccount(null)
              emitReloadSettingsType(SETTINGS_TYPES.tenant_payment)
              bus.$emit('reload-session-info')
              this.isComponentLoading = false
            })
            .catch(e => {
              this.isComponentLoading = false
              console.log(e)
            })
      }
    },
    async deletePaymentAccount(paymentAccount) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente desativar essa conta?',
      })
      if (confirm) {
        this.isComponentLoading = true
        restfulService.post('tenant', 'delete-payment-account', null, {id: paymentAccount.id})
            .then(() => {
              emitReloadSettingsType(SETTINGS_TYPES.tenant_payment)
              notifyService.success()
              this.dependencies.tenant_payment_accounts = this.dependencies.tenant_payment_accounts.filter(item => item.id !== paymentAccount.id)
              this.isComponentLoading = false
            })
            .catch(e => {
              this.isComponentLoading = false
              console.log(e)
            })
      }
    },
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="{'tenant-card-settings-loading' : isComponentLoading}"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados..."
           v-if="canUseGatewayPayments">
    <div slot="header">
      <div>
        <span class="mr-1">Pagamento Online</span>
        <a class="el-button el-button--primary el-button--mini ml-2 mr-2" :href="termsConditionsLink" target="_blank">
          Termos de Uso
        </a>
        <el-tag type="success" size="mini" effect="dark" v-if="activePaymentAccounts.length">Ativo</el-tag>
        <el-tag type="danger" size="mini" v-else>Inativo</el-tag>
      </div>
      <el-button size="mini" type="warning" class="mr-1" @click="handlePaymentAccount(null)">
        <i class="fas fa-plus"></i>
        <span class="ml-1">Inserir Nova Conta</span>
      </el-button>
    </div>
    <el-alert :closable="false" class="mb-2" show-icon>
      <div slot="title">
        Ao ativar esse módulo você confirma que está de acordo com os termos de uso!
      </div>
    </el-alert>
    <el-alert type="success"
              show-icon
              :closable="false"
              class="mb-2"
              v-if="!activePaymentAccounts.length">
      <div slot="title">
        Insira uma conta bancária para ativar o pagamento online para reservas e torneios
      </div>
    </el-alert>
    <el-alert type="warning"
              show-icon
              :closable="false">
      <div slot="title">
        <strong>Taxas do Pagamento Online</strong>
      </div>
      <div class="fs-16">
        <strong>{{ pagarMeTransactionFeePercentage }}%</strong> por pagamento realizado e
        <strong>{{ pagarMeWithdrawAmount | currencyFormatter }}</strong> por saque
      </div>
    </el-alert>
    <div class="table-responsive mt-3" v-if="activePaymentAccounts.length">
      <table class="custom-table-list">
        <thead>
        <tr>
          <th width="30%">Banco</th>
          <th>Dados</th>
          <th class="btn-actions">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="clientPaymentAccount in activePaymentAccounts" :key="clientPaymentAccount.id">
          <td>
            <div v-if="clientPaymentAccount.bank">
              ({{ clientPaymentAccount.bank_code }}) {{ clientPaymentAccount.bank }}
            </div>
            <div class="mt-1" v-if="clientPaymentAccount.main">
              <el-tag type="success" size="mini" effect="dark">
                Conta Principal
              </el-tag>
            </div>
          </td>
          <td>
            <div>
              <strong>Agência: </strong> {{ clientPaymentAccount.agency }}
              <strong>Conta: </strong> {{ clientPaymentAccount.account }}-{{ clientPaymentAccount.digit }}
            </div>
            <div>
              <strong>CNPJ/CPF: </strong> {{ clientPaymentAccount.cpf_cnpj }}
            </div>
          </td>
          <td>
            <div class="btn-actions">
              <el-button type="info"
                         size="mini"
                         title="Edit conta de pagamento"
                         @click="handlePaymentAccount(clientPaymentAccount)">
                <i class="fas fa-pencil-alt"></i>
              </el-button>
              <el-button type="danger"
                         size="mini"
                         title="Desativar conta de pagamento"
                         @click="deletePaymentAccount(clientPaymentAccount)">
                <i class="fas fa-trash"></i>
              </el-button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <gripo-dialog :model.sync="paymentAccount.showDialog"
                  width="70vw"
                  :persistent="false"
                  content-card-class="modal-pdf"
                  :on-close="resetPaymentAccount">
      <template v-slot:header>Dados da Conta Bancária</template>
      <template v-slot:content>
        <el-form :model="paymentAccount.data" ref="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Banco"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.bank_code.$error }">
                <el-select v-model="paymentAccount.data.bank_code"
                           clearable
                           filterable
                           placeholder="Selecione um banco"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full"
                           @input="$v.paymentAccount.data.bank_code.$touch()">
                  <el-option v-for="item in dependencies.banks"
                             :key="item.id"
                             :label="`(${item.code}) ${item.name}`"
                             :value="item.code">
                  </el-option>
                </el-select>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.bank_code.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="CPF/CNPJ"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.cpf_cnpj.$error }">
                <el-input v-model="paymentAccount.data.cpf_cnpj"
                          :disabled="paymentAccount.is_edit"
                          @input="$v.paymentAccount.data.cpf_cnpj.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.cpf_cnpj.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="Agência sem o Digíto"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.agency.$error }">
                <el-input v-model="paymentAccount.data.agency"
                          @input="$v.paymentAccount.data.agency.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.agency.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="Conta"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.account.$error }">
                <el-input v-model="paymentAccount.data.account"
                          @input="$v.paymentAccount.data.account.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.account.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Dígito"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.digit.$error }">
                <el-input v-model="paymentAccount.data.digit"
                          @input="$v.paymentAccount.data.digit.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.digit.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Conta Principal">
            <el-switch v-model="paymentAccount.data.main"
                       on-color="#13ce66"
                       on-text="SIM"
                       off-color="#ff4949"
                       off-text="NÃO">
            </el-switch>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer/>
        <el-button type="success"
                   :disabled="isComponentLoading || $v.paymentAccount.data.$invalid"
                   @click="savePaymentAccount">
          <i class="fas fa-check"></i>
          <span class="ml-1">Salvar Dados</span>
        </el-button>
      </template>
    </gripo-dialog>
  </el-card>
</template>
