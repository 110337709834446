<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import ValidationMessage from '../../../../components/form/ValidationMessage'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import helpers from '@mixins/helpers'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton, ValidationMessage},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      form: {
        product_id: '',
        type: 'input',
        quantity: 0
      },
      dependencies: {
        products: []
      }
    }
  },
  validations: {
    form: {
      product_id: {required},
      type: {required},
      quantity: {required}
    }
  },
  mounted() {
    this.getDependencies()
    this.isLoadingData = false
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'product'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      data.price = this.currencyParser(data.price)
      restfulService.save('stock', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'stock.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Estoque"/>
    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Dados do Estoque</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="top">
        <el-form-item label="Tipo">
          <el-radio-group v-model="form.type">
            <el-radio-button label="input">Entrada</el-radio-button>
            <el-radio-button label="output">Saída</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Produto"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.product_id.$error }">
              <el-select v-model="form.product_id"
                         filterable
                         clearable
                         placeholder="Selecione um produto"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @input="$v.form.product_id.$touch()">
                <el-option
                    v-for="item in dependencies.products"
                    :key="item.id"
                    :label="item.description"
                    :value="item.id">
                </el-option>
              </el-select>
              <validation-message v-if="$v.form.product_id.$error"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Estoque"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.form.quantity.$error }">
              <el-input-number v-model="form.quantity"
                               :min="0"
                               :step="1"
                               @input="$v.form.quantity.$touch()"/>
              <validation-message v-if="$v.form.quantity.$error"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.$invalid"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="stock.index"/>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
