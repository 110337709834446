<script>
export default {
  name: 'credit-pack-status-label',
  props: {
    status: {
      type: String,
      default: 'created'
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  computed: {
    color() {
      if (this.status === 'active') {
        return 'success'
      } else if (this.status === 'expired') {
        return 'danger'
      } else if (this.status === 'used') {
        return 'info'
      }
      return 'default'
    },
    label() {
      if (this.status === 'active') {
        return 'Ativo'
      } else if (this.status === 'expired') {
        return 'Expirado'
      } else if (this.status === 'used') {
        return 'Utilizado'
      }
      return 'Aguardando Pgto.'
    }
  }
}
</script>

<template>
  <el-tag :size="size" :type="color">{{label}}</el-tag>
</template>
