<script>
export default {
  name: 'credit-pack-type-label',
  props: {
    type: {
      type: String,
      default: 'message'
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  computed: {
    color() {
      if (this.type === 'nf') {
        return 'warning'
      } else if (this.type === 'facial_id') {
        return 'default'
      }
      return 'success'
    },
    label() {
      if (this.type === 'nf') {
        return 'Notas Fiscais'
      } else if (this.type === 'facial_id') {
        return 'Reconhecimento Facial'
      }
      return 'Whatsapp'
    }
  }
}
</script>

<template>
  <el-tag :size="size" :type="color">{{label}}</el-tag>
</template>
