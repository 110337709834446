<script>
import ListHeader from '../../../../components/list/Header'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import FormContacts from '../../../../components/form/Contacts'
import CoachData from './CoachData'
import ClientFacerecon from './Facerecon.vue'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'
import {get as getCep} from '@app/services/cepService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required, email} from 'vuelidate/lib/validators'
import {cloneDeep, debounce} from 'lodash'
import {mapGetters} from 'vuex'

export default {
  components: {
    ListHeader,
    FormSaveButton,
    FormCancelButton,
    FormContacts,
    CoachData,
    ClientFacerecon,
  },
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingCity: false,
      isSearchingClient: false,
      isSearchingUser: false,
      searchClientModel: null,
      searchClient: null,
      searchUserModel: null,
      searchUser: null,
      searchCity: '',
      phonePrefix: '+55',
      formEnabled: false,
      updateInputMoney: false,
      selectedPlayersClass: [],
      form: {
        country_id: '',
        city_id: '',
        external_reference: '',
        name: '',
        nickname: '',
        cpf_cnpj: '',
        email: '',
        phone: '',
        notes: '',
        birth_date: '',
        status: 'active',
        contacts: [],
        is_coach: false,
        user: {
          country_id: '',
          city_id: '',
          name: '',
          cpf: '',
          email: '',
          phone: '',
          birth_date: '',
          side: '',
          sex: '',
          status: 'active'
        },
        address: {
          cep: '',
          street: '',
          number: '',
          neighborhood: '',
          complement: '',
          codigo_ibge: '',
          city_id: '',
          city: {
            id: '',
            state_id: '',
            name: '',
            name_with_state: '',
            state: {
              id: '',
              name: '',
              initials: '',
            }
          },
        },
        coach_config: {
          entries_by_coach: false,
          amount_class: 0
        },
        data: {
          inscricao_municipal: '',
        }
      },
      dependencies: {
        cities: [],
        clients: [],
        countries: [],
        players_class: [],
        users: []
      },
    }
  },
  validations: {
    form: {
      country_id: {required},
      name: {required},
      email: {email}
    }
  },
  computed: {
    ...mapGetters(['roles', 'permissions', 'tenant', 'hasProfessionalPlan', 'isCoach', 'hasFaceReconModuleEnable']),
    moneyMask() {
      return currencyConfig()
    },
    phoneMask() {
      const country = this.dependencies.countries.find(item => item.id === this.form.country_id)
      return this.countryPhoneMask(country?.code)
    },
    isCpf() {
      return this.form.cpf_cnpj && this.onlyNumbers(this.form.cpf_cnpj).length === 11
    }
  },
  watch: {
    'searchClient': debounce(function (value) {
      value && value !== this.searchClientModel && this.onSearchClient(value)
    }, 300),
    'searchUser': debounce(function (value) {
      value && value !== this.searchUserModel && this.onSearchUser(value)
    }, 300),
    searchCity: debounce(function (value) {
      value && value !== this.form.city_id && this.onSearchCities(value)
    }, 300),
    async 'form.address.cep'(value, oldValue) {
      const cep = this.onlyNumbers(value)
      const cepOldValue = this.onlyNumbers(oldValue)
      if (value && cepOldValue.length === 7) {
        if (cep.length === 8) {
          const cepFound = await getCep(cep)
          if (cepFound) {
            this.$refs.formAddressNumber.focus()
            this.form.address.street = cepFound.logradouro
            this.form.address.neighborhood = cepFound.bairro
            this.form.address.complement = cepFound.complemento
            this.form.address.codigo_ibge = cepFound.ibge
          }
        } else {
          this.form.address.street = ''
          this.form.address.neighborhood = ''
          this.form.address.number = ''
          this.form.address.complement = ''
          this.form.address.codigo_ibge = ''
        }
      }
    }
  },
  async mounted() {
    await this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      await this.getClient(id)
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
    if (!this.form.country_id) {
      const country = this.dependencies.countries.find(item => item.code === 'BRA')
      this.form.country_id = country.id
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'country'}, {domain: 'player_class'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    onSearchClient(query) {
      this.dependencies.clients.splice(0)
      this.isSearchingClient = true
      let data = {query}
      restfulService.post('client', 'autocomplete', null, data)
          .then((response) => {
            if (response.length > 0) {
              this.dependencies.clients = [...response]
            }
            this.dependencies.clients.push({id: 0, name: query, avatar: null})
            this.isSearchingClient = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    onSearchUser(query) {
      this.dependencies.users.splice(0)
      this.isSearchingUser = true
      let data = {query, only_users: true}
      restfulService.post('client', 'autocomplete', null, data)
          .then((response) => {
            if (response.length > 0) {
              this.dependencies.users = [...response]
            }
            this.isSearchingUser = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    onSearchCities(query) {
      this.dependencies.cities.splice(0)
      if (query !== '') {
        this.isSearchingCity = true
        const country = this.dependencies.countries.find(item => item.id === this.form.country_id)
        const data = {country_code: country.code, query: query}
        restfulService.post('city', 'autocomplete', null, data)
            .then((response) => {
              this.dependencies.cities = [...response]
              this.isSearchingCity = false
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    changeCountry(id) {
      const country = this.dependencies.countries.find(item => item.id === id)
      this.phonePrefix = '+' + country.phone_prefix
    },
    async resetClientAutocomplete() {
      return new Promise(resolve => {
        this.searchClientModel = null
        this.searchClient = null
        this.dependencies.clients.splice(0)
        resolve()
      })
    },
    async onSelectClient() {
      if (this.searchClient) {
        if (this.searchClientModel === 0) {
          this.form.name = this.searchClient
          this.formEnabled = true
          await this.resetClientAutocomplete()
        } else {
          const client = this.dependencies.clients.find(item => item.id === this.searchClientModel)
          if (client.type === 'client') {
            await this.getClient(client.id)
            await this.resetClientAutocomplete()
          } else if (client.type === 'user') {
            this.form.country_id = client.country_id
            this.form.city_id = client.city_id
            this.form.name = client.name
            this.form.email = client.email
            this.form.phone = client.phone
            this.form.cpf_cnpj = client.cpf
            this.form.avatar = client.avatar
            this.onSend()
          }
        }
      }
    },
    async onSelectUser() {
      const data = {user_id: this.searchUserModel}
      restfulService.post('client', 'update-user', this.form.id, data)
          .then(() => {
            this.getClient(this.form.id)
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    removeUserClient() {
      this.searchUserModel = ''
      this.form.user_id = ''
      this.onSelectUser()
    },
    async getClient(id) {
      return new Promise(resolve => {
        bus.$emit('show-loader')
        this.isEdit = true
        restfulService.get('client', null, id, null, ['contacts', 'addresses.city.state', 'city', 'country', 'players_class', 'user.country', 'user.city'])
            .then(response => {
              this.dependencies.cities.splice(0)
              if (response.city) {
                this.dependencies.cities.push(response.city)
              }
              if (response.players_class.length > 0) {
                this.selectedPlayersClass = response.players_class.map(item => item.player_class_id)
              }
              if (!response.user) {
                response.user = {...this.form.user}
              }
              if (!response.address) {
                response.address = {...this.form.address}
              }
              if (!response.coach_config) {
                response.coach_config = {...this.form.coach_config, ...response.coach_config}
              }
              if (!response.data) {
                response.data = {...this.form.data, ...response.data}
              }
              response.coach_config.amount_class = this.currencyFormatter(response.coach_config.amount_class)
              if (response.coach_config.grid) {
                delete response.coach_config.grid
              }
              this.form = {...this.form, ...response}
              if (response.addresses.length) {
                this.form.address = {...response.addresses[0]}
              }
              this.formEnabled = true
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
              resolve()
            })
      })
    },
    async activateUser() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      restfulService.put('user', 'activate', this.form.user.id, {})
          .then(response => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.isEdit = true
            this.getClient(this.form.id)
            this.$router.push({name: 'client.update', params: {id: response.id}})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    async onSend() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = cloneDeep(this.form)
        if (data.cpf_cnpj) {
          data.cpf_cnpj = this.onlyNumbers(data.cpf_cnpj)
        }
        if (data.phone) {
          data.phone = this.onlyNumbers(data.phone)
        }
        data.players_class = this.selectedPlayersClass
        if (data.coach_config.amount_class) {
          data.coach_config.amount_class = this.currencyParser(data.coach_config.amount_class)
        }
        if (Array.isArray(data.coach_config?.selected_grid) && data.coach_config.selected_grid.length) {
          delete data.coach_config.grid
          const selectedGrid = []
          data.coach_config.selected_grid.filter(item => typeof item === 'string').map(item => {
            const [weekday, court_id, start_hour, end_hour, interval] = item.split('-')
            selectedGrid.push({weekday, court_id, start_hour, end_hour, interval})
          })
          data.coach_config.selected_grid = [...selectedGrid]
        }
        restfulService.post('client', 'save', null, data)
            .then(response => {
              notifyService.success({hideLoader: true})
              this.isSubmitted = false
              this.isEdit = true
              this.getClient(response.id)
              if (!this.isEdit) {
                this.$router.push({name: 'client.update', params: {id: response.id}})
              }
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
              bus.$emit('hide-loader')
              reject(e)
            })
      })
    }
  }
}
</script>

<template>
  <div>
    <ListHeader title="Clientes"/>
    <el-form ref="form"
             :model="form"
             label-position="top">
      <el-row :gutter="20">
        <el-col :span="!isEdit ? 24 : 12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados do Cliente</span>
              <el-form-item class="p-0 m-0" label="Cadastro Ativo" v-if="isEdit">
                <el-switch v-model="form.status"
                           active-color="#13ce66"
                           active-value="active"
                           active-text="SIM"
                           inactive-color="#ff4949"
                           inactive-value="inactive"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
            </div>
            <el-form-item label="Buscar Cliente" v-if="!isEdit">
              <v-autocomplete v-model="searchClientModel"
                              :search-input.sync="searchClient"
                              :items="dependencies.clients"
                              clearable
                              single-line
                              @change="onSelectClient"
                              placeholder="Busque por Nome/Celular/Email/CPF"
                              no-data-text="Clientes não encontrados"
                              color="blue-grey lighten-2"
                              item-text="name"
                              item-value="id"
                              hide-details
                              hide-selected
                              no-filter>
                <template slot="item" slot-scope="data">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ data.item.name }}
                      <span v-if="data.item.id === 0"> (CRIAR NOVO CLIENTE)</span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title v-if="data.item.phone">
                      {{ data.item.phone | phone }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </el-form-item>
            <h4 class="mt-4" v-if="!isEdit">
              Dados Pessoais
              {{ !formEnabled ? '(Utilize o campo de pesquisa acima para buscar o cliente)' : '' }}
            </h4>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="País"
                              class="is-required"
                              style="margin-bottom: 0;"
                              :class="{ 'el-form-item--error': $v.form.country_id.$error }">
                  <v-select v-model="form.country_id"
                            :items="dependencies.countries"
                            item-value="id"
                            item-text="name"
                            outline
                            single-line
                            :error-messages="validationMessageField($v.form.country_id)"
                            @input="$v.form.country_id.$touch()"
                            @blur="$v.form.country_id.$touch()"
                            :disabled="!formEnabled"
                            @change="changeCountry">
                    <template slot="item" slot-scope="data">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ data.item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-select>
                  <div class="el-form-item__error" v-if="$v.form.country_id.$error">
                    Campo obrigatório
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Cidade">
                  <el-select v-model="form.city_id"
                             clearable
                             filterable
                             remote
                             :remote-method="onSearchCities"
                             :loading="isSearchingCity"
                             placeholder="Digite para buscar a cidade..."
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full"
                             :disabled="!formEnabled">
                    <el-option v-for="item in dependencies.cities"
                               :key="item.id"
                               :label="(item.name + (item.state ? ' - '+item.state.initials : '') + (item.country ? ' / '+item.country.code : ''))"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Nome Completo"
                              class="is-required"
                              :class="{ 'el-form-item--error': $v.form.name.$error }">
                  <el-input v-model="form.name"
                            :disabled="!formEnabled"/>
                  <div class="el-form-item__error" v-if="$v.form.name.$error">
                    Campo obrigatório
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Apelido">
                  <el-input v-model="form.nickname"
                            :disabled="!formEnabled"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Celular">
                  <el-input v-model="form.phone"
                            type="tel"
                            v-mask="phoneMask"
                            :disabled="!formEnabled">
                    <template slot="prepend">{{ phonePrefix }}</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="CPF/CNPJ/CI/DNI">
                  <el-input v-model="form.cpf_cnpj"
                            :disabled="!formEnabled"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="!!form.cpf_cnpj && !this.isCpf">
              <el-col :span="12">
                &nbsp;
              </el-col>
              <el-col :span="12">
                <el-form-item label="Inscrição Municipal">
                  <el-input v-model="form.data.inscricao_municipal"
                            :disabled="!formEnabled"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Email"
                              :class="{ 'el-form-item--error': $v.form.email.$error }">
                  <el-input v-model="form.email"
                            type="email"
                            :disabled="!formEnabled"
                            @input="$v.form.email.$touch()"/>
                  <div class="el-form-item__error" v-if="!$v.form.email.email">
                    Esse e-mail é inválido
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="this.isCpf ? 'Data de Nascimento' : 'Data de Abertura'">
                  <el-date-picker
                      v-model="form.birth_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      v-mask="'##/##/####'"
                      :disabled="!formEnabled"
                      placeholder="Data">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Código de Referência">
              <el-input v-model="form.external_reference"/>
            </el-form-item>
            <el-form-item label="Observações">
              <el-input type="textarea"
                        :rows="2"
                        :disabled="!formEnabled"
                        v-model="form.notes"/>
            </el-form-item>
          </el-card>

          <el-card class="el-card__save_form box-card mt-4" v-if="isEdit">
            <div slot="header">
              <span>Professor</span>
              <el-switch v-model="form.is_coach"
                         :disabled="!form.user_id"
                         class="mt-1"
                         active-text="SIM"
                         inactive-text="Não">
              </el-switch>
            </div>
            <el-alert type="error"
                      show-icon
                      :closable="false"
                      v-if="!form.user_id">
              <div slot="title">
                O professor precisa possuir um usuário no <a href="https://gripo.app"
                                                             target="_blank">https://gripo.app</a>
              </div>
              <div>
                Vincule o usuário dele para habilitar a opção de Professor
              </div>
            </el-alert>
            <coach-data v-if="form.is_coach && form.user_id && form.id"
                        :client-id="form.id"
                        :enabled.sync="form.is_coach"
                        :coach-config.sync="form.coach_config"/>
          </el-card>
        </el-col>
        <el-col :span="12">
          <template v-if="isEdit">
            <el-card class="el-card__save_form box-card">
              <template v-if="form.user_id">
                <div slot="header">
                  <span>Dados do Usuário Vinculado</span>
                  <el-button size="small"
                             type="danger"
                             @click="removeUserClient">
                    <i class="fas fa-user-minus"></i>
                    <span>Remover Vínculo com Usuário</span>
                  </el-button>
                </div>
                <el-alert type="warning" show-icon :closable="false" class="mb-4">
                  <slot name="title">
                    Os dados do usuário vinculado não podem ser alterados pelo clube.
                    Caso seja necessário uma alteração o usuário precisa logar no app e realizar a
                    alteração.
                  </slot>
                </el-alert>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="País"
                                  class="mb-0">
                      <v-select v-model="form.user.country_id"
                                :items="dependencies.countries"
                                item-value="id"
                                item-text="name"
                                outline
                                single-line
                                disabled>
                        <template slot="item" slot-scope="data">
                          <v-list-tile-avatar>
                            <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              {{ data.item.name }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </template>
                        <template v-slot:selection="{ item }">
                          <v-list-tile-avatar>
                            <img :src="`/static/flags/${item.flag_icon}`" alt="">
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>
                              {{ item.name }}
                            </v-list-tile-title>
                          </v-list-tile-content>
                        </template>
                      </v-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Cidade">
                      <el-input :value="form.user.city ? form.user.city.name_with_state : ''"
                                readonly/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Nome">
                      <el-input v-model="form.user.name"
                                readonly/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Email">
                      <el-input v-model="form.user.email"
                                readonly/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Celular">
                      <el-input v-model="form.user.phone"
                                type="tel"
                                v-mask="phoneMask"
                                readonly>
                        <template slot="prepend">{{ phonePrefix }}</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="CPF/CI/DNI">
                      <el-input v-model="form.user.cpf"
                                readonly/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-alert type="error" show-icon :closable="false" class="mb-4"
                          v-if="form.user.status === 'not_confirmed'">
                  <slot name="title">
                    <strong class="fs-16">Esse usuário não está ativo</strong>
                    <el-button size="small"
                               class="ml-3"
                               type="success"
                               @click="activateUser">
                      <i class="fas fa-user-check"></i>
                      <span>Ativar Usuário</span>
                    </el-button>
                  </slot>
                </el-alert>
              </template>
              <template v-else>
                <div slot="header">
                  <span>Vincular Usuário</span>
                </div>
                <el-form-item label="Buscar Usuário">
                  <v-autocomplete v-model="searchUserModel"
                                  :search-input.sync="searchUser"
                                  :items="dependencies.users"
                                  clearable
                                  single-line
                                  @change="onSelectUser"
                                  placeholder="Busque por Nome/Celular/Email/CPF"
                                  no-data-text="Usuários não encontrados"
                                  color="blue-grey lighten-2"
                                  item-text="name"
                                  item-value="id"
                                  hide-details
                                  hide-selected
                                  no-filter>
                    <template slot="item" slot-scope="data">
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ data.item.name }}
                        </v-list-tile-title>
                        <v-list-tile-sub-title>
                          <span v-if="data.item.email">{{ data.item.email }}</span>
                          <span v-if="data.item.phone"> - {{ data.item.phone }}</span>
                          <span v-if="data.item.cpf"> - {{ data.item.cpf }}</span>
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </template>
                  </v-autocomplete>
                </el-form-item>
              </template>
            </el-card>

            <div class="mt-4" v-if="hasFaceReconModuleEnable">
              <client-facerecon :client-id="form.id"
                                :has-user="!!form.user_id"
                                :has-face="(form.user && !!form.user.facerecon_at) || !!form.facerecon_at"
                                :get-client="getClient"/>
            </div>

            <div class="mt-4">
              <form-contacts :list="form.contacts"
                             :ownerId="form.id"
                             ownerType="client"
                             v-if="form.id"/>
            </div>

            <el-card class="el-card__save_form box-card mt-4">
              <div slot="header">
                <span>Endereço (Obrigatório para geração de NFSe)</span>
              </div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="CEP">
                    <el-input v-model="form.address.cep"
                              v-mask="'#####-###'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Endereço">
                    <el-input v-model="form.address.street"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Número">
                    <el-input v-model="form.address.number" ref="formAddressNumber"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Bairro">
                    <el-input v-model="form.address.neighborhood"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Complemento">
                    <el-input v-model="form.address.complement"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Código IBGE">
                    <el-input v-model="form.address.codigo_ibge"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="el-card__save_form box-card" v-if="false">
              <div slot="header">
                <span>Inscrições - Configuração de Categorias</span>
              </div>
              <el-form-item label="Categorias que o Jogador(a) pode se inscrever">
                <el-transfer v-model="selectedPlayersClass"
                             filterable
                             :data="this.dependencies.players_class"
                             :titles="['Todas Categorias', 'Categorias Permitidas']"
                             :props="{
                                      key: 'id',
                                      label: 'name_with_sex'
                                    }">
                </el-transfer>
              </el-form-item>
            </el-card>
          </template>
        </el-col>
      </el-row>
      <el-card class="el-card__btn_form">
        <el-form-item class="m-0">
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid || !formEnabled"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="client.index"/>
        </el-form-item>
      </el-card>

    </el-form>
  </div>
</template>
