<script>
export default {
  name: 'invoice-status-label',
  props: {
    status: String
  },
  computed: {
    statusText() {
      if (this.status === 'created') {
        return 'Aguardando'
      } else if (this.status === 'approved') {
        return 'Aprovada'
      } else if (this.status === 'not_authorized') {
        return 'Não Autorizada'
      } else if (this.status === 'queued') {
        return 'Na fila'
      } else {
        return 'Cancelada'
      }
    },
    chipColor() {
      if (this.status === 'created') {
        return 'info'
      } else if (this.status === 'approved') {
        return 'success'
      } else if (this.status === 'not_authorized' || this.status === 'queued') {
        return 'warning'
      } else {
        return 'error'
      }
    }
  }
}
</script>

<template>
  <v-chip :value="true"
          :color="chipColor"
          small
          outlined>
    <span class="white--text">{{ statusText }}</span>
  </v-chip>
</template>
