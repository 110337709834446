<script>
import GripoDialog from '../../../../../../components/common/Dialog'
import MatchEdit from './MatchEdit'
import MatchListResult from './MatchListResult'
import MatchAvailableType from './MatchAvailableType'
import MatchPairNames from './MatchPairNames.vue'

import DataService from '../../../../../../services/dataService'
import * as restfulService from '../../../../../../services/restfulService'
import * as notifyService from '../../../../../../services/notifyService'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'
import {mapGetters} from 'vuex'

import {clone, cloneDeep, debounce} from 'lodash'
import Draggable from 'vuedraggable'
import * as fileService from '@app/services/fileService'
import {confirmDialog, warningDialog} from '@utils/flash'

const defaultSwitchMatch = {
  id: null,
  date: '',
  hour: '',
  court_name: '',
  pair1_player1_id: '',
  pair1_player2_id: '',
  pair1_names: '',
  pair2_names: '',
  pair2_player1_id: '',
  pair2_player2_id: '',
  impediments: []
}

export default {
  components: {GripoDialog, Draggable, MatchEdit, MatchListResult, MatchAvailableType, MatchPairNames},
  props: {
    stageId: {
      type: Number,
      required: true
    },
    matches: {
      type: Number,
      required: true
    },
    stage: {
      type: Object,
      required: true
    }
  },
  mixins: [helpers],
  data() {
    return {
      isLoading: true,
      isSubmitted: false,
      isMatchesGenerated: false,
      list: [],
      listPairs: [],
      tabs: [],
      playerClassId: '',
      searchPlayer: '',
      searchPlayerText: '',
      currentTabSelected: '',
      currentDate: '',
      dialogVisible: false,
      showPairs: false,
      switchMatches: {
        match1: clone(defaultSwitchMatch),
        match2: clone(defaultSwitchMatch)
      },
      dependencies: {
        courts: [],
        stage_dates: [],
        stage_players_class: []
      },
      scoreboardConfig: {
        visible: false,
        golden_point: true,
        number_sets: 1,
        number_games: 9,
        tiebreak_last_set: true
      },
      timeUnavailable: {
        timeUnavailableDialogVisible: false,
        tabIndex: null,
        rowIndex: null,
        courtId: null,
        date: null,
        hour: null,
        notes: '',
      },
      checkin: {
        dialogVisible: false,
        time: this.timeNow(true),
        duration: 4,
        pair1: {
          id: '',
          player1: false,
          player1_name: '',
          player1_checkin_at: '',
          player2: false,
          player2_name: '',
          player2_checkin_at: '',
          names: ''
        },
        pair2: {
          id: '',
          player1: false,
          player1_name: '',
          player1_checkin_at: '',
          player2: false,
          player2_name: '',
          player2_checkin_at: '',
          names: ''
        },
      }
    }
  },
  computed: {
    ...mapGetters(['roles', 'tenant', 'currentUser']),
    websocketKeyMatchResultChange() {
      return 'matchResultChange'
    },
    websocketKeyMatchResultChangeName() {
      return `${this.websocketKeyMatchResultChange}.${this.tenant.id}.${this.stageId}`
    },
    websocketKeyMatchResultChangeListen() {
      return `.${this.websocketKeyMatchResultChange}`
    },
    hasSwitchMatchesSelected() {
      return this.switchMatches.match1.id !== null || this.switchMatches.match2.id !== null
    },
    matchesInGroupsWithoutDatetime() {
      const courtWithoutDatetime = this.tabs.find(item => item.without_datetime)
      if (courtWithoutDatetime) {
        return courtWithoutDatetime.items.filter(item => item.round === 99 && !item.datetime).length
      }
      return false
    },
    stageTitle() {
      return this.stage.name_with_circuit
    },
    switchMatchesMatch1Pair1Names() {
      return this.switchMatches.match1.pair1_names.replace('<br>', '')
    },
    switchMatchesMatch1Pair2Names() {
      return this.switchMatches.match1.pair2_names.replace('<br>', '')
    },
    switchMatchesMatch2Pair1Names() {
      return this.switchMatches.match2.pair1_names.replace('<br>', '')
    },
    switchMatchesMatch2Pair2Names() {
      return this.switchMatches.match2.pair2_names.replace('<br>', '')
    },
    filteredTabs() {
      if (this.searchPlayerText.length > 0) {
        const searchPlayerText = this.searchPlayerText.toLowerCase().trim()
        return this.tabs.map(court => {
          return {
            ...court,
            items: court.items.filter(item => {
              const player1 = (item?.pair1_player1_data?.name?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair1_player1_data?.cpf?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair1_player1_data?.email?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair1_player1_data?.phone?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair1_player2_data?.name?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair1_player2_data?.cpf?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair1_player2_data?.email?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair1_player2_data?.phone?.toLowerCase()?.includes(searchPlayerText));
              const player2 = (item?.pair2_player1_data?.name?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair2_player1_data?.cpf?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair2_player1_data?.email?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair2_player1_data?.phone?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair2_player2_data?.name?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair2_player2_data?.cpf?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair2_player2_data?.email?.toLowerCase()?.includes(searchPlayerText)
                  || item?.pair2_player2_data?.phone?.toLowerCase()?.includes(searchPlayerText));
              return player1 || player2;
            })
          }
        })
      }
      return this.tabs
    }
  },
  mounted() {
    this.isMatchesGenerated = this.matches > 0
    this.showPairs = false
    bus.$on('match-data', (match) => this.updateMatchData(match))
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.websocketKeyMatchResultChangeName)
          .listen(this.websocketKeyMatchResultChangeListen, ({matches}) => {
            if (matches && matches.length) {
              matches.forEach((match) => {
                this.updateMatchData(match)
              })
            }
          })
    },
    async openDialog() {
      bus.$emit('show-loader')
      this.isSubmitted = false
      this.list.splice(0)
      this.listPairs.splice(0)
      this.tabs.splice(0)
      this.searchPlayer = ''
      this.searchPlayerText = ''
      await this.getDependencies()
      if (this.dependencies.stage_dates.length > 0) {
        this.startWebsocket()
        const currentDate = this.dateMomentInstance(this.momentNow().format('YYYY-MM-DD'))
        const firstDate = this.dateMomentInstance(this.dependencies.stage_dates[0].date)
        const lastDate = this.dateMomentInstance(this.dependencies.stage_dates[this.dependencies.stage_dates.length - 1].date)
        let date = this.dependencies.stage_dates[0].date
        if (currentDate.isBetween(firstDate.format('YYYY-MM-DD'), lastDate.format('YYYY-MM-DD'), null, '[]')) {
          date = currentDate.format('YYYY-MM-DD')
        }
        await this.loadAllMatches(date)
        this.dialogVisible = true
      }
      this.isLoading = false
    },
    closeDialog() {
      window.Echo.channel(this.websocketKeyMatchResultChangeName)
          .stopListening(this.websocketKeyMatchResultChangeListen)
      bus.$off('update-match-data')
      bus.$emit('hide-loader')
      this.currentDate = ''
      this.searchPlayer = ''
      this.searchPlayerText = ''
      this.list.splice(0)
      this.listPairs.splice(0)
      this.tabs.splice(0)
      this.clearSwitchMatch()
    },
    getDependencies() {
      return new Promise(resolve => {
        this.isLoadingData = true
        DataService.get([
          {
            domain: 'stage_player_class',
            data: {stage_id: this.stageId},
            relations: ['player_class']
          },
          {
            domain: 'stage_date',
            data: {stage_id: this.stageId}
          },
          {
            domain: 'court'
          }
        ]).then((result) => {
          this.dependencies = {...result}
          resolve()
        })
      })
    },
    updateMatchData(match) {
      if (match) {
        if (match.date !== this.currentDate) {
          return
        }
        const courtIndex = this.tabs.findIndex(item => item.id === match.court_id)
        if (courtIndex > -1) {
          const matchIndex = this.tabs[courtIndex].items.findIndex(item => {
            return item.court_id === match.court_id
                && item.date === match.date
                && item.hour === match.hour
          })
          if (matchIndex > -1) {
            match.metadata.loading = true
            this.$set(this.tabs[courtIndex].items, matchIndex, match)
            setTimeout(() => {
              match.metadata.loading = false
              this.$set(this.tabs[courtIndex].items, matchIndex, match)
            }, 1000)
          }
        }
      }
    },
    getMatchesByDate(date, selectedTab) {
      return new Promise((resolve, reject) => {
        if (!date) {
          resolve()
        }
        bus.$emit('show-loader')
        this.isLoadingData = true
        restfulService.get('manage/stage/' + this.stageId + '/matches/' + date)
            .then((response) => {
              if (response?.stage?.scoreboard_config) {
                this.scoreboardConfig = {...this.scoreboardConfig, ...response?.stage?.scoreboard_config}
              }
              this.tabs = [...response.stage.matches[0].items ?? []]
              this.currentTabSelected = selectedTab || 'tab_' + this.tabs[0].id
              this.currentDate = date
              this.isLoadingData = false
              resolve()
            })
            .catch((e) => {
              this.isLoadingData = false
              console.log(e)
              reject()
            })
      })
    },
    async loadAllMatches(date) {
      return new Promise(async (resolve, reject) => {
        try {
          await this.getMatchesByDate(date, this.currentTabSelected)
          await this.getMatchesWithoutDatetime()
          resolve()
          bus.$emit('hide-loader')
        } catch (e) {
          bus.$emit('hide-loader')
          reject(e)
        }
      })
    },
    getMatchesWithoutDatetime() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        this.isLoadingData = true
        restfulService.get('manage/stage/' + this.stageId + '/matches-without-datetime')
            .then((response) => {
              let tabItem = this.tabs.find(court => court.without_datetime === true)
              let tabItemIndex = this.tabs.findIndex(court => court.without_datetime === true)
              tabItem.items = []
              this.tabs.splice(tabItemIndex, 1, tabItem)
              tabItem.items = [...response]
              this.tabs.splice(tabItemIndex, 1, tabItem)
              resolve()
            })
            .catch((e) => {
              this.isLoadingData = false
              console.log(e)
              reject()
            })
      })
    },
    generateMatches(playerClassId) {
      bus.$emit('show-loader')
      const data = {player_class_id: playerClassId || null}
      restfulService.put('stage', 'matches', this.stageId, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            bus.$emit('hide-loader')
            this.isMatchesGenerated = true
          })
          .catch((e) => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    destroyMatches(playerClassId) {
      const data = {player_class_id: playerClassId || null}
      restfulService.put('stage', 'destroy-matches', this.stageId, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            bus.$emit('hide-loader')
            this.isMatchesGenerated = false
          })
          .catch((e) => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    selectPlayerClass() {
      this.listPairs.splice(0)
      if (this.playerClassId) {
        const data = {
          stage_id: this.stageId,
          player_class_id: this.playerClassId,
          orderBy: [{column: 'group'}],
          status: {in: ['created', 'approved']}
        }
        restfulService.search('stage_pair', null, data, null, ['player1', 'player2'])
            .then((response) => {
              this.listPairs = response.data
            })
            .catch((e) => {
              console.log(e)
            })
      }
    },
    updateMatchPair(match, pairNumber, pairId) {
      bus.$emit('show-loader')
      const data = {
        match_id: match.id,
        pair_number: pairNumber,
        pair_id: pairId,
        clear: true
      }
      restfulService.post('match', 'pair-update', null, data)
          .then(async (response) => {
            notifyService.success({hideLoader: true})
            this.updateMatchData(response)
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    saveMatch(data) {
      bus.$emit('show-loader')
      restfulService.save('match', (!data.id ? 'store' : null), data.id, data)
          .then(async (response) => {
            notifyService.success({hideLoader: true})
            this.updateMatchData(response)
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    addPair(pair, match, tabIndex, pairNumber) {
      bus.$emit('show-loader')
      const pairObj = pair[0]
      if (!match.hour) {
        warningDialog({
          title: 'Você não pode colocar uma dupla em um jogo que não tem horário definido',
        })
        bus.$emit('hide-loader')
        return
      }
      const matchData = {
        id: match.id,
        date: this.currentDate,
        hour: match.hour,
        pair1_id: match.pair1_id,
        pair2_id: match.pair2_id,
        player_class_id: pairObj.player_class_id,
        stage_id: pairObj.stage_id,
        court_id: this.tabs[tabIndex].id
      }
      if (pairNumber === 1) {
        matchData.pair1_id = pairObj.id
      } else {
        matchData.pair2_id = pairObj.id
      }
      restfulService.post('pair', 'impediment', pairObj.id, matchData)
          .then(async (response) => {
            bus.$emit('hide-loader')
            if (response.message !== 'OK') {
              const {value: confirm} = await confirmDialog({
                title: 'Algum dos atletas da dupla tem impedimento nesse horario!',
                text: 'Deseja adicionar o jogo mesmo assim?',
                icon: 'warning',
              })
              if (confirm) {
                this.saveMatch(matchData, tabIndex)
              } else {
                pair.splice(0)
              }
            } else {
              this.saveMatch(matchData, tabIndex)
            }
          })
    },
    async deletePair(match, tabIndex, pairNumber) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remover essa dupla?',
      })
      if (confirm) {
        this.updateMatchPair(match, pairNumber, match[`pair${pairNumber}_id`])
      }
    },
    switchMatch(match) {
      if (this.switchMatches.match1.id === match.id) {
        warningDialog({
          title: 'Você não pode inverter o mesmo jogo',
        })
        return
      }
      let court
      if (!this.switchMatches.match1.id) {
        this.switchMatches.match1 = cloneDeep(match)
        court = this.tabs.find(item => item.id === this.switchMatches.match1.court_id)
        if (court) {
          this.switchMatches.match1.court_name = court.name
        }
      } else {
        this.switchMatches.match2 = cloneDeep(match)
        court = this.tabs.find(item => item.id === this.switchMatches.match2.court_id)
        if (court) {
          this.switchMatches.match2.court_name = court.name
        }
      }
    },
    setMatchHere(match) {
      const court = this.tabs.find(item => item.id === match.court_id)
      this.switchMatches.match2.court_name = court.name
      this.switchMatches.match2.court_id = match.court_id
      this.switchMatches.match2.date = match.date
      this.switchMatches.match2.hour = match.hour
      console.log(this.switchMatches)
    },
    clearSwitchMatch() {
      this.removeSwitchMatch(1)
      this.removeSwitchMatch(2)
    },
    removeSwitchMatch(number) {
      this.switchMatches['match' + number] = {...clone(defaultSwitchMatch)}
      if (number === 1 && this.switchMatches.match2.id === null) {
        this.switchMatches.match2 = {...clone(defaultSwitchMatch)}
      }
    },
    async saveSwitchMatch() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente inverter os jogos selecionados?',
        icon: 'warning',
      })
      if (confirm) {
        this.saveActionSwitchMatch()
      }
    },
    saveActionSwitchMatch(force) {
      force = force || false
      bus.$emit('show-loader')
      const data = cloneDeep(this.switchMatches)
      data.force = force
      restfulService.post('match', 'invert', null, data)
          .then(async (response) => {
            if (response.error !== undefined) {
              bus.$emit('hide-loader')
              const {value: confirm} = await confirmDialog({
                title: response.error,
                text: 'Deseja alterar os jogos mesmo assim?',
                icon: 'warning',
              })
              if (confirm) {
                this.saveActionSwitchMatch(true)
              }
            } else {
              notifyService.success({hideLoader: true})
              bus.$emit('show-loader')
              this.clearSwitchMatch()
              if (Array.isArray(response)) {
                response.forEach(match => {
                  this.updateMatchData(match)
                })
              }
              await this.getMatchesWithoutDatetime()
              bus.$emit('hide-loader')
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async commandDestroyMatches(command) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente remover os jogos?',
      })
      if (confirm) {
        this.destroyMatches(command ? parseInt(command) : null)
      }
    },
    commandGenerateMatches(command) {
      this.generateMatches(command ? parseInt(command) : null)
    },
    commandExportReport(command) {
      if (command === 'by-date') {
        bus.$emit('show-loader')
        fileService.get('pdf/stage', 'matches/by-date', this.stage.id, 'pdf', null, false, 'Tabela de Jogos por Quadra')
            .finally(() => bus.$emit('hide-loader'))
      } else {
        bus.$emit('show-loader')
        fileService.get('pdf/stage', 'matches', this.stage.id, 'pdf', null, false, 'Tabela de Jogos')
            .finally(() => bus.$emit('hide-loader'))
      }
    },
    async resetDatetime(match) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja enviar esse jogo para a aba Sem Horário Definido?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        restfulService.put('match', 'reset-datetime', match.id)
            .then(async (response) => {
              this.updateMatchData(response)
              await this.getMatchesWithoutDatetime()
              bus.$emit('hide-loader')
            })
      }
    },
    matchCssClass(match) {
      const classes = []
      if (match.id && this.switchMatches.match1.id === match.id) {
        classes.push('match-row-selected')
      }
      if (this.playerClassId && match.player_class_id === this.playerClassId) {
        classes.push('match-player-class-selected')
      }
      if (match.unavailable) {
        classes.push('row-danger')
      }
      return classes
    },
    showTimeUnavailableDialog(tabIndex, rowIndex, match) {
      this.timeUnavailable = {
        timeUnavailableDialogVisible: true,
        tabIndex,
        rowIndex,
        courtId: match.court_id,
        date: match.date,
        hour: match.hour,
        notes: ''
      }
    },
    getCourtMatches(court) {
      if (court.without_datetime && !!this.playerClassId) {
        return court.items.filter(item => item.player_class_id === this.playerClassId)
      }
      return court.items
    },
    setTimeUnavailable() {
      bus.$emit('show-loader')
      const url = 'manage/stage/' + this.stageId + '/matches'
      const data = {
        stage_id: this.stageId,
        court_id: this.timeUnavailable.courtId,
        date: this.timeUnavailable.date,
        hour: this.timeUnavailable.hour,
        notes: this.timeUnavailable.notes
      }

      restfulService.post(url, 'set-time-unavailable', null, data)
          .then(() => {
            this.tabs[this.timeUnavailable.tabIndex].items[this.timeUnavailable.rowIndex].unavailable = true
            this.tabs[this.timeUnavailable.tabIndex].items[this.timeUnavailable.rowIndex].unavailable_details.notes = data.notes
            this.timeUnavailable = {
              dialogIntervalVisible: false,
              tabIndex: null,
              rowIndex: null,
              courtId: null,
              date: null,
              hour: null,
              notes: '',
            }
            notifyService.success({hideLoader: true})
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    async removeTimeUnavailable(tabIndex, index, match) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja tornar esse horário disponível novamente?',
        icon: 'warning',
      })
      if (confirm) {
        console.log(tabIndex, index, match)
        const data = {
          stage_id: this.stageId,
          court_id: match.court_id,
          date: match.date,
          hour: match.hour
        }
        const url = 'manage/stage/' + this.stageId + '/matches'
        restfulService.put(url, 'set-time-available', null, data)
            .then(() => {
              this.tabs[tabIndex].items[index].unavailable = false
              notifyService.success({hideLoader: true})
            })
      }
    },
    onSearchPlayer: debounce(function (value) {
      this.searchPlayerText = value
    }, 300),
    checkIn(match) {
      this.checkin.time = `${this.timeNow(true)}`
      this.checkin.pair1.id = match.pair1_id
      this.checkin.pair1.player1 = false
      this.checkin.pair1.player1_name = match?.pair1_player1_name || ''
      this.checkin.pair1.player1_checkin_at = match?.pair1_player1_checkin_at || ''
      this.checkin.pair1.player2 = false
      this.checkin.pair1.player2_name = match?.pair1_player2_name || ''
      this.checkin.pair1.player2_checkin_at = match?.pair1_player2_checkin_at || ''
      this.checkin.pair2.id = match.pair2_id
      this.checkin.pair2.player1 = false
      this.checkin.pair2.player1_name = match?.pair2_player1_name || ''
      this.checkin.pair2.player1_checkin_at = match?.pair2_player1_checkin_at || ''
      this.checkin.pair2.player2 = false
      this.checkin.pair2.player2_name = match?.pair2_player2_name || ''
      this.checkin.pair2.player2_checkin_at = match?.pair2_player2_checkin_at || ''
      this.checkin.dialogVisible = true
    },
    onSaveCheckIn() {
      bus.$emit('show-loader')
      const data = {
        stage_id: this.stageId,
        date: this.currentDate,
        players: []
      }
      if (this.checkin.pair1.id) {
        if (this.checkin.pair1.player1) {
          data.players.push({
            pair_id: this.checkin.pair1.id,
            player_number: 1,
            duration: this.checkin.duration,
            created_at: `${this.currentDate} ${this.checkin.time}:00`,
          })
        }
        if (this.checkin.pair1.player2) {
          data.players.push({
            pair_id: this.checkin.pair1.id,
            player_number: 2,
            duration: this.checkin.duration,
            created_at: `${this.currentDate} ${this.checkin.time}:00`,
          })
        }
      }
      if (this.checkin.pair2.id) {
        if (this.checkin.pair2.player1) {
          data.players.push({
            pair_id: this.checkin.pair2.id,
            player_number: 1,
            duration: this.checkin.duration,
            created_at: `${this.currentDate} ${this.checkin.time}:00`,
          })
        }
        if (this.checkin.pair2.player2) {
          data.players.push({
            pair_id: this.checkin.pair2.id,
            player_number: 2,
            duration: this.checkin.duration,
            created_at: `${this.currentDate} ${this.checkin.time}:00`,
          })
        }
      }
      restfulService.post('registration_checkin', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.checkin.dialogVisible = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
  }
}

</script>


<template>
  <div class="stage-card card-matches">

    <div class="card-content">
      <div class="card-icon">
        <i class="fas fa-dot-circle"></i>
      </div>
      <div class="card-information">
        <div class="card-title">Jogos</div>
        <div class="card-subtitle">{{ stage.matches.groups_count }} jogos de grupos</div>
        <div class="card-subtitle">{{ stage.matches.groups_eliminatory_count }} jogos eliminatórios</div>
        <div class="card-subtitle" v-if="stage.matches.eliminatory_count > 0">
          {{ stage.matches.eliminatory_count }} jogos Mata-mata
        </div>

        <div class="card-buttons">
          <div class="box">
            <div class="col">
              <el-button @click="openDialog()" size="small" block>
                <i class="fas fa-cog"></i> <span>Gerenciar</span>
              </el-button>
            </div>
            <div class="col">
              <el-dropdown trigger="click"
                           key="commandExportReport"
                           @command="commandExportReport"
                           size="small">
                <el-button type="info"
                           size="small">
                  <i class="fas fa-file-pdf"></i>
                  <span>Relatórios</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="by-date">
                    <i class="fas fa-clock"></i>
                    <span class="ml-1">Horários por Data</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="matches-grid">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Quadro de Jogos</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="col">
              <el-dropdown trigger="click"
                           key="commandGenerateMatches"
                           @command="commandGenerateMatches"
                           size="small">
                <el-button size="small" type="primary">
                  <i class="fas fa-check"></i>
                  <span>Gerar</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="all">Todas Categorias</el-dropdown-item>
                  <el-dropdown-item v-for="(item, index) in stage.players_class"
                                    :key="index"
                                    :command="item.id">{{ item.name_with_sex }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="col">
              <el-dropdown trigger="click"
                           key="commandDestroyMatches"
                           @command="commandDestroyMatches"
                           size="small">
                <el-button type="danger"
                           size="small">
                  <i class="fas fa-times"></i>
                  <span>Remover</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="all">Todas categorias</el-dropdown-item>
                  <el-dropdown-item v-for="(item, index) in stage.players_class"
                                    :key="index"
                                    :command="item.id">{{ item.name_with_sex }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gripo-dialog
        width="30vw"
        top="1vh"
        :model.sync="timeUnavailable.timeUnavailableDialogVisible">
      <template v-slot:header>Definir Horário Como Indisponível</template>
      <template v-slot:content>
        <el-form>
          <el-row>
            <el-col>
              <el-input
                  placeholder="Descrição da indisponibilidade de horário"
                  v-model="timeUnavailable.notes">
              </el-input>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="secondary" @click="setTimeUnavailable">
          <i class="fas fa-check"></i>
          <span>Indisponilizar</span>
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog
        width="70vw"
        top="1vh"
        :model.sync="checkin.dialogVisible">
      <template v-slot:header>Check-in de Atletas: {{ currentDate | dateEnToBr }}</template>
      <template v-slot:content>
        <el-form>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Horário do Check-in" class="is-required">
                <el-input v-model="checkin.time"
                          placeholder="Horário"
                          v-mask="'##:##'">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Duração do Check-in (em horas)" class="is-required">
                <el-input-number v-model="checkin.duration"
                                 :step="1"
                                 :min="1">
                </el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12"><strong>Dupla{{ !stage.single ? ' 1' : '' }}</strong></el-col>
            <el-col :span="12" v-if="!stage.single"><strong>Dupla 2</strong></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <div>
                <el-checkbox v-model="checkin.pair1.player1">
                  <span>{{ checkin.pair1.player1_name }}</span>
                </el-checkbox>
                <span class="ml-2 fs-12 text-success" v-if="checkin.pair1.player1_checkin_at">
                  Último check-in: <strong>{{ checkin.pair1.player1_checkin_at | dateTimeEnToBr }}h</strong>
                </span>
              </div>
              <div>
                <el-checkbox v-model="checkin.pair1.player2">
                  <span>{{ checkin.pair1.player2_name }}</span>
                </el-checkbox>
                <span class="ml-2 fs-12 text-success" v-if="checkin.pair1.player2_checkin_at">
                  Último check-in: <strong>{{ checkin.pair1.player2_checkin_at | dateTimeEnToBr }}h</strong>
                </span>
              </div>
            </el-col>
            <el-col :span="12" v-if="!stage.single">
              <div>
                <el-checkbox v-model="checkin.pair2.player1">
                  <span>{{ checkin.pair2.player1_name }}</span>
                </el-checkbox>
                <span class="ml-2 fs-12 text-success" v-if="checkin.pair2.player1_checkin_at">
                  Último check-in: <strong>{{ checkin.pair2.player1_checkin_at | dateTimeEnToBr }}h</strong>
                </span>
              </div>
              <div>
                <el-checkbox v-model="checkin.pair2.player2">
                  <span>{{ checkin.pair2.player2_name }}</span>
                </el-checkbox>
                <span class="ml-2 fs-12 text-success" v-if="checkin.pair2.player2_checkin_at">
                  Último check-in: <strong>{{ checkin.pair2.player2_checkin_at | dateTimeEnToBr }}h</strong>
                </span>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary" @click="onSaveCheckIn">
          <i class="fas fa-check"></i>
          <span>Fazer Check-in</span>
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="dialogVisible"
                  :big="true"
                  :actions-close-btn="false"
                  :custom-dialog-class="hasSwitchMatchesSelected ? 'invert-matches-opened': ''"
                  :on-close="closeDialog">
      <template v-slot:header>Gerenciar Jogos</template>
      <template v-slot:content>
        <el-row :gutter="10" style="margin-bottom: 8px;">
          <el-col :span="6" :xs="24">
            <el-input size="small"
                      v-model="searchPlayer"
                      @input="onSearchPlayer"
                      placeholder="Pesquisar atletas"/>
            <div class="mt-2">
              <strong>Mostrar Categorias</strong>
              <span> | </span>
              <el-switch v-model="showPairs"
                         active-text="Sim"
                         inactive-text="Não">
              </el-switch>
            </div>
          </el-col>
          <el-col :span="18" :xs="24">
            <el-button-group class="match-dates">
              <el-button v-for="(item, index) in dependencies.stage_dates"
                         :key="index"
                         size="large"
                         icon="el-icon-date"
                         :class="{'el-button--primary el-button--active' : item.date === currentDate}"
                         @click="loadAllMatches(item.date)" plain>
                {{ item.date | dateEnToBr }}
              </el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="5" v-if="showPairs" :xs="24" style="margin-bottom: 15px;">
            <v-select :items="dependencies.stage_players_class"
                      v-model="playerClassId"
                      @change="selectPlayerClass"
                      item-text="player_class.name_with_sex"
                      item-value="player_class_id"
                      single-line
                      hide-details
                      outline
                      label="Categoria"
                      placeholder="Selecione uma Categoria"
                      no-data-text="Nenhum registro encontrado"
                      clearable/>
            <el-card class="el-card box-card draggable-list draggable-list-scrollable matches-pairs-list mt-3">
              <div slot="header">
                <span>Duplas</span>
              </div>
              <Draggable class="draggable-component"
                         v-model="listPairs"
                         :options="{group:{name:'pairs',pull:'clone',revertClone:true}, scroll:true}"
                         @start="drag=true"
                         @end="drag=false">
                <div v-for="pair in listPairs"
                     :key="pair.id">
                  [ <strong>{{ pair.group }}</strong> ] {{ pair.names }}
                </div>
              </Draggable>
            </el-card>
          </el-col>
          <el-col :span="showPairs ? 19 : 24" :xs="24">

            <v-alert v-if="matchesInGroupsWithoutDatetime > 0" class="mb-2"
                     size="small"
                     :value="true"
                     dismissible
                     type="warning"
                     outline>
              <slot name="content">
                Existem jogos da fase de grupos na aba <strong>Sem horário definido</strong>
              </slot>
            </v-alert>

            <el-tabs class="matches-courts"
                     v-model="currentTabSelected"
                     :class="{'has-switch-matchs' : hasSwitchMatchesSelected}"
                     type="card">
              <el-tab-pane v-for="(court, tabIndex) in filteredTabs"
                           :key="court.id"
                           :name="'tab_' + court.id">
                <div slot="label">
                  {{ court.name }}
                </div>
                <div class="table-responsive">
                  <table class="custom-table-list matches-table">
                    <thead>
                    <tr>
                      <th class="text-center hidden-xs-only">Fase</th>
                      <th class="text-center hidden-xs-only">Categ.</th>
                      <th class="text-center hidden-xs-only">Chave/Jogo</th>
                      <th class="text-center">Horário</th>
                      <th class="text-center hidden-sm-and-up">
                        Placar
                      </th>
                      <th class="text-right">Dupla 1</th>
                      <th colspan="3" class="text-center" style="min-width: 90px;">Resultado</th>
                      <th>Dupla 2</th>
                      <th class="btn-actions min-w70">
                        Alterar <br>Jogo
                      </th>
                      <th class="btn-actions hidden-xs-only">
                        Placar
                      </th>
                      <th class="btn-actions">
                        Check-in
                      </th>
                      <th class="text-center hidden-sm-and-up">Fase</th>
                      <th class="text-center hidden-sm-and-up">Categ.</th>
                      <th class="text-center hidden-sm-and-up">Chave/Jogo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(match,index) in getCourtMatches(court)"
                        :key="index"
                        class="match-row"
                        :class="matchCssClass(match)">
                      <td colspan="50" v-if="match.metadata.loading">
                        <div class="match-loading el-loading-mask-light" v-loading="true"/>
                      </td>
                      <template v-else>
                        <td class="round hidden-xs-only">{{ match.round_name }}</td>
                        <td class="player-class hidden-xs-only">
                          <span>{{ match.player_class_label }}</span></td>
                        <td class="round-game hidden-xs-only">
                        <span>
                          {{
                            match.id ? (match.round === 0 || match.round === 99 ? 'Chave ' + match.group : (match.number ? 'Jogo ' + match.number : '')) : ''
                          }}
                        </span>
                        </td>
                        <td class="hour">
                          <div class="flex-c">
                            {{ match.hour }}
                            <template v-if="!match.id">
                              <el-button
                                  v-if="!match.unavailable"
                                  type="warning"
                                  size="mini"
                                  circle
                                  class="el-button--extra-small-circle"
                                  title="Indisponilizar horário"
                                  @click="showTimeUnavailableDialog(tabIndex, index, match)">
                                <i class="fas fa-times"></i>
                              </el-button>
                              <el-button v-else
                                         type="success"
                                         circle
                                         size="mini"
                                         class="el-button--extra-small-circle"
                                         title="Remover indisponibilidade de horário"
                                         @click="removeTimeUnavailable(tabIndex, index, match)">
                                <i class="fas fa-check"></i>
                              </el-button>
                            </template>
                            <match-available-type
                                v-if="false && switchMatches.match1.id && switchMatches.match1.id !== match.id"
                                :current-date="currentDate"
                                :match="match"
                                :courts="tabs"
                                :selected-match1="switchMatches.match1"
                                :selected-match2="switchMatches.match2"
                                :interval="court.interval"
                                :impediments="switchMatches.match1.impediments"/>
                          </div>
                        </td>
                        <template v-if="match.unavailable">
                          <td colspan="7">{{ match.unavailable_details.notes }}</td>
                        </template>
                        <template v-else>
                          <td class="hidden-sm-and-up">
                            <match-edit v-if="match.id"
                                        :courts="tabs"
                                        :stage-id="stageId"
                                        :match="match"
                                        :stage-title="stageTitle"
                                        :scoreboard-key="court.key"
                                        :scoreboard-config="scoreboardConfig"
                                        @update:match-data="updateMatchData"/>
                          </td>
                          <td :class="['pair', 'pair1', {'no-padding': $vuetify.breakpoint.xsOnly}]">
                            <Draggable class="match-pair-draggable"
                                       v-if="!match.pair1_id"
                                       v-model="match.pair1"
                                       :options="{sort:false,group:{name:'pairs',pull: false}, scroll:false}"
                                       @add="addPair(match.pair1, match, tabIndex, 1)">
                              <match-pair-names v-if="match.pair1_names"
                                                :pair-names="match.pair1_names"
                                                :player1-checkin-at="match?.pair1_player1_checkin_at"
                                                :player2-checkin-at="match?.pair1_player2_checkin_at"/>
                            </Draggable>
                            <template v-else-if="match.pair1_id || match.round > 0">
                              <div class="flex-c -inline -jc-ce">
                                <match-pair-names v-if="match.pair1_names"
                                                  :data-id="match.pair1_id"
                                                  :pair-names="match.pair1_names"
                                                  :player1-checkin-at="match?.pair1_player1_checkin_at"
                                                  :player2-checkin-at="match?.pair1_player2_checkin_at"/>
                                <el-button
                                    v-if="match.pair1_id && match.sets.length === 0"
                                    @click="deletePair(match, tabIndex, 1)"
                                    size="mini"
                                    type="danger"
                                    circle
                                    class="match-remove-pair"
                                    icon="el-icon-close"
                                    plain>
                                </el-button>
                              </div>
                            </template>
                          </td>
                          <td v-if="match.sets.length === 0"></td>
                          <match-list-result :sets="match.sets"
                                             :pair-id="match.pair1_id"
                                             :has-winner="match.winner_pair !== null"/>
                          <td class="versus">x</td>
                          <td v-if="match.sets.length === 0"></td>
                          <match-list-result :sets="match.sets"
                                             :pair-id="match.pair2_id"
                                             :has-winner="match.winner_pair !== null"/>
                          <td :class="['pair', 'pair2', {'no-padding': $vuetify.breakpoint.xsOnly}]">
                            <Draggable class="match-pair-draggable"
                                       v-if="!match.pair2_id"
                                       v-model="match.pair2"
                                       :options="{sort:false,group:{name:'pairs', pull: false}, scroll:false}"
                                       @add="addPair(match.pair2, match, tabIndex, 2)">
                              <match-pair-names v-if="match.pair2_names"
                                                :pair-names="match.pair2_names"
                                                :player1-checkin-at="match?.pair2_player1_checkin_at"
                                                :player2-checkin-at="match?.pair2_player2_checkin_at"/>
                            </Draggable>
                            <template v-else-if="match.pair2_id || match.round > 0">
                              <div class="flex-c -inline -jc-ce">
                                <el-button
                                    v-if="match.pair2_id && match.sets.length === 0"
                                    @click="deletePair(match, tabIndex, 2)"
                                    size="mini"
                                    type="danger"
                                    circle
                                    class="match-remove-pair"
                                    icon="el-icon-close"
                                    plain>
                                </el-button>
                                <match-pair-names v-if="match.pair2_names"
                                                  :data-id="match.pair2_id"
                                                  :pair-names="match.pair2_names"
                                                  :player1-checkin-at="match?.pair2_player1_checkin_at"
                                                  :player2-checkin-at="match?.pair2_player2_checkin_at"/>
                              </div>
                            </template>
                          </td>
                          <td class="list-table-nowrap">
                            <div class="btn-actions">
                              <el-button v-if="match.id && match.id !== switchMatches.match1.id"
                                         key="switchMatch"
                                         type="info"
                                         class="match-switch"
                                         :disabled="hasSwitchMatchesSelected && (!switchMatches.match1.date || !switchMatches.match1.court_id)"
                                         @click="switchMatch(match)"
                                         title="Inverter jogos">
                                <i class="fas fa-exchange-alt"></i>
                              </el-button>
                              <el-button v-if="switchMatches.match1.id && !match.id"
                                         type="warning"
                                         key="setMatchHere"
                                         @click="setMatchHere(match)"
                                         title="Alocar Jogo nesse Horário">
                                <i class="fas fa-arrow-alt-circle-down"></i>
                              </el-button>
                              <el-button
                                  v-if="match.round > 0 && match.court_id !== '' && match.datetime !== ''"
                                  type="primary"
                                  key="resetDatetime"
                                  :disabled="(hasSwitchMatchesSelected && (!switchMatches.match1.datetime || !switchMatches.match1.court_id)) || match.sets.length > 0"
                                  @click="resetDatetime(match)"
                                  title="Enviar para Sem Horário Definido">
                                <i class="fas fa-clock"></i>
                              </el-button>
                            </div>
                          </td>
                          <td class="list-table-nowrap hidden-xs-only">
                            <match-edit v-if="match.id"
                                        :courts="tabs"
                                        :stage-id="stageId"
                                        :switch-selected="hasSwitchMatchesSelected"
                                        :match="match"
                                        :stage-title="stageTitle"
                                        :scoreboard-key="court.key"
                                        :scoreboard-config="scoreboardConfig"
                                        @update:match-data="updateMatchData"/>
                          </td>
                          <td class="list-table-nowrap">
                            <el-button v-if="match.id && match.pair1_id && match.pair2_id"
                                       type="success"
                                       key="checkIn"
                                       size="mini"
                                       @click="checkIn(match)"
                                       title="Check-in">
                              <i class="fas fa-user-check"></i>
                            </el-button>
                          </td>
                          <td class="round hidden-sm-and-up">{{ match.round_name }}</td>
                          <td class="player-class hidden-sm-and-up">
                            <span>{{ match.player_class_label }}</span></td>
                          <td class="round-game hidden-sm-and-up">
                          <span v-if="match.type === 'groups'">
                            {{
                              match.round === 0 ? 'Chave ' + match.group : (match.number ? 'Jogo ' + match.number : '')
                            }}
                          </span>
                            <span v-else-if="match.type === 'eliminatory'">Eliminatória</span>
                          </td>
                        </template><!--match.unavailable=false-->
                      </template>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </template>
    </gripo-dialog>

    <div class="change-matchs" v-if="hasSwitchMatchesSelected">
      <h3>Inverter Jogos</h3>
      <div class="box-slots">
        <div class="slots slot-1">
          <div class="slot-name mb-1">
            HORÁRIO DE ORIGEM
          </div>
          <div class="slot-pair">
            <div>{{ switchMatchesMatch1Pair1Names }}</div>
            <div>{{ switchMatchesMatch1Pair2Names }}</div>
          </div>
          <div class="slot-data pt-1">
            <div class="slot-item">
              <span>Categoria: </span><strong>{{ switchMatches.match1.player_class_label }} -
              {{ switchMatches.match1.group }}</strong>
            </div>
          </div>
          <div class="slot-data">
            <div class="slot-item">
              <span>Horário: </span><strong>{{ switchMatches.match1.date | dateEnToBr }} {{
                switchMatches.match1.hour
              }}</strong>
            </div>
            <div class="slot-item">
              <span>Quadra: </span><strong>{{ switchMatches.match1.court_name }}</strong>
            </div>
          </div>
        </div>
        <div class="slots slot-2">
          <div class="slot-name flex-c mb-1">
            <div v-if="!switchMatches.match2.id"></div>
            {{ !switchMatches.match2.id ? 'SELECIONE O ' : '' }} HORÁRIO DE DESTINO
            <div v-if="!switchMatches.match2.id"></div>
            <el-button v-if="switchMatches.match2.id"
                       type="danger"
                       size="mini"
                       title="Limpar Jogo"
                       @click="removeSwitchMatch(2)">
              <i class="fas fa-times"></i>
              <span class="ml-1">Limpar</span>
            </el-button>
          </div>
          <div class="slot-pair">
            <div>{{ switchMatchesMatch2Pair1Names }}</div>
            <div>{{ switchMatchesMatch2Pair2Names }}</div>
          </div>
          <template>
            <div class="slot-data pt-1">
              <div class="slot-item" v-if="switchMatches.match2.player_class_label && switchMatches.match2.group">
                <span>Categoria: </span>
                <strong>{{ switchMatches.match2.player_class_label }} - {{ switchMatches.match2.group }}</strong>
              </div>
            </div>
            <div class="slot-data">
              <div class="slot-item">
                <span>Horário: </span><strong>{{ switchMatches.match2.date | dateEnToBr }} {{
                  switchMatches.match2.hour
                }}</strong>
              </div>
              <div class="slot-item">
                <span>Quadra: </span><strong>{{ switchMatches.match2.court_name }}</strong>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="slot-actions mt-1">
        <el-button v-if="switchMatches.match1.id"
                   type="default"
                   size="mini"
                   @click="saveSwitchMatch">
          <i class="fas fa-check"></i>
          <span>Confirmar {{ switchMatches.match1.id && switchMatches.match2.id ? 'Inversão' : 'Alteração' }}</span>
        </el-button>
        <el-button v-if="switchMatches.match1.id || switchMatches.match2.id"
                   type="warning"
                   size="mini"
                   @click="clearSwitchMatch">
          <i class="fas fa-times"></i>
          <span>Limpar Seleção</span>
        </el-button>
      </div>
    </div>
  </div>
</template>
