<script>
import FormSaveButton from '@components/form/SaveButton'

import * as notifyService from '@app/services/notifyService'
import {
  SETTINGS_TYPES,
  defaultFaceRecon,
  getTenantSettings,
  saveTenantSettings,
  emitReloadSettingsType
} from '../../../service'

import {mapGetters} from 'vuex'

const SETTINGS_TYPE = SETTINGS_TYPES.face_recon
export default {
  components: {FormSaveButton},
  name: 'face-recon',
  data() {
    return {
      isComponentLoading: true,
      isFirstLoad: true,
      isSubmitted: false,
      showSecretToken: false,
      termsConditionsLink: 'https://documents.gripo.com.br/terms-and-conditions/face-recon',
      form: {...defaultFaceRecon},
    }
  },
  validations: {
    form: {}
  },
  computed: {
    ...mapGetters(['tenant'])
  },
  async mounted() {
    this.isComponentLoading = true
    this.getSettings()
  },
  methods: {
    async getSettings() {
      const result = await getTenantSettings(SETTINGS_TYPE)
      this.form = {...this.form, ...result.data}
      setTimeout(() => {
        this.isFirstLoad = false
        this.isComponentLoading = false
      }, 200)
    },
    onSaveSettings() {
      this.isComponentLoading = true
      saveTenantSettings({type: SETTINGS_TYPE, settings: {...this.form}}, true)
          .then((settings) => {
            emitReloadSettingsType(SETTINGS_TYPES.totem)
            notifyService.success()
            this.form = {...this.form, ...settings.data}
            this.isComponentLoading = false
          })
          .catch(e => {
            this.isComponentLoading = false
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="{'tenant-card-settings-loading' : isComponentLoading}"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados...">
    <div slot="header">
      <div>
        <span>Reconhecimento Facial</span>
        <a class="el-button el-button--primary el-button--mini ml-3" :href="termsConditionsLink" target="_blank">
          Termos de Uso
        </a>
      </div>
      <el-switch v-model="form.enabled"
                 :active-value="true"
                 :inactive-value="false"
                 active-text="Habilitado"
                 inactive-text="">
      </el-switch>
    </div>
    <form-save-button :event-click="onSaveSettings"
                      :is-disabled="isComponentLoading || $v.form.$invalid"
                      :is-submitted="isSubmitted"/>
    <el-alert :closable="false" class="mt-2" show-icon>
      Ao ativar esse módulo você confirma que está de acordo com os termos de uso!
    </el-alert>
  </el-card>
</template>
