import * as fileService from '@app/services/fileService'
import * as restfulService from '@app/services/restfulService'

export const showInvoicePdf = (invoice) => {
    return new Promise((resolve, reject) => {
        const data = {format: 'pdf'}
        restfulService.post('invoice', 'show', invoice.id, data)
            .then(response => {
                if (invoice.type === 'nfce') {
                    let pom = document.createElement('a')
                    pom.setAttribute('target', '_blank')
                    pom.setAttribute('href', response.base64)
                    pom.style.display = 'none'
                    document.body.appendChild(pom)
                    pom.click()
                    document.body.removeChild(pom)
                    resolve()
                } else {
                    resolve(response)
                }
            })
            .catch(e => {
                console.log(e)
                reject(e)
            })
    })
}
export const showInvoiceXml = (invoice) => {
    return new Promise((resolve) => {
        fileService.downloadFileLink(invoice.xml_file)
            .finally(() => resolve())
    })
}
