import * as restfulService from '@app/services/restfulService'

import bus from '@utils/bus'
import {currencyParser} from '@mixins/helpers'

import moment from 'moment'
import {orderBy} from 'lodash'
import {warningDialog} from '@utils/flash'

export const defaultSchedule = {
    id: '',
    has_schedule_grid: false,
    create: false,
    switched: false,
    type: 'normal',
    court: {
        id: '',
        name: ''
    },
    court_id: '',
    client_id: '',
    plan_id: '',
    plan_due_date: '',
    client: {
        id: '',
        name: '',
        nickname: '',
        cpf_cnpj: '',
        phone: '',
        email: '',
        active_plans: []
    },
    coach_id: '',
    coach: {
        id: '',
        name: '',
        nickname: '',
        phone: '',
        email: '',
        coach_config: {
            amount_class: 0
        },
    },
    date: '',
    start_hour: '',
    end_hour: '',
    weekday: '',
    fixed: false,
    update_fixed: false,
    schedule_fixed: false,
    players_limit: 0,
    notes: '',
    unavailable_details: {
        notes: ''
    },
    players: [],
    classes: [],
    genders: [],
    invites: [],
    transactions: [],
    grid_remove: false,
    price: 0,
    queue: [],
    sport: null
}

export const saveSchedule = (data, notifyUser, hoursSelection) => {
    data.start_datetime = data.date + ' ' + data.start_hour
    data.end_datetime = data.date + ' ' + data.end_hour
    data.notify_users = [...notifyUser.players]
    data.notify_users = data.notify_users.map(item => {
        delete item?.client?.active_plans
        delete item?.client?.address
        return item
    })
    if (data.fixed && data.schedule_fixed) {
        data.fixed = false
    }
    data.price = currencyParser(data.price)
    delete data.date
    delete data.start_hour
    delete data.end_hour
    delete data.court
    delete data.client
    delete data.coach
    data.players = data.players.map(item => {
        delete item?.client?.active_plans
        delete item?.client?.address
        return {
            ...item,
            amount: item.amount ? currencyParser(item.amount) : 0
        }
    })
    if (hoursSelection.length) {
        const selectedSchedules = orderBy(hoursSelection, ['start_hour'])
        const firstSchedule = selectedSchedules[0]
        const lastSchedule = selectedSchedules[selectedSchedules.length - 1]
        let currentHour = moment(`${firstSchedule.date} ${firstSchedule.start_hour}`, 'YYYY-MM-DD HH:mm:ss')
        const lastHour = moment(`${firstSchedule.date} ${lastSchedule.end_hour}`, 'YYYY-MM-DD HH:mm:ss')
        let intervalCounter = 0
        while (currentHour.isBefore(lastHour)) {
            intervalCounter++
            currentHour.add(firstSchedule.interval, 'minutes')
        }
        if (intervalCounter > hoursSelection.length) {
            warningDialog({
                title: 'Atenção!',
                text: 'Você não pode ter horários vazios entre as reservas',
                icon: 'warning'
            })
            bus.$emit('hide-loader')
            throw new Error('error')
        }
        data.start_datetime = `${firstSchedule.date} ${firstSchedule.start_hour}`
        data.end_datetime = lastHour.format('YYYY-MM-DD HH:mm:ss')
    }
    return restfulService.post('schedule', 'save', null, data)
}

export const removeSchedule = ({schedule_id, notify, notify_users, is_multiple}) => {
    const data = {notify, notify_users, is_multiple}
    return restfulService.post('schedule', 'delete', schedule_id, data)
}

export const revertRemoveSchedule = ({schedule_id}) => {
    return restfulService.post('schedule', 'revert-delete', schedule_id, {})
}

export const removeScheduleFixed = ({client_id, court_id, weekday, date, start_time, end_time}) => {
    const data = {client_id, court_id, weekday, date, start_time, end_time}
    return restfulService.post('schedule', 'delete-fixed', null, data)
}

export const releaseScheduleFixed = ({id, court_id, date, start_hour, end_hour, is_multiple, selection}) => {
    const data = {id, court_id, date, start_hour, end_hour, is_multiple, selection}
    return restfulService.post('schedule', 'release', null, data)
}

export const revertScheduleFixedCanceled = (scheduleId) => {
    return restfulService.post('schedule', 'revert-fixed-canceled', null, {schedule_id: scheduleId})
}

export const revertReleaseScheduleFixed = ({fixed_release_id}) => {
    const data = {fixed_release_id}
    return restfulService.post('schedule', 'revert-fixed-release', null, data)
}
