<script>
import GripoDialog from '@components/common/Dialog'
import ClientComponent from '@components/common/ClientComponent'
import PlanType from './labels/PlanType.vue'
import PlanIntervalLabel from './labels/PlanIntervalLabel'
import PlanRuleTypeLabel from './labels/PlanRuleTypeLabel'
import PlanRuleTargetLabel from './labels/PlanRuleTargetLabel'
import PlanRuleValueLabel from './labels/PlanRuleValueLabel'
import ClientPlanStatusLabel from './labels/ClientPlanStatusLabel'
import RenewalStatusLabel from './labels/RenewalStatusLabel'

import * as restfulService from '../../../../services/restfulService'
import DataService from '@app/services/dataService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {debounce} from 'lodash'
import moment from 'moment'
import {confirmDialog} from "../../../../../utils/flash";

const defaultReferenceMonth = moment().format('YYYY-MM-DD')

export default {
  mixins: [helpers],
  components: {
    GripoDialog,
    ClientComponent,
    PlanType,
    PlanIntervalLabel,
    PlanRuleTypeLabel,
    PlanRuleTargetLabel,
    PlanRuleValueLabel,
    ClientPlanStatusLabel,
    RenewalStatusLabel,
  },
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      selectedList: [],
      plan: {
        id: '',
        name: '',
        bg_color: '',
        clients: [],
        rules: [],
        amount: 0,
        coach: {
          id: '',
          name: ''
        }
      },
      isSearchingClient: false,
      searchClient: null,
      selectedClientId: null,
      isModalAddClientsVisible: false,
      selectedClients: [],
      selectedPlanRules: [],
      startDate: '',
      dueDate: '',
      dependencies: {
        clients: [],
        plan_rule_status: [],
      },
      filters: {
        client: '',
        status: ['created', 'active'],
        start_period: [],
        end_period: [],
      },
      isModalClientPlanDetailsVisible: false,
      clientPlan: {
        client: {
          name: ''
        },
        reference_month: defaultReferenceMonth,
        fixed_schedules: [],
      },
      planDetailsActiveTab: 0
    }
  },
  watch: {
    'searchClient': debounce(function (value) {
      value && value !== this.selectedClientId && this.onSearchClient(value)
    }, 300),
    'clientPlan.reference_month': function (value, oldValue) {
      if (value !== oldValue) {
        this.getClientPlanDetails(this.clientPlan)
      }
    }
  },
  computed: {
    isAllChecked() {
      return this.clientsFiltered.length > 0 && this.selectedList.length === this.clientsFiltered.length
    },
    clientsFiltered() {
      let clients = [...this.plan.clients]
      if (this.filters.client) {
        clients = clients.filter(item => {
          return item.client.name.toLowerCase().includes(this.filters.client.toLowerCase())
        })
      }
      if (this.filters.status && !(this.filters.status.length === 1 && !this.filters.status[0])) {
        clients = clients.filter(item => {
          return this.filters.status.includes(item.status)
        })
      }
      if (this.filters.start_period && this.filters.start_period.length > 0) {
        clients = clients.filter(item => {
          return moment(item.start_datetime).isBetween(moment(this.filters.start_period[0]), moment(this.filters.start_period[1]), 'days', '[]')
        })
      }
      if (this.filters.end_period && this.filters.end_period.length > 0) {
        clients = clients.filter(item => {
          return moment(item.start_datetime).isBetween(moment(this.filters.end_period[0]), moment(this.filters.end_period[1]), 'days', '[]')
        })
      }
      return clients
    },
    isRecurrentPlan() {
      return this.plan.recurrent
    },
    planAmount() {
      const creditRule = this.plan.rules.find(item => item.type === 'credit')
      if (['schedule_credit', 'lesson_single'].includes(this.plan.type) && this.plan.rules.length === 1 && creditRule) {
        return creditRule.value * this.plan.amount
      }
      return this.plan.amount
    },
    hasPayment() {
      return this.plan.amount > 0
    },
  },
  mounted() {
    this.getDependencies()
    this.loadPlan()
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'plan_rule_status'}]).then((result) => {
        this.dependencies = {...result}
      })
    },
    loadPlan() {
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('plan', null, id, null, ['coach', 'rules'])
            .then(response => {
              this.plan = {...this.plan, ...response}
              this.loadClients()
              bus.$emit('hide-loader')
            })
            .catch(e => {
              console.log(e)
              this.isLoadingData = false
              bus.$emit('hide-loader')
            })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    loadClients() {
      bus.$emit('show-loader')
      this.plan.clients.splice(0)
      restfulService.post('client', 'search-plans', null, {plan_id: this.plan.id})
          .then(response => {
            this.plan.clients = [...response]
            this.resetSelectedList()
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    resetSelectedList() {
      this.plan.clients.forEach(entry => {
        entry.checked = false
      })
      this.selectedList.splice(0)
    },
    checkItem(listItem) {
      if (this.selectedList.some(item => item === listItem.id)) {
        this.selectedList = this.selectedList.filter(item => item !== listItem.id)
        listItem.checked = false
      } else {
        this.selectedList.push(listItem.id)
        listItem.checked = true
      }
    },
    checkAll() {
      if (this.isAllChecked) {
        this.resetSelectedList()
      } else {
        this.selectedList = [...this.plan.clients.map(item => item.id)]
        this.plan.clients.forEach(entry => {
          entry.checked = true
        })
      }
    },
    selectedAction(command) {
      if (command === 'cancel-plan') {
        this.cancelClientsPlan()
      }
    },
    selectPlanRule(planRule) {
      if (this.selectedPlanRules.some(item => item === planRule.id)) {
        this.selectedPlanRules = this.selectedPlanRules.filter(item => item !== planRule.id)
      } else {
        this.selectedPlanRules.push(planRule.id)
      }
    },
    hasPlanRuleSelected(planRule) {
      return this.selectedPlanRules.some(item => item === planRule.id)
    },
    handleAddClients() {
      this.selectedClients.splice(0)
      this.selectedClientId = ''
      this.searchClient = ''
      this.startDate = ''
      this.dueDate = ''
      this.selectedPlanRules.splice(0)
      this.selectedPlanRules = [...this.plan.rules.map(planRule => planRule.id)]
      this.isModalAddClientsVisible = !this.isModalAddClientsVisible
    },
    onSearchClient(query) {
      this.isSearchingClient = true
      let data = {query, only_clients: true}
      restfulService.post('client', 'autocomplete', null, data)
          .then((response) => {
            if (response.length > 0) {
              this.dependencies.clients = [...response]
            }
            this.isSearchingClient = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    onSelectClient(client) {
      this.selectedClients.push({
        hash: (Math.random() + 1).toString(36).substring(7),
        ...client,
      })
      setTimeout(() => {
        this.$refs.addClientComponent.reset()
      }, 100)
    },
    removeClient(item) {
      this.selectedClients = this.selectedClients.filter(client => client.hash !== item.hash)
    },
    saveClientsPlan() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      let data = {
        plan_id: this.plan.id,
        clients: this.selectedClients.map(item => {
          return {
            id: item.id,
            start_date: this.startDate,
            due_date: this.dueDate,
          }
        }),
        plan_rules: [...this.plan.rules.filter(item => this.selectedPlanRules.includes(item.id))]
      }
      restfulService.post('client', 'save-plans', null, data)
          .then(() => {
            this.loadClients()
            this.handleAddClients()
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    cancelClientsPlan() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      let data = {
        plan_id: this.plan.id,
        client_plans: this.selectedList,
      }
      restfulService.post('client', 'cancel-plans', null, data)
          .then(() => {
            this.loadClients()
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    handleClientPlanDetails() {
      this.isModalClientPlanDetailsVisible = !this.isModalClientPlanDetailsVisible
    },
    async showClientPlanDetails(clientPlan) {
      clientPlan.reference_month = defaultReferenceMonth
      await this.getClientPlanDetails(clientPlan)
      this.handleClientPlanDetails()
    },
    getClientPlanDetails(clientPlan) {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = {client_plan_id: clientPlan.id, reference_month: this.clientPlan.reference_month}
        restfulService.post('client', 'plan-details', null, data)
            .then(response => {
              this.clientPlan = {...this.clientPlan, ...response.client_plan}
              this.clientPlan.reference_month = response.reference_month
              this.clientPlan.fixed_schedules = [...response.fixed_schedules]
              bus.$emit('hide-loader')
              this.isSubmitted = false
              resolve()
            })
            .catch(e => {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              console.log(e)
              reject(e)
            })
      })
    },
    renewClientPlanRule(clientPlanRule, renewal) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        client_plan_rule_id: clientPlanRule.id,
        start_datetime: renewal ? renewal.start_datetime : null,
        end_datetime: renewal ? renewal.end_datetime : null,
        value: clientPlanRule.rule_data.type === 'credit' ? clientPlanRule.rule_data.value : null,
      }
      restfulService.post('client', 'renew-plan-rule', null, data)
          .then(() => {
            this.getClientPlanDetails(this.clientPlan)
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async cancelRenew(renewal) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente cancelar essa renovação?',
        text: 'Os créditos relacionados a ela serão cancelados também!',
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = {renewal_id: renewal.id}
        restfulService.post('client', 'cancel-renewal', null, data)
            .then(() => {
              this.getClientPlanDetails(this.clientPlan)
            })
            .catch(e => {
              this.isSubmitted = false
              bus.$emit('hide-loader')
              console.log(e)
            })
      }
    },
    updateRuleTarget(rule, targetId) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        rule_id: rule.id,
        target_id: targetId,
        type: 'schedule',
      }
      restfulService.post('client', 'update-rule-target', null, data)
          .then(() => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            rule.target_id = ''
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    getClientActiveRenewals(rules) {
      return rules.reduce((total, rule) => {
        return total + rule.renewals.filter(item => item.status === 'active').length
      }, 0)
    }
  }
}
</script>

<template>
  <div>
    <el-card class="el-card__search_list box-card mt-4">
      <div slot="header">
        <div>
          <span v-if="plan.bg_color" class="color-circle-label -small mr-2"
                :style="`background-color: ${plan.bg_color}`"></span>
          <span>{{ plan.name }}</span>
        </div>
      </div>
      <el-row :gutter="20" class="mt-2">
        <el-col :span="10">
          <table class="custom-table-list">
            <thead>
            <tr>
              <th colspan="2">Dados do Plano/Pacote</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><strong>Tipo</strong>:</td>
              <td>
                <plan-type v-if="!!plan.type" :type="plan.type"/>
              </td>
            </tr>
            <tr v-if="plan.coach_id">
              <td><strong>Professor:</strong>:</td>
              <td>{{ plan.coach.name }}</td>
            </tr>
            <tr>
              <td><strong>Valor</strong>:</td>
              <td>
                <span v-if="plan.has_payment">
                  {{ plan.amount | currencyFormatter }}
                </span>
                <span v-else>Sem custo</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Renovação Auto.</strong>:
              </td>
              <td>
                <plan-interval-label :interval="plan.interval"
                                     :interval-type="plan.interval_type"
                                     v-if="plan.recurrent"/>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <td><strong>Descrição</strong>:</td>
              <td><span class="safe-html" v-html="plan.description"></span></td>
            </tr>
            </tbody>
          </table>
        </el-col>
        <el-col :span="14">
          <table class="custom-table-list">
            <thead>
            <tr>
              <th colspan="4">Regras</th>
            </tr>
            <tr>
              <th>Tipo</th>
              <th>Item</th>
              <th>Valor/Quantidade</th>
              <th>Renovar Créditos Aut.</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="rule in plan.rules">
              <td>
                <plan-rule-type-label :type="rule.type"/>
              </td>
              <td>
                <plan-rule-target-label v-if="rule.target" :target="rule.target"/>
              </td>
              <td>
                <plan-rule-value-label :type="rule.type" :value="rule.value"/>
              </td>
              <td>
                {{ rule.renew_credits ? 'Sim' : '-' }}
              </td>
            </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="el-card__search_list box-card mt-4">
      <div slot="header">
        <div>
          <span>Clientes ({{ clientsFiltered.length }})</span>
          <div class="entries-selected d-inline-block">
            <el-dropdown :disabled="selectedList.length === 0"
                         @command="selectedAction"
                         v-if="selectedList.length > 0"
                         placement="bottom-start">
              <el-button :type="selectedList.length === 0 ? 'default' : 'primary'" size="small">
                <span>Com selecionados:</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="cancel-plan">
                  <i class="fas fa-times"></i>
                  <span class="ml-2">Cancelar Plano</span>
                </el-dropdown-item>
                <el-dropdown-item command="renew-plan">
                  <i class="fas fa-sync"></i>
                  <span class="ml-2">Renovar Plano</span>
                </el-dropdown-item>
                <el-dropdown-item command="migrar-plan" disabled>
                  <i class="fas fa-exchange-alt"></i>
                  <span class="ml-2">Migrar para outro Plano (<strong>Em breve</strong>)</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-button type="success" size="small" @click="handleAddClients">
          <i class="fas fa-plus"></i>
          <span>Adicionar Clientes ao Plano/Pacote</span>
        </el-button>
      </div>
      <el-form :inline="true" :model="filters">
        <el-form-item label="Cliente">
          <el-input v-model="filters.client"
                    placeholder="Filtrar por cliente"/>
        </el-form-item>
        <el-form-item label="Status">
          <el-select v-model="filters.status"
                     multiple
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full">
            <el-option label="Todos" value=""/>
            <el-option v-for="item in dependencies.plan_rule_status"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Período de Ativação">
          <el-date-picker
              v-model="filters.start_period"
              type="daterange"
              format="dd/MM/yyyy"
              placeholder="Filtrar por ativação">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Período de Encerramento">
          <el-date-picker
              v-model="filters.end_period"
              type="daterange"
              format="dd/MM/yyyy"
              placeholder="Filtrar por encerramento">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <table class="custom-table-list mt-3">
        <thead>
        <tr>
          <th>
            <el-checkbox @change="checkAll" :value="isAllChecked"/>
          </th>
          <th>Cliente</th>
          <th>Status do Plano</th>
          <th>Data de Ativação</th>
          <th>Última Renovação</th>
          <th>Renovações Ativas</th>
          <th>Data de Encerramento</th>
          <th>Detalhes</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="clientPlan in clientsFiltered">
          <th class="list-table-check">
            <el-checkbox @change="checkItem(clientPlan)" v-model="clientPlan.checked" :disabled="!clientPlan.id"/>
          </th>
          <td>{{ clientPlan.client.name }}</td>
          <td>
            <client-plan-status-label :status="clientPlan.status"/>
          </td>
          <td>
            <span v-if="clientPlan.status !== 'created'">
              {{ clientPlan.start_datetime | dateTimeEnToBr }}h
            </span>
          </td>
          <td>
            <span v-if="clientPlan.last_renewal">{{ clientPlan.last_renewal | dateTimeEnToBr }}h</span>
          </td>
          <td>{{ getClientActiveRenewals(clientPlan.rules) }}</td>
          <td>
            <span v-if="clientPlan.end_datetime">{{ clientPlan.end_datetime | dateTimeEnToBr }}h</span>
          </td>
          <td>
            <template v-for="rule in clientPlan.rules">
              <div v-if="rule.notes">{{ rule.notes }}</div>
            </template>
          </td>
          <td class="list-table-nowrap">
            <div class="btn-actions">
              <el-button type="default" title="Ver Detalhes" @click="showClientPlanDetails(clientPlan)">
                <i class="fas fa-search"></i>
              </el-button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </el-card>
    <gripo-dialog :model.sync="isModalAddClientsVisible"
                  width="80vw">
      <template slot="header">
        Adicionar clientes ao plano/pacote
      </template>
      <template slot="content">
        <el-form ref="form"
                 :model="plan"
                 label-position="top">
          <el-form-item label="Buscar Clientes">
            <client-component :client-id.sync="selectedClientId"
                              ref="addClientComponent"
                              :outline="false"
                              @selectClient="onSelectClient"/>
          </el-form-item>
        </el-form>
        <el-divider/>
        <el-row :gutter="10">
          <el-col :span="10">
            <h3 class="mt-0 mb-0">Clientes Selecionados</h3>
            <v-list two-line>
              <template v-for="(item, index) in selectedClients">
                <v-list-tile :key="item.hash">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                    <v-list-tile-sub-title v-if="item.phone">
                      {{ item.phone | phone }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn icon ripple @click="removeClient(item)">
                      <i class="fas fa-times" color="error"></i>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider :key="index + 1"/>
              </template>
            </v-list>
          </el-col>
          <el-col :span="14">
            <h3 class="mt-0 mb-2">Regras Selecionadas</h3>
            <table class="custom-table-list">
              <thead>
              <tr>
                <th></th>
                <th>Tipo</th>
                <th>Item</th>
                <th>Valor/Quantidade</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="rule in plan.rules">
                <td>
                  <el-checkbox @change="selectPlanRule(rule)" :value="hasPlanRuleSelected(rule)"/>
                </td>
                <td>
                  <plan-rule-type-label size="mini" :type="rule.type"/>
                </td>
                <td>
                  <plan-rule-target-label size="mini" v-if="rule.target" :target="rule.target"/>
                </td>
                <td>
                  <plan-rule-value-label :type="rule.type" :value.sync="rule.value" show-input-selection/>
                </td>
              </tr>
              </tbody>
            </table>
            <el-row :gutter="10">
              <el-col :span="12">
                <h3 class="mt-4 mb-2">Data de Início</h3>
                <el-date-picker
                    v-model="startDate"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    v-mask="'##/##/####'"
                    placeholder="Data de Início">
                </el-date-picker>
                <el-alert class="mt-2" :closable="false" type="warning">
                  <span class="fs-14">Data de <strong>início do plano</strong>, caso a data não seja informada será utilizado a data de hoje</span>
                </el-alert>
              </el-col>
              <el-col :span="12" v-if="hasPayment">
                <h3 class="mt-4 mb-2">Data de Vencimento</h3>
                <el-date-picker
                    v-model="dueDate"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    v-mask="'##/##/####'"
                    placeholder="Data de Vencimento">
                </el-date-picker>
                <el-alert class="mt-2" :closable="false" type="warning">
                  <span class="fs-14">Data de <strong>vencimento do pagamento</strong>, caso a data não seja informada será utilizado a data de hoje</span>
                </el-alert>
              </el-col>
            </el-row>
            <template v-if="hasPayment">
              <h3 class="mt-4 mb-2">Valor Final</h3>
              {{ planAmount | currencyFormatter }}
            </template>
          </el-col>
        </el-row>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted || selectedClients.length === 0 || selectedPlanRules.length === 0"
                   @click="saveClientsPlan">Confirmar
        </el-button>
      </template>
    </gripo-dialog>
    <gripo-dialog :model.sync="isModalClientPlanDetailsVisible"
                  width="60vw">
      <template slot="header">
        <div class="font-weight-bold">
          <span class="mr-2">{{ clientPlan.client.name }}</span>
          <client-plan-status-label :status="clientPlan.status" show-status-text/>
          <el-tag size="small" class="el-tag--status ml-2" type="info" v-if="clientPlan.status !== 'created'">
            Dt. Ativação: {{ clientPlan.start_datetime | dateTimeEnToBr }}h
          </el-tag>
          <span></span>
        </div>
      </template>
      <template slot="content">
        <v-tabs v-model="planDetailsActiveTab"
                fixed-tabs
                color="transparent"
                slider-color="secondary">
          <v-tab v-for="rule in clientPlan.rules" :key="rule.id">
            <plan-rule-type-label :type="rule.rule_data.type"
                                  :tag="false"/>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="planDetailsActiveTab">
          <v-tab-item v-for="rule in clientPlan.rules" :key="rule.id">
            <table class="custom-table-list">
              <thead>
              <tr>
                <th>Tipo</th>
                <th>Item</th>
                <th width="200">Valor/Quantidade</th>
                <th>Ações</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <plan-rule-type-label :type="rule.rule_data.type"/>
                </td>
                <td>
                  <plan-rule-target-label v-if="rule.rule_data.target" :target="rule.rule_data.target"/>
                </td>
                <td>
                  <plan-rule-value-label :type="rule.rule_data.type" :value.sync="rule.rule_data.value"
                                         show-input-selection/>
                </td>
                <td>
                  <div
                      v-if="isRecurrentPlan && rule.rule_data.type === 'credit' && clientPlan.fixed_schedules.length > 0">
                    <h4 class="mt-3 mb-1">Vincular Horário Fixo</h4>
                    <v-select v-model="rule.target_id"
                              :items="clientPlan.fixed_schedules"
                              item-value="id"
                              placeholder="Seleciona um horário fixo"
                              clearable
                              outline
                              single-line
                              @change="updateRuleTarget(rule, rule.target_id)">
                      <template slot="item" slot-scope="data">
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ data.item.court }}
                          </v-list-tile-title>
                          <v-list-tile-sub-title>
                            {{ data.item.weekday_label }}
                            {{ data.item.start_datetime | timeEnToBr }}h
                            até {{ data.item.end_datetime | timeEnToBr }}h
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ item.court }}
                          </v-list-tile-title>
                          <v-list-tile-sub-title>
                            {{ item.weekday_label }}
                            {{ item.start_datetime | timeEnToBr }}h
                            até {{ item.end_datetime | timeEnToBr }}h
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                    </v-select>
                  </div>
                  <el-button type="warning"
                             size="small"
                             title="Renovar Manualmente"
                             @click="renewClientPlanRule(rule, null)"
                             v-if="!clientPlan.plan.recurrent && clientPlan.status === 'active'">
                    <i class="fas fa-sync"></i>
                    <span class="ml-1">Renovar Manualmente</span>
                  </el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <el-row type="flex" justify="space-between" class="mt-4 mb-1">
              <el-col>
                <h3 class="mb-0 mt-0">Renovações</h3>
              </el-col>
              <el-col>
                <el-form :inline="true" :model="clientPlan">
                  <el-form-item label="Mês de Referência" class="mb-0">
                    <el-date-picker
                        :clearable="false"
                        v-model="clientPlan.reference_month"
                        type="month"
                        size="small"
                        format="MM/yyyy"
                        value-format="yyyy-MM-dd"
                        placeholder="Mês de Referência">
                    </el-date-picker>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <table class="custom-table-list">
              <thead>
              <tr>
                <th>Status</th>
                <th>Data Inicial dessa Renovação</th>
                <th>Data Final dessa Renovação</th>
                <th v-if="rule.rule_data.type === 'credit'">Uso dos Créditos</th>
                <th>Ações</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="renewal in rule.renewals">
                <td>
                  <renewal-status-label :status="renewal.status"
                                        :id="renewal.id"
                                        :plan-recurrent="plan.recurrent"
                                        :has-payment="plan.has_payment"/>
                </td>
                <td>{{ renewal.start_datetime | dateTimeEnToBr }}h</td>
                <td>
                  <span v-if="clientPlan.plan.recurrent || renewal.status === 'canceled'">
                    {{ renewal.end_datetime | dateTimeEnToBr }}h
                  </span>
                </td>
                <td width="250" v-if="rule.rule_data.type === 'credit'">
                  <template
                      v-if="rule.rule_data.type === 'credit' && ['active', 'canceled', 'expired'].includes(renewal.status)">
                    <span v-if="!!renewal.coupon">
                      <el-tag size="mini">
                        {{ renewal.coupon.uses_count }} de {{ renewal.coupon.uses_limit }} créditos
                      </el-tag>
                    </span>
                  </template>
                </td>
                <td class="list-table-nowrap">
                  <div class="btn-actions">
                    <el-button type="warning"
                               title="Renovar Manulamente"
                               @click="renewClientPlanRule(rule, renewal)"
                               v-if="!renewal.id && clientPlan.plan.has_payment && clientPlan.status === 'active'">
                      <i class="fas fa-sync"></i>
                    </el-button>
                    <el-button type="danger"
                               title="Canceler Renovação"
                               @click="cancelRenew(renewal)"
                               v-if="['created', 'active'].includes(renewal.status)">
                      <i class="fas fa-times"></i>
                    </el-button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
      </template>
    </gripo-dialog>
  </div>
</template>
