import store from '../vuex'
import moment from 'moment'
import {isEmpty, orderBy} from 'lodash'
import * as MESSAGES from '../app/messages'

import * as CPF from '@utils/cpf'
import {currencyConfig} from "../utils/money";

export const hasModulePermission = (routeName) => {
    const professionalModule = store.state.tenant?.modules['professional'] ?? false
    if (store.state.tenant.status === 'trial_time' || professionalModule) {
        return true
    }
    const basicModule = store.state.tenant?.modules['basic'] ?? false
    const globalPermissions = [
        'court.index',
        'client.index',
        'plan.index',
        'transaction.index',
        'circuit.index',
        'stage.index',
        'circuit.index',
        'user.index',
        'role.index',
        'coupon.index',
    ]
    if (!basicModule && !professionalModule) {
        return !(routeName.includes('.index') && !globalPermissions.includes(routeName))
    } else if (basicModule) {
        const permissions = [
            'schedule.index',
            'schedule_daily.index',
            'player_group.index',
            'entry.revenue.index',
            'invoice.index',
            'product.index',
            ...globalPermissions
        ]
        return !(routeName.includes('.index') && !permissions.includes(routeName))
    }
    return true
}

export const hasPermissionByName = (routeName) => {
    return store.getters.permissions.some(item => item.name === routeName)
}

export const hasRoutePermission = (route) => {
    if (!route.name) {
        return true
    }
    let domain
    let action
    if (route.name.split('.').length > 2) {
        [domain] = route.name.split('.')
        action = route.name.replace(`${domain}.`, '').split('.').reverse().join('.')
    } else {
        [domain, action] = route.name.split('.')
    }
    let routePermission = `${action}.${domain}`
    if (route.meta && route.meta.permission !== undefined) {
        if (route.meta.permission === false) {
            return true
        }
        routePermission = route.meta.permission
    }
    return hasPermissionByName(routePermission)
}

// Set moment locale
moment.locale(window?.localeCountryConfig?.locale || 'pt-BR')

const dateFormatBr = 'DD/MM/YYYY'
const dateFormatEn = 'YYYY-MM-DD'
const dateTimeFormatBr = 'DD/MM/YYYY HH:mm:ss'
const dateTimeFormatEn = 'YYYY-MM-DD HH:mm:ss'

const getMomentInstance = function (date) {
    let momentInstance = null
    if (moment(date, dateFormatBr, true).isValid()) {
        momentInstance = moment(date, dateFormatBr, true)
    } else if (moment(date, dateFormatEn, true).isValid()) {
        momentInstance = moment(date, dateFormatEn, true)
    } else if (moment(date, dateTimeFormatBr, true).isValid()) {
        momentInstance = moment(date, dateTimeFormatBr, true)
    } else if (moment(date, dateTimeFormatEn, true).isValid()) {
        momentInstance = moment(date, dateTimeFormatEn, true)
    } else {
        console.warn('Formato inválido', date)
    }
    return momentInstance
}

export const currencyParser = (number) => {
    if (number !== undefined && number !== false && typeof number === 'string') {
        const formattedStr = number.replaceAll(".", "")
            .replaceAll(",", ".");
        return parseFloat(parseFloat(formattedStr).toFixed(2));
    }
    return number
}

export const currencyNotZero = (value) => currencyParser(value) > 0

export const validCPF = (cpf) => CPF.validate(cpf)

export default {
    methods: {
        validateFormFocus: function (validatorModel, validatorModelKey, $refs) {
            const invalidFields = Object.keys(validatorModel.$params).filter(item => validatorModel[item].$invalid)
            if (!invalidFields.length) {
                return {
                    invalid: false,
                    fields: []
                }
            }
            validatorModel.$touch()
            const fieldRef = `${validatorModelKey}.${invalidFields[0]}`
            if ($refs[fieldRef]) {
                $refs[fieldRef].focus()
            }
            return {
                invalid: true,
                fields: invalidFields
            }
        },
        momentNow: function () {
            return moment()
        },
        dateMomentInstance: function (date) {
            return getMomentInstance(date)
        },
        dateNow: function (format) {
            format = format || 'br'
            return moment().format((format === 'br' ? 'DD/MM/YYYY' : 'YYYY-MM-DD'))
        },
        timeNow: function (short) {
            return short ? moment().format('HH:mm') : moment().format('HH:mm:ss')
        },
        dateTimeNow: function (short, format) {
            short = short || false
            format = format || 'br'
            if (format === 'br') {
                return short ? moment().format('DD/MM/YYYY HH:mm') : moment().format('DD/MM/YYYY HH:mm:ss')
            }
            return short ? moment().format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm:ss')
        },
        dateFormat: function (date, format) {
            if (date === undefined || date === null || date.length < 1) {
                return ''
            }
            format = format || 'br'
            if (format === 'br') {
                return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else if (format === 'en') {
                return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
            }
        },
        dayMonthFormat: function (date, format) {
            format = format || 'br'
            if (format === 'br') {
                return (date ? moment(date, 'YYYY-MM-DD') : moment()).format('DD/MM')
            } else if (format === 'en') {
                return (date ? moment(date, 'DD/MM/YYYY') : moment()).format('MM-DD')
            }
        },
        yearMonthFormat: function (date, format) {
            format = format || 'br'
            if (format === 'br') {
                return (date ? moment(date, 'YYYY-MM-DD') : moment()).format('MM/YYYY')
            } else if (format === 'en') {
                return (date ? moment(date, 'DD/MM/YYYY') : moment()).format('YYYY-MM')
            }
        },
        dateTimeToTimeFormat: function (datetime, short) {
            if (datetime === undefined || datetime === null || datetime.length < 1) {
                return ''
            }
            let momentInstance = getMomentInstance(datetime)
            if (!momentInstance) {
                return ''
            }
            short = short || false
            let resultFormat = (short ? 'HH:mm' : 'HH:mm:ss')
            return momentInstance.format(resultFormat)
        },
        dateTimeFormat: function (datetime, short) {
            if (datetime === undefined || datetime === null || datetime.length < 1) {
                return ''
            }
            short = short || false
            return short ? moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm') : moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
        },
        dateTimeFormatDate: function (datetime, format) {
            if (datetime === undefined || datetime === null || datetime.length < 1) {
                return ''
            }
            format = format || 'en'
            return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format(format === 'br' ? 'DD/MM/YYYY' : 'YYYY-MM-DD')
        },
        dateTimeFormatTime: function (datetime, short) {
            if (datetime === undefined || datetime === null || datetime.length < 1) {
                return ''
            }
            short = short || false
            return short ? moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
        },
        todayAddDays: function (formatBr, days = 3) {
            return moment().add(days, 'days').format(formatBr ? 'DD/MM/YYYY' : 'YYYY-MM-DD')
        },
        todaySubDays: function (formatBr, days = 3) {
            return moment().subtract(days, 'days').format(formatBr ? 'DD/MM/YYYY' : 'YYYY-MM-DD')
        },
        dateFirstDayOfMonth: function (formatBr) {
            if (!formatBr) {
                return moment(moment().format('YYYY-MM-01'), 'YYYY-MM-DD').toDate()
            } else {
                return moment(moment().format('01/MM/YYYY'), 'DD/MM/YYYY').toDate()
            }
        },
        dateLastDayOfMonth: function (formatBr) {
            if (!formatBr) {
                return moment(moment().format('YYYY-MM-') + moment().daysInMonth(), 'YYYY-MM-DD').toDate()
            } else {
                return moment(moment().daysInMonth() + moment().format('/MM/YYYY'), 'DD/MM/YYYY').toDate()
            }
        },
        dateCalendarToString: function (date) {
            if (date === undefined || date === null || date.length < 1) {
                return ''
            }
            if (date.indexOf('T') > -1) {
                let dateSplit = date.split('T')
                return dateSplit[0]
            }
            return date
        },
        dateRemoveTime: function (date) {
            const newDate = date.split(' ')
            return newDate[0]
        },
        dateToWeekday: function (date) {
            return this.weekdayString(moment(date, 'YYYY-MM-DD').weekday())
        },
        timeShort: function (time) {
            return moment(time, 'HH:mm:ss').format('HH:mm')
        },
        currencyParser: currencyParser,
        currencyFormatter: function (number, config) {
            config = currencyConfig(config)
            try {
                if (number === null || number === undefined || number === 'NaN') {
                    number = 0
                }
                return (new Intl.NumberFormat(config.locale, {
                    minimumFractionDigits: config.minimumFractionDigits,
                    maximumFractionDigits: config.maximumFractionDigits,
                    currency: config.currency,
                    ...(config?.showSymbol && config?.symbol && {style: 'currency'}),
                    ...(config?.showSymbol && config?.symbol && {currencyDisplay: 'narrowSymbol'})
                })).format(number);
            } catch (err) {
                console.error(number, config, err)
            }
        },
        onlyNumbers(str) {
            if (str === undefined || str === null) {
                return str
            }
            if (typeof str !== 'string') {
                str = str.toString()
            }
            return str.replace(/\D+/g, '')
        },
        roundLower(value, decimals) {
            let aux = Math.pow(10, decimals)
            return Math.floor(value * aux) / aux
        },
        weekdayString(weekday) {
            switch (weekday) {
                case 0:
                    return 'Domingo'
                case 1:
                    return 'Segunda-Feira'
                case 2:
                    return 'Terça-Feira'
                case 3:
                    return 'Quarta-Feira'
                case 4:
                    return 'Quinta-Feira'
                case 5:
                    return 'Sexta-Feira'
                case 6:
                    return 'Sábado'
            }
        },
        stringFirstChars(string, count) {
            count = count || string.length
            return string.substr(0, count)
        },
        validationMessageField(field, types, value = null) {
            types = types || ['required']
            const errors = []
            if (!field.$dirty) return errors
            if (types.indexOf('required') !== -1) {
                !field.required && errors.push(MESSAGES.VALIDATION_REQUIRED)
            }
            if (types.indexOf('validEmail') !== -1) {
                !field.email && errors.push(MESSAGES.VALIDATION_EMAIL)
            }
            if (types.indexOf('minLength') !== -1) {
                !field.minLength && errors.push(MESSAGES.VALIDATION_MINLENGTH + field.$params.minLength.min)
            }
            if (types.indexOf('maxLength') !== -1) {
                !field.maxLength && errors.push(MESSAGES.VALIDATION_MAXLENGTH + field.$params.maxLength.max)
            }
            if (value && types.indexOf('validCPF') !== -1) {
                !validCPF(value) && errors.push(MESSAGES.VALIDATION_CPF)
            }
            return errors
        },
        getDateHours(date, startHour, endHour, interval, isStage) {
            startHour = startHour || '08:00:00'
            endHour = endHour || '23:59:00'
            interval = interval || 50
            isStage = isStage || true
            const hours = []
            const weekDay = moment(date, 'YYYY-MM-DD').day()
            if (isStage) {
                if (weekDay === 4 || weekDay === 5) {
                    startHour = '18:00:00'
                }
            }
            if (isStage) {
                if (weekDay === 0) {
                    interval = 60
                }
            }
            let currentHour = moment(startHour, 'HH:mm:ss')
            while (currentHour.isSameOrBefore(moment(endHour, 'HH:mm:ss'))) {
                hours.push(currentHour.format('HH:mm'))
                currentHour.add(interval, 'minutes')
            }
            if (currentHour.format('HH:mm:ss') === '00:00:00') {
                hours.push('00:00')
            }
            return {
                start_hour: hours[0],
                end_hour: hours[hours.length - 1],
                interval: interval,
                list: hours,
                enabled: true
            }
        },
        sortListBy(list, column, direction) {
            column = column || 'id'
            direction = direction || 'asc'
            return orderBy(list, [column], [direction])
        },
        getColors(count) {
            const colors = [
                '#f5396e',
                '#aa6f53',
                '#9328b6',
                '#133eb5',
                '#37b3e8',
                '#1B5E20',
                '#cab924',
                '#F9A825',
                '#FF6F00',
                '#822508',
                '#3e2406',
                '#83d2bc',
                '#34534b'
            ]
            return (colors.sort(() => 0.5 - Math.random())).slice(0, count)
        },
        /**
         * @param base64String
         * @returns {Uint8Array}
         */
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4)
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/')
            const rawData = window.atob(base64)
            const outputArray = new Uint8Array(rawData.length)
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i)
            }
            return outputArray
        },
        isEmpty(value) {
            return isEmpty(value)
        },
        countryPhoneMask(countryCode) {
            switch (countryCode) {
                case 'URY':
                    return '########'

                case 'ARG':
                    return '###########'

                case 'PRY':
                    return '#########'
                default:
                    return '## #####-####'
            }
        },
        entryRecurrentLabel(interval, type) {
            let typeLabel = ''
            switch (type) {
                case 'months':
                    typeLabel = 'mês(es)'
                    break
                case 'years':
                    typeLabel = 'ano(s)'
                    break
                default:
                    typeLabel = 'dia(s)'
            }
            return `A cada ${interval} ${typeLabel}`
        },
        slugify(str, separator = '-') {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to = "aaaaeeeeiiiioooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, separator) // collapse whitespace and replace by -
                .replace(/-+/g, separator); // collapse dashes

            return str;
        }
    }
}
