<script>

import {TOTEM_VALIDATION_OPTION_LABELS} from "../../../../service";

export default {
  name: 'totem-facial-register',
  computed: {
    optionsLabels() {
      return TOTEM_VALIDATION_OPTION_LABELS
    }
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    module: {
      type: Object,
    }
  },
}
</script>

<template>
  <div :class="{'disabled': !enabled}">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-form-item :label="`Habilitar ${module.name}`" size="small">
          <el-switch v-model="module.enabled"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="Não">
          </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-form-item v-if="module.enabled" label="Opções de Validação">
          <el-button size="small" type="primary">
            {{optionsLabels.facial}}
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider/>
  </div>
</template>
