<script>
export default {
  name: 'credit-pack-nf-type-label',
  props: {
    nfType: {
      type: String,
      default: 'nfce'
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  computed: {
    label() {
      if (this.nfType === 'nfce') {
        return 'NFCe'
      }
      return 'NFSe'
    }
  }
}
</script>

<template>
  <el-tag :size="size" type="warning">{{ label }}</el-tag>
</template>
