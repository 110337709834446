<script>
export default {
  name: 'client-name',
  props: {
    client: {
      type: Object
    },
    customName: {
      type: String
    },
    nameClass: {
      type: String,
      default: ''
    },
    showNickname: {
      type: Boolean,
      default: true
    },
    showPhone: {
      type: Boolean,
      default: false
    },
    paid: {
      type: Boolean,
      default: false
    },
    fullNameLimit: {
      type: Number,
      default: 0
    }
  },
  computed: {
    name() {
      if (!this.client) {
        return ''
      }
      return (this.customName || (this.showNickname && this.client.nickname) || this.client.name).toLowerCase()
    },
    showFullName() {
      return this.fullNameLimit === 0 || (this.fullNameLimit > 0 && this.name.length <= this.fullNameLimit)
    },
    showName() {
      if (!this.showFullName) {
        const nameSplit = this.name.split(' ')
        return `${nameSplit.at(0)} ${nameSplit.at(-1)}`
      }
      return this.name
    },
    dots() {
      if (this.showDots && this.client) {
        return this.client.active_plans.filter((item) => item.plan.show_client_dot).map(function (item) {
          return {
            title: item.plan.name,
            bg_color: item.plan.bg_color,
            status: item.status,
          }
        })
      }
      return []
    },
    showDots() {
      return this.client && this.client.active_plans && this.client.active_plans.length > 0
    },
    padding() {
      return this.dots.length * 3
    },
    phone() {
      return this.client.phone
    }
  }
}
</script>

<template>
  <div class="plans-dots-container client-name-component" :style="{paddingRight: `${padding}px`}">
    <el-tooltip effect="dark"
                content="Pago"
                placement="top">
      <span v-if="paid" class="paid">
        <i class="fas fa-dollar-sign"></i>
      </span>
    </el-tooltip>
    <el-tooltip effect="dark"
                placement="right"
                :disabled="showFullName">
      <span :class="nameClass" style="text-transform:capitalize !important;">
        {{ showName }}
        <div class="fs-16 pt-1" v-if="showPhone && !!phone">{{phone}}</div>
      </span>
      <div slot="content" style="text-transform:capitalize !important;">{{name}}</div>
    </el-tooltip>
    <div class="dots"
         v-if="showDots">
      <template v-for="(dot, index) in dots">
        <el-tooltip effect="dark"
                    placement="right"
                    :key="index">
          <div slot="content">
            {{ dot.title }}
            <div class="mt-1">
              Status:
              <el-tag effect="dark" size="mini" :type="dot.status === 'active' ? 'success' : 'warning'">
                {{ dot.status === 'active' ? 'Ativo' : 'Aguar. Renovação' }}
              </el-tag>
            </div>
          </div>
          <div :class="['dot', {'-no-bg': !dot.bg_color}]" :style="{backgroundColor: `${dot.bg_color}`}"/>
        </el-tooltip>
      </template>
    </div>
  </div>
</template>