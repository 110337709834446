<script>
import FormSaveButton from '@components/form/SaveButton'
import * as notifyService from '@app/services/notifyService'
import {
  defaultTenantPayment,
  eventOffReloadSettingsType,
  eventOnReloadSettingsType,
  getTenantSettings,
  saveTenantSettings,
  SETTINGS_TYPES
} from '../../../service'
import {mapGetters} from 'vuex'

const SETTINGS_TYPE = SETTINGS_TYPES.tenant_payment

export default {
  components: {FormSaveButton},
  name: 'payment',
  data() {
    return {
      isComponentLoading: true,
      isFirstLoad: true,
      isSubmitted: false,
      form: {...defaultTenantPayment}
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasProfessionalPlan', 'hasCommercialInvoiceModule', 'hasCommercialReceiptModule']),
    hasPaymentAccount() {
      return (this.tenant?.has_payment_account ?? false)
    },
  },
  async mounted() {
    eventOnReloadSettingsType(SETTINGS_TYPE, this.onLoad)
    this.onLoad()
  },
  beforeDestroy() {
    eventOffReloadSettingsType(SETTINGS_TYPE)
  },
  methods: {
    async onLoad() {
      this.isComponentLoading = true
      this.getSettings()
    },
    async getSettings() {
      const result = await getTenantSettings(SETTINGS_TYPE)
      this.form = {...this.form, ...result.data}
      setTimeout(() => {
        this.isFirstLoad = false
        this.isComponentLoading = false
      }, 200)
    },
    onSaveSettings() {
      this.isComponentLoading = true
      saveTenantSettings({type: SETTINGS_TYPE, settings: {...this.form}}, true)
          .then((settings) => {
            notifyService.success()
            this.form = {...this.form, ...settings.data}
            this.isComponentLoading = false
          })
          .catch(e => {
            this.isComponentLoading = false
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <el-card class="el-card__save_form box-car el-loading-mask-light"
           :class="{'tenant-card-settings-loading' : isComponentLoading}"
           v-loading="isComponentLoading"
           element-loading-text="Carregando dados...">
    <div slot="header">
      <span>Financeiro</span>
      <div v-if="!hasProfessionalPlan">
        <el-alert type="error"
                  show-icon
                  :closable="false"
                  class="ml-1"
                  center>
          <div slot="title" class="text-center">
            Disponível no plano de gestão profissional
          </div>
        </el-alert>
      </div>
    </div>
    <div :class="{'disabled': !hasProfessionalPlan}">
      <template>
        <el-form-item label="Abertura e Fechamento de Caixa">
          <el-switch v-model="form.teller_control_enabled"
                     :active-value="true"
                     :inactive-value="false"
                     active-text="SIM"
                     inactive-text="NÃO">
          </el-switch>
        </el-form-item>
        <el-divider class="mt-2 mb-3"/>
      </template>
      <el-row :gutter="20">
        <el-col :span="24">
          <h4>Enviar Mensagem para o Whatsapp dos Clientes</h4>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Para comandas NÃO PAGAS">
            <el-switch v-model="form.order_whatsapp_message.unpaid"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Para comandas PAGAS">
            <el-switch v-model="form.order_whatsapp_message.paid"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Mensagem Customizada para o Whatsapp">
        <el-input type="textarea"
                  :rows="3"
                  v-model="form.entry_portion_notify_custom_message"/>
      </el-form-item>
      <el-divider class="mt-2 mb-3"/>
      <template v-if="hasCommercialInvoiceModule || hasCommercialReceiptModule">
        <el-row :gutter="20">
          <el-col :span="24">
            <h4>Gerar nota fiscal no fechamento de comanda</h4>
          </el-col>
          <el-col :span="12" v-if="hasCommercialReceiptModule">
            <el-form-item label="De Produto (NFC-e)">
              <el-switch v-model="form.generate_invoice.product"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="hasCommercialInvoiceModule">
            <el-form-item label="De Serviço (NFS-e)">
              <el-switch v-model="form.generate_invoice.service"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider class="mt-2 mb-3"/>
      </template>
      <div>
        <h4>Pagamento Online de Lançamentos pelo App</h4>
        <el-alert type="warning"
                  class="pt-3 pb-3 mb-3"
                  show-icon
                  :closable="false"
                  v-if="!hasPaymentAccount">
          <div slot="title" class="fs-16">
            Cadastre uma conta de pagamentos online para permitir essa configuração
          </div>
        </el-alert>
        <div v-else class="highlight-card -payment mb-3">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Habilitado" class="mb-2">
                <el-switch v-model="form.order_online_payment.enabled"
                           :active-value="true"
                           :inactive-value="false"
                           active-text="SIM"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Nº de Minutos para Cancelar o Pagamento" class="mb-2">
                <el-input
                    type="number"
                    :min="0"
                    :step="1"
                    :disabled="!form.order_online_payment.enabled"
                    v-model="form.order_online_payment.minutes_to_cancel"
                />
                <div class="el-form-item-info text-left">
                  Após X minutos o sistema irá cancelar o pagamento
                </div>
                <div class="el-form-item-info text-left">Use 0 para não ativar essa opção</div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <form-save-button :event-click="() => onSaveSettings('tenant_payment')"
                        :is-disabled="isComponentLoading"
                        :is-submitted="isSubmitted"/>
    </div>
  </el-card>
</template>
