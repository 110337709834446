<script>
import ListHeader from '../../../../components/list/Header'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as fileService from '../../../../services/fileService'

import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from "vuex";

const defaultFilters = {
  type: 'revenue',
  use_amount: true,
  amount: 0,
  start_date: helpers.methods.dateNow('en') + ' 00:00:00',
  end_date: helpers.methods.dateNow('en') + ' 23:59:59',
  entry_current_date: false,
  payment_method_id: '',
  bank_account_id: '',
  product_category_id: '',
  classification_id: '',
  created_by_user_id: [],
  teller_ids: [],
  group_by: 'payment_method',
}

export default {
  components: {ListHeader},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isLoadingTellers: false,
      filters: {...defaultFilters},
      list: {
        settled: {
          items: [],
          total: 0,
          total_cash: 0,
          tellers: {
            opening: 0,
            inputs: 0,
            outputs: 0,
            total_diff: 0,
          },
        },
        created: {
          items: [],
          total: 0,
          total_cash: 0
        },
        created_by_users: {
          items: [],
          total: 0,
          total_cash: 0
        },
        classifications: {
          items: [],
          total: 0,
          total_cash: 0
        },
        total: 0
      },
      dependencies: {
        payment_methods: [],
        bank_accounts: [],
        product_categories: [],
        classifications: [],
        tenant_users: [],
        entry_tellers: [],
      }
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    moneyMask() {
      return currencyConfig()
    },
    filtersDatesEqual() {
      return (this.filters.start_date && !this.filters.end_date) || (this.filters.start_date && this.filters.end_date && this.filters.start_date === this.filters.end_date)
    },
    settledItemsGroup() {
      if (this.filters.group_by === 'user') {
        return this.list.created_by_users.items
      }
      if (this.filters.group_by === 'classifications') {
        return this.list.classifications.items
      }
      return this.list.settled.items
    },
    hasTellerControlEnabled() {
      return !!this.tenant?.teller_control || false
    },
  },
  watch: {
    'filters.start_date': function () {
      this.getEntryTellers()
    },
    'filters.end_date': function () {
      this.getEntryTellers()
    },
  },
  mounted() {
    this.getDependencies()
    this.getTenantUsers()
    this.getEntryTellers()
    this.onFilter()
    bus.$emit('hide-loader')
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'payment_method'}, {domain: 'bank_account'}, {domain: 'product_category'}, {domain: 'classification_revenue'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    async getTenantUsers() {
      const result = await restfulService.post('user/search', null, null, {
        status: 'active',
        orderBy: [
          {
            column: 'name',
            direction: 'ASC'
          }
        ]
      })
      this.dependencies.tenant_users = [...result.data]
    },
    async getEntryTellers() {
      if (!this.hasTellerControlEnabled) {
        return
      }
      this.isLoadingTellers = true
      this.dependencies.entry_tellers.splice(0)
      if (!!this.filters.start_date && !!this.filters.end_date) {
        const result = await restfulService.post('entry_teller', 'list', null, {
          type: 'managed',
          start_date: this.filters.start_date,
          end_date: this.filters.end_date,
        })
        this.dependencies.entry_tellers = [...result]
      }
      this.isLoadingTellers = false
    },
    getRequestData() {
      let data = {
        type: this.filters.type,
        amount: this.currencyParser(this.filters.amount),
        start_date: this.filters.start_date,
        teller_ids: this.filters.teller_ids,
        teller_all: this.filters.teller_ids.length === 0
      }
      if (!this.filters.use_amount) {
        data.amount = 0
      }
      if (this.filters.end_date) {
        data.end_date = this.filters.end_date
      }
      if (this.filters.payment_method_id) {
        data.payment_methods = [this.filters.payment_method_id]
      }
      if (this.filters.bank_account_id) {
        data.bank_account_id = this.filters.bank_account_id
      }
      if (this.filters.product_category_id) {
        data.product_category_id = this.filters.product_category_id
      }
      if (this.filters.classification_id) {
        data.classification_id = this.filters.classification_id
      }
      if (this.filters.created_by_user_id.length > 0) {
        data.created_by_user_id = this.filters.created_by_user_id
      }
      data.entry_current_date = this.filtersDatesEqual && this.filters.entry_current_date
      return data
    },
    onFilter() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = this.getRequestData()
      if (this.hasTellerControlEnabled) {
        data.use_amount = true
        data.amount = 0
      }
      restfulService.post('report', 'entry/daily', null, data)
          .then(response => {
            this.list = {...response}
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    generateCsv() {
      bus.$emit('show-loader')
      const data = this.getRequestData()
      data.export = 'csv'
      fileService.get('report', 'entry/daily', null, 'csv', data, false, 'Relatório de Lançamentos')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    onFilterClean() {
      this.filters = {...defaultFilters}
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Relatório de Lançamentos"/>

    <el-card class="el-card__search_list box-card">
      <div slot="header">
        <span>Filtros</span>
        <div>
          <el-button type="primary" @click="onFilter" size="small">
            <i class="fas fa-filter"></i>
            <span>Gerar Relatório</span>
          </el-button>
          <el-button type="success" @click="generateCsv" size="small">
            <i class="fas fa-file-csv"></i>
            <span>Gerar em Excel/CSV</span>
          </el-button>
          <el-button type="default" @click="onFilterClean" size="small">
            <i class="fas fa-times"></i>
            <span>Limpar</span>
          </el-button>
        </div>
      </div>
      <el-form :inline="true" :model="filters">
        <el-form-item label="Tipo">
          <el-select v-model="filters.type"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option label="Receita" value="revenue"/>
            <el-option label="Despesa" value="expense"/>
          </el-select>
        </el-form-item>
        <template v-if="filters.type === 'revenue' && !hasTellerControlEnabled">
          <el-form-item label="Incluir Troco">
            <el-switch v-model="filters.use_amount" active-text="SIM" inactive-text="NÃO"/>
          </el-form-item>
          <el-form-item label="Troco em Caixa">
            <el-input v-model.lazy="filters.amount"
                      :disabled="!filters.use_amount"
                      v-money="moneyMask"/>
          </el-form-item>
        </template>
        <el-form-item label="Data Inicial">
          <el-date-picker v-model="filters.start_date"
                          placeholder="Data Inicial"
                          type="datetime"
                          format="dd/MM/yyyy HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Data Final">
          <el-date-picker v-model="filters.end_date"
                          placeholder="Data Final"
                          type="datetime"
                          format="dd/MM/yyyy HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Caixas"
                      v-if="hasTellerControlEnabled">
          <el-select v-model="filters.teller_ids"
                     clearable
                     multiple
                     :placeholder="isLoadingTellers ? 'Buscando caixas...' : 'Todos os caixas'"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.entry_tellers"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Forma de Pagamento">
          <el-select v-model="filters.payment_method_id"
                     filterable
                     clearable
                     placeholder="Selecione uma forma de pagamento"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.payment_methods"
                :key="item.id"
                :label="item.description"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Conta Bancária">
          <el-select v-model="filters.bank_account_id"
                     filterable
                     clearable
                     placeholder="Selecione uma conta bancária"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.bank_accounts"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Categoria de Produtos" v-if="false && filters.type === 'revenue'">
          <el-select v-model="filters.product_category_id"
                     filterable
                     clearable
                     placeholder="Selecione uma categoria"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.product_categories"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Classificação">
          <el-select v-model="filters.classification_id"
                     filterable
                     clearable
                     placeholder="Selecione uma classificação"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option
                v-for="item in dependencies.classifications"
                :key="item.id"
                :label="item.description"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Usuário" style="min-width: 250px;">
          <el-select
              v-model="filters.created_by_user_id"
              multiple
              filterable
              placeholder="Selecione os usuários"
              empty-text="Nenhum usuário encontrado"
              class="el-select-full">
            <el-option
                v-for="item in dependencies.tenant_users"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Agrupamento">
          <el-select v-model="filters.group_by"
                     placeholder="Selecione um agrupamento"
                     class="el-select-full"
                     @keydown.enter.native.prevent="onFilter">
            <el-option label="Forma de Pagamento" value="payment_method"/>
            <el-option label="Classificação" value="classifications"/>
            <el-option label="Usuário" value="user"/>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="font-weight-bold">
        <div class="el-form-item-description text-danger"
             v-if="filters.teller_ids.length > 0">
          * Os campos data inicial e final não serão considerados ao selecionar um ou mais caixas
        </div>
      </div>
    </el-card>

    <div class="main-list responsive-list space-top">
      <div class="el-card el-card__search_list box-card">
        <div class="el-card__body">
          <div class="table-list">
            <div v-if="filters.group_by === 'payment_method'">
              <h3>Quitados</h3>
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th>Forma de Pagamento</th>
                  <th>Qtd (Lançamentos)</th>
                  <th align="right">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in settledItemsGroup">
                  <td>{{ item.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td align="right">{{ item.total | currencyFormatter }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th colspan="2" align="right">Total Geral:</th>
                  <th align="right">{{ list.settled.total | currencyFormatter }}</th>
                </tr>
                </tfoot>
              </table>
            </div>
            <div v-else-if="filters.group_by === 'user'">
              <h3>Quitados (Agrupado por usuário)</h3>
              <template v-for="user in settledItemsGroup">
                <h4 class="mt-4 mb-1">{{ user.name }}</h4>
                <table class="custom-table-list">
                  <thead>
                  <tr>
                    <th>Forma de Pagamento</th>
                    <th>Qtd (Lançamentos)</th>
                    <th align="right">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in user.items">
                    <td>{{ item.name }}</td>
                    <td>{{ item.quantity }}</td>
                    <td align="right">{{ item.total | currencyFormatter }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr v-if="hasTellerControlEnabled">
                    <th colspan="2" align="right">Total em Caixa:</th>
                    <th align="right">{{ user.total_cash | currencyFormatter }}</th>
                  </tr>
                  <tr>
                    <th colspan="2" align="right">Total Geral:</th>
                    <th align="right">{{ user.total | currencyFormatter }}</th>
                  </tr>
                  </tfoot>
                </table>
              </template>
              <h3 class="mt-3 mb-1">TOTAL GERAL</h3>
              <table class="custom-table-list">
                <tfoot>
                <tr v-if="hasTellerControlEnabled">
                  <th colspan="2" align="right">Total em Caixa:</th>
                  <th align="right">{{ list.created_by_users.total_cash | currencyFormatter }}</th>
                </tr>
                <tr>
                  <th colspan="2" align="right">Total Geral:</th>
                  <th align="right">{{ list.created_by_users.total | currencyFormatter }}</th>
                </tr>
                </tfoot>
              </table>
            </div>
            <div v-else-if="filters.group_by === 'classifications'">
              <h3>Quitados (Agrupado por Classificação)</h3>
              <template v-for="data in settledItemsGroup">
                <h4 class="mt-4 mb-1">{{ data.name }}</h4>
                <table class="custom-table-list">
                  <thead>
                  <tr>
                    <th>Forma de Pagamento</th>
                    <th>Qtd (Lançamentos)</th>
                    <th align="right">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in data.items">
                    <td>{{ item.name }}</td>
                    <td>{{ item.quantity }}</td>
                    <td align="right">{{ item.total | currencyFormatter }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr v-if="filters.use_amount">
                    <th colspan="2" align="right">Total em Caixa:</th>
                    <th align="right">{{ data.total_cash | currencyFormatter }}</th>
                  </tr>
                  <tr>
                    <th colspan="2" align="right">Total Geral:</th>
                    <th align="right">{{ data.total | currencyFormatter }}</th>
                  </tr>
                  </tfoot>
                </table>
              </template>
              <h3 class="mt-3 mb-1">TOTAL GERAL</h3>
              <table class="custom-table-list">
                <tfoot>
                <tr v-if="filters.use_amount">
                  <th colspan="2" align="right">Total em Caixa:</th>
                  <th align="right">{{ list.classifications.total_cash | currencyFormatter }}</th>
                </tr>
                <tr>
                  <th colspan="2" align="right">Total Geral:</th>
                  <th align="right">{{ list.classifications.total | currencyFormatter }}</th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="table-list mt-3">
            <div>
              <div class="mb-4" v-if="hasTellerControlEnabled">
                <h3>Movimentações de Caixa</h3>
                <table class="custom-table-list">
                  <tbody>
                  <tr>
                    <td colspan="2">Abertura</td>
                    <td align="right">{{ list.settled.tellers.opening | currencyFormatter }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">Suprimentos</td>
                    <td align="right">{{ list.settled.tellers.inputs | currencyFormatter }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">Sangrias</td>
                    <td align="right">{{ list.settled.tellers.outputs | currencyFormatter }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <h3>Lançamentos Abertos</h3>
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th>Forma de Pagamento</th>
                  <th>Qtd (Lançamentos)</th>
                  <th align="right">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in list.created.items">
                  <td>{{ item.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td align="right">{{ item.total | currencyFormatter }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th colspan="2" align="right">Total Geral:</th>
                  <th align="right">{{ list.created.total | currencyFormatter }}</th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="table-list" v-if="false">
            <div>
              <table class="custom-table-list">
                <tfoot>
                <tr>
                  <th align="right">Total:</th>
                  <th align="right" :class="{'-green': list.total >= 0, '-red': list.total < 0}">
                    {{ list.total | currencyFormatter }}
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
