<script>
export default {
  name: 'match-pair-names',
  props: {
    pairNames: {
      type: String,
      default: ''
    },
    player1CheckinAt: {
      type: String,
      default: ''
    },
    player2CheckinAt: {
      type: String,
      default: ''
    }
  },
  computed: {
    player1Name() {
      const [player1Name,] = this.pairNames.split(' /<br>')
      return player1Name
    },
    player2Name() {
      const [, player2Name] = this.pairNames.split(' /<br>')
      return player2Name
    },
  }
}
</script>

<template>
  <div>
    <span>{{ player1Name }}</span>
    <el-tooltip effect="dark"
                placement="top">
      <div slot="content">
        Check-in: {{ player1CheckinAt | dateTimeEnToBr }}h
      </div>
      <span v-if="!!player1CheckinAt" class="ml-1 text-success">
        <i class="fas fa-check-circle"></i>
      </span>
    </el-tooltip>
    <br>
    <span>{{ player2Name }}</span>
    <el-tooltip effect="dark"
                placement="top">
      <div slot="content">
        Check-in: {{ player2CheckinAt | dateTimeEnToBr }}h
      </div>
      <span v-if="!!player2CheckinAt" class="ml-1 text-success">
        <i class="fas fa-check-circle"></i>
      </span>
    </el-tooltip>
  </div>
</template>
