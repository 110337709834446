<script>
import bus from '@utils/bus'
import ListHeader from '../../../../components/list/Header'
import {required} from 'vuelidate/lib/validators'
import DataService from '../../../../services/dataService'
import FormSaveButton from '../../../../components/form/SaveButton'
import FormCancelButton from '../../../../components/form/CancelButton'
import * as restfulService from '../../../../services/restfulService'
import {clone} from 'lodash'
import * as notifyService from '../../../../services/notifyService'
import {currencyConfig} from '@utils/money'
import helpers from '@mixins/helpers'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton},
  mixins: [helpers],
  data() {
    return {
      updatePrice: false,
      form: {
        target_id: null,
        name: '',
        code: '',
        discount_type: '', //amount, amount_replace
        value: null,
        uses_limit: 0,
        start_datetime: null,
        end_datetime: null,
        status: 'active',
        data: {
          player_data: [],
          unique_discount_by_player: false
        }
      },
      dependencies: {
        stages: [],
        coupon_discount_types: [],
      },
      isSubmitted: false,
      isEdit: false,
    }
  },
  computed: {
    moneyMask() {
      return currencyConfig()
    },
  },
  validations: {
    form: {
      target_id: {required},
      name: {required},
      code: {required},
      discount_type: {required},
      value: {required},
      uses_limit: {required},
      status: {required}
    }
  },
  watch: {
    'form.name': function (value) {
      this.form.code = this.slugify(value, '_').toUpperCase()
    },
  },
  async mounted() {
    bus.$emit('hide-loader')
    await this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('coupon', null, id)
          .then(response => {
            response.value = this.currencyFormatter(response.value)
            this.updatePrice = true
            this.form = {...response}
            if (response.data?.player_data === undefined) {
              this.form.data = {
                ...this.form.data,
                ...{player_data: []}
              }
            }
            this.isLoadingData = false
            setTimeout(() => {
              this.updatePrice = false
            }, 100)
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'stage', data: {status: 'active'}},
          {domain: 'coupon_discount_type'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    onSend() {
      const data = clone(this.form)
      if (data.data.player_data.length === 0) {
        delete data.data.player_data
      } else {
        data.uses_limit = 1
      }
      restfulService.save('coupon', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'coupon.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    },
    generateRandomCode() {
      this.form.code = (Math.random() + 1).toString(36).substring(7).toUpperCase()
    },
    onSelectPlayerData(value) {
      const currentValues = value[0]
      if (value.length > 0) {
        const multipleValueCheck = value[value.length - 1]
        if (multipleValueCheck.includes(',')) {
          const values = multipleValueCheck.split(',')
          this.form.data.player_data = [...new Set([currentValues, ...values])]
        }
      }
    }
  },
}
</script>
<template>
  <div>
    <ListHeader title="Cupons"/>
    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Dados do Cupom</span>
      </div>

      <el-form label-position="top">

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Nome do Cupom" class="is-required"
                          :class="{'el-form-item-error': $v.form.name.$error}">
              <el-input v-model="form.name" placeholder="Nome do cupom" @input="$v.form.name.$touch()"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Código do Cupom" class="is-required"
                          :class="{'el-form-item-error': $v.form.code.$error}">
              <el-input v-model="form.code" placeholder="Código do Cupom" @input="$v.form.code.$touch()">
                <el-button @click="generateRandomCode" slot="prepend" title="Gerar código randômico">
                  <i class="fas fa-random"></i>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Tipo de Desconto" class="is-required"
                          :class="{'el-form-item-error': $v.form.discount_type.$error}">
              <el-select v-model="form.discount_type"
                         :disabled="isEdit"
                         placeholder="Selecione o tipo de desconto"
                         class="el-select-full">
                <el-option v-for="item in dependencies.coupon_discount_types" :key="item.id"
                           :label="item.description"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
                label="Valor do Desconto"
                class="is-required"
                :class="{'el-form-item-error': $v.form.value.$error}">
              <el-input
                  v-if="!updatePrice"
                  v-model.lazy="form.value"
                  v-money="moneyMask"
                  @input="$v.form.value.$touch()">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Quantidade de Usos" class="is-required"
                          :class="{'el-form-item-error': $v.form.uses_limit.$error}">
              <el-input type="number" v-model="form.uses_limit"
                        placeholder="Quantidade de Usos"
                        @input="$v.form.uses_limit.$touch()"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Data Inicial">
              <el-date-picker v-model="form.start_datetime"
                              type="date"
                              placeholder="Data Inicial"
                              format="dd/MM/yyyy"
                              value-format="yyyy-MM-dd"
                              v-mask="'##/##/####'">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Data Final">
              <el-date-picker v-model="form.end_datetime"
                              type="date"
                              placeholder="Data Final"
                              format="dd/MM/yyyy"
                              value-format="yyyy-MM-dd"
                              v-mask="'##/##/####'">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Status" class="is-required" :class="{'el-form-item-error': $v.form.status.$error}">
              <el-select v-model="form.status" placeholder="Selecione o status do cupom" class="el-select-full">
                <el-option label="Criado" value="created"></el-option>
                <el-option label="Ativo" value="active"></el-option>
                <el-option v-if="isEdit" label="Cancelado" value="canceled"></el-option>
                <el-option v-if="isEdit" label="Expirado" value="expired"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Torneio"
                          class="is-required"
                          :class="{'el-form-item-error': $v.form.target_id.$error}">
              <el-select placeholder="Selecione o torneio para o cupom"
                         empty-text="Nenhum registro encontrado"
                         v-model="form.target_id"
                         class="el-select-full"
                         :disabled="isEdit"
                         filterable
                         clearable>
                <el-option v-for="item in dependencies.stages" :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Desconto único por CPF/Email/Telefone">
              <el-switch v-model="form.data.unique_discount_by_player"
                         class="mt-1"
                         active-text="SIM"
                         inactive-text="Não">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="Aplicar Desconto para o(s) CPF/Email/Telefone(s):">
              <el-select v-model="form.data.player_data"
                         multiple
                         allow-create
                         default-first-option
                         @change="onSelectPlayerData"
                         filterable
                         placeholder="Digite o CPF, Email ou Telefone"
                         class="el-select-full"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.$invalid"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="coupon.index"/>
        </el-form-item>
      </el-form>

    </el-card>
  </div>
</template>
