<script>
import ListMain from '../../../../components/list/Main'
import ListExport from '@components/list/Export'
import ListHeader from '../../../../components/list/Header'
import ListTheadColumn from '../../../../components/list/TheadColumn'
import GripoDialog from '../../../../components/common/Dialog'
import ShowEntry from '../../entry/components/ShowEntry'
import InvoiceStatusLabel from './StatusLabel'
import InvoiceTypeLabel from './TypeLabel'
import InvoiceEnvLabel from './EnvLabel'
import ShowPdf from '@app/components/common/ShowPdf'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'
import * as fileService from '@app/services/fileService'
import {showInvoicePdf, showInvoiceXml} from '../service'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {confirmDialog} from "@utils/flash";
import {mapGetters} from 'vuex'

import moment from 'moment'
import {cloneDeep} from 'lodash'
import {required} from 'vuelidate/lib/validators'

const defaultSort = {
  env: 'production',
  orderBy: [{column: 'created_at', direction: 'desc'}]
}

export default {
  components: {
    ShowPdf,
    GripoDialog,
    ListTheadColumn,
    ListMain,
    ListHeader,
    ShowEntry,
    InvoiceStatusLabel,
    InvoiceTypeLabel,
    InvoiceEnvLabel,
    ListExport,
  },
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      showModalGenerateInvoice: false,
      cancelModal: {
        show: false,
        invoice: {},
        reason: ''
      },
      selectedList: [],
      credits: {
        nfce: 0,
        nfse: 0,
      },
      filters: {
        type: 'all',
        env: 'production',
        number: '',
        client_name: '',
        cpf: '',
        status: 'all',
        date_field: 'created_at',
        show_archived: false,
      },
      dependencies: {},
      pdfBase64String: '',
      invoiceType: 'nfce',
      entryIdSelected: null,
      generateInvoiceData: {
        nfce: true,
        nfce_include_cpf: true,
        nfce_send_notification: true,
        nfse: true,
        nfse_include_cpf: true,
        nfse_send_notification: true,
        entry_portion: {
          type: 'revenue',
          id: ''
        }
      },
      archiveInvoices: {
        isModalVisible: false,
        all: false,
        type: [],
        status: ['not_authorized', 'canceled'],
        invoices: []
      },
      exportFields: [
        {field: 'type', label: 'Tipo'},
        {field: 'number', label: 'Número'},
        {field: 'status', label: 'Status'},
        {field: 'created_at', label: 'Data de solicitação'},
        {field: 'approved_at', label: 'Data de aprovação'},
        {field: 'client_name', label: 'Cliente'},
        {field: 'cpf', label: 'CPF/CNPJ'},
        {field: 'amount_en', label: 'Valor'},

      ],
      exportSortFields: [
        {field: 'type', label: 'Tipo'},
        {field: 'number', label: 'Número'},
        {field: 'status', label: 'Status'},
        {field: 'created_at', label: 'Data de solicitação'},
        {field: 'approved_at', label: 'Data de aprovação'},
        {field: 'client_name', label: 'Cliente'},
        {field: 'cpf', label: 'CPF/CNPJ'},
        {field: 'amount_en', label: 'Valor'},
      ],
      pdfBase64StringExport: '',
    }
  },
  validations: {
    cancelModal: {
      invoice: {
        id: {required}
      },
      reason: {required}
    }
  },
  mounted() {
    this.getCreditsInfo()
    bus.$emit('list-init', {
      domain: 'invoice',
      relations: ['entry.portions', 'entry.client', 'entry.invoice', 'order_product.invoice'],
      data: this.prepareSearchData()
    }, () => {
      if (this.hasCommercialReceiptModule) {
        this.archiveInvoices.type.push('nfce')
      }
      if (this.hasCommercialInvoiceModule) {
        this.archiveInvoices.type.push('nfse')
      }
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore', 'tenant', 'hasCommercialReceiptModule', 'hasCommercialInvoiceModule']),
    amountTotal() {
      return this.listStore.listData.reduce((total, item) => total + item.amount_en, 0)
    },
    hasInvoiceNotApproved() {
      return this.listStore.listData.some(item => item.status !== 'approved')
    },
    isAllChecked() {
      return this.listStore.listData.length > 0 && this.selectedList.length === this.listStore.listData.length
    },
    nfseCityConfig() {
      return (this.tenant?.settings?.tenant_nf?.nfse?.city_config ?? null)
    },
    nfseCityConfigCpfCnpjObrigatorioNfse() {
      return this.nfseCityConfig?.cpf_cnpj_obrigatorio_nfse ?? false
    },
    shouldChargeInvoices() {
      return moment().isSameOrAfter(moment('2025-01-25'))
    }
  },
  methods: {
    prepareSearchData() {
      const searchData = {...defaultSort}
      if (this.filters.number) {
        searchData.number = {
          'like': '%' + this.filters.number + '%'
        }
      }
      if (this.filters.dates && this.filters.dates.length > 0) {
        let startDate = this.dateFormat(this.filters.dates[0], 'en')
        let endDate = this.dateFormat(this.filters.dates[1], 'en')
        if (startDate.length > 0 && endDate.length > 0) {
          searchData[this.filters.date_field] = {between: [startDate + ' 00:00:00', endDate + ' 23:59:59']}
        }
      }
      if (this.filters.client_name) {
        searchData.client_name = {
          'like': '%' + this.filters.client_name + '%'
        }
      }
      if (this.filters.cpf) {
        searchData.cpf = {
          'like': '%' + this.onlyNumbers(this.filters.cpf) + '%'
        }
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      if (this.filters.type !== 'all') {
        searchData.type = this.filters.type
      }
      if (this.filters.env !== 'all') {
        searchData.env = this.filters.env
      } else {
        delete searchData['env']
      }
      if (!this.filters.show_archived) {
        searchData.archived_at = {isNull: true}
      }
      return searchData
    },
    onFilter() {
      this.getCreditsInfo()
      this.resetSelectedList()
      const searchData = this.prepareSearchData()
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {status: 'all', dates: [], type: 'all', env: 'production'}
      const data = this.prepareSearchData()
      bus.$emit('list-filter-clean', data)
    },
    resetSelectedList() {
      this.listStore.listData.forEach(entry => {
        entry.checked = false
      })
      this.selectedList.splice(0)
    },
    checkItem(listItem) {
      if (this.selectedList.some(item => item === listItem.id)) {
        this.selectedList = this.selectedList.filter(item => item !== listItem.id)
        listItem.checked = false
      } else {
        this.selectedList.push(listItem.id)
        listItem.checked = true
      }
    },
    checkAll() {
      if (this.isAllChecked) {
        this.resetSelectedList()
      } else {
        this.selectedList = [...this.listStore.listData.map(item => item.id)]
        this.listStore.listData.forEach(entry => {
          entry.checked = true
        })
      }
    },
    handleCancelModal(invoice) {
      if (invoice) {
        this.cancelModal.invoice = {...cloneDeep(invoice)}
      } else {
        this.cancelModal.invoice = {}
      }
      this.cancelModal.show = !this.cancelModal.show
      if (this.cancelModal.show) {
        setTimeout(() => {
          this.$refs.cancelModalReason.focus()
        }, 200)
      }
    },
    cancelInvoice() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {reason: this.cancelModal.reason}
      restfulService.put('invoice', 'cancel', this.cancelModal.invoice.id, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.onFilter()
            this.handleCancelModal()
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async showInvoicePdf(invoice) {
      try {
        bus.$emit('show-loader')
        const result = await showInvoicePdf(invoice)
        if (invoice.type === 'nfse') {
          this.pdfBase64String = result.base64
        }
        bus.$emit('hide-loader')
      } catch (e) {
        console.log(e)
        bus.$emit('hide-loader')
      }
    },
    async showInvoiceXml(invoice) {
      bus.$emit('show-loader')
      try {
        await showInvoiceXml(invoice)
        bus.$emit('hide-loader')
      } catch (e) {
        console.log(e)
        bus.$emit('hide-loader')
      }
    },
    openGenerateInvoicesModal(entry_portion, type) {
      this.generateInvoiceData.entry_portion_id = ''
      this.generateInvoiceData.entry_portion = {
        id: entry_portion.id,
        type
      }
      this.generateInvoiceData.nfce = this.hasCommercialReceiptModule
      this.generateInvoiceData.nfce_include_cpf = false
      this.generateInvoiceData.nfce_send_notification = false
      this.generateInvoiceData.nfse = this.hasCommercialInvoiceModule
      this.generateInvoiceData.nfse_include_cpf = this.nfseCityConfigCpfCnpjObrigatorioNfse
      this.generateInvoiceData.nfse_send_notification = false
      this.showModalGenerateInvoice = true
    },
    async generateInvoice() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      const data = {
        nfce: this.generateInvoiceData.nfce,
        nfce_include_cpf: this.generateInvoiceData.nfce_include_cpf,
        nfce_send_notification: this.generateInvoiceData.nfce_send_notification,
        nfse: this.generateInvoiceData.nfse,
        nfse_include_cpf: this.generateInvoiceData.nfse_include_cpf,
        nfse_send_notification: this.generateInvoiceData.nfse_send_notification,
      }
      restfulService.put(`entry_portion/${this.generateInvoiceData.entry_portion.type}`, 'generate-invoice', this.generateInvoiceData.entry_portion.id, data)
          .then(({message}) => {
            notifyService.success({message})
            this.isSubmitted = false
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    async checkStatus(item) {
      this.isSubmitted = true
      bus.$emit('show-loader')
      restfulService.post('invoice', 'check-status', item.id, {})
          .then(({message}) => {
            notifyService.success({message})
            this.isSubmitted = false
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    onExport() {
      bus.$emit('show-loader')
      const data = {...this.prepareSearchData()}
      restfulService.post('invoice', 'export', null, data)
          .then(response => {
            notifyService.success({message: response.message})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    async selectedAction(command) {
      if (command.type === 'execute') {
        if (command.action === 'archive') {
          this.archiveInvoices.invoices = [...this.selectedList]
          this.onArchiveInvoices()
        }
        if (command.action === 'process') {
          this.onProcessInvoices()
        }
      }
    },
    toggleArchiveInvoices() {
      this.archiveInvoices.isModalVisible = !this.archiveInvoices.isModalVisible
      if (this.archiveInvoices.isModalVisible) {
        this.archiveInvoices.all = true
        this.archiveInvoices.invoices.splice(0)
      }
    },
    async onArchiveInvoices() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente arquivar as notas selecionadas?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {...this.archiveInvoices}
        if (data.invoices.length > 0) {
          data.all = false
        }
        restfulService.post('invoice', 'archive', null, data)
            .then(response => {
              if (this.archiveInvoices.isModalVisible) {
                this.toggleArchiveInvoices()
              }
              this.onFilter()
              notifyService.success({message: response.message})
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async onProcessInvoices() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente emitir as notas selecionadas?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {invoices: this.selectedList}
        restfulService.post('invoice', 'process', null, data)
            .then(response => {
              this.onFilter()
              notifyService.success({message: response.message})
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    async onUnarchive(item) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente desarquivar essa nota?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {
          all: false,
          unarchive: true,
          invoices: [item.id]
        }
        restfulService.post('invoice', 'archive', null, data)
            .then(response => {
              this.onFilter()
              notifyService.success({message: response.message})
            })
            .catch(e => {
              console.log(e)
              bus.$emit('hide-loader')
            })
      }
    },
    onListExport({format, fields, sort}) {
      const filters = {...this.prepareSearchData()}
      if (format === 'pdf') {
        this.exportPdf(filters, fields, sort)
      } else {
        this.exportCsv(filters, fields, sort)
      }
    },
    exportPdf(filters, fields, sort) {
      bus.$emit('show-loader')
      const searchData = {filters, fields, sort, format: 'pdf'}
      restfulService.post('report/invoice', 'export-search', null, searchData)
          .then(response => {
            this.pdfBase64String = response.file
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    exportCsv(filters, fields, sort) {
      bus.$emit('show-loader')
      const data = {filters, fields, sort, format: 'csv'}
      fileService.get('report/invoice', 'export-search', null, 'csv', data, false, 'Relatório de Notas Fiscais')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    getCreditsInfo() {
      bus.$emit('show-loader')
      restfulService.post('tenant', 'pack-credits', null, {type: 'nf'})
          .then(async (response) => {
            this.credits.nfce = response.credits.nfce
            this.credits.nfse = response.credits.nfse
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
  }
}
</script>

<template>
  <div>

    <show-pdf :base64="pdfBase64String"/>

    <ListHeader title="Notas Fiscais">
      <el-button type="warning" @click="toggleArchiveInvoices">
        <i class="fas fa-archive"></i>
        <span>Arquivar Notas</span>
      </el-button>
    </ListHeader>

    <el-alert v-if="shouldChargeInvoices" type="error" show-icon border :closable="false" effect="dark" class="mb-3">
      <div slot="title" class="fs-16">
        A cobrança pela emissão de notas fiscais irá entrar em vigor a partir do dia 01/02/2025
      </div>
    </el-alert>

    <ListMain>

      <div slot="filters">

        <div class="flex-c -ai-s gap-25">

          <el-card class="el-card__search_list box-card">
            <div slot="header">
              <span>Filtros</span>
              <div>
                <el-button type="primary" @click="onFilter" size="small">
                  <i class="fas fa-filter"></i>
                  <span>Aplicar Filtros</span>
                </el-button>
                <el-button type="default" @click="onFilterClean" size="small">
                  <i class="fas fa-times"></i>
                  <span>Limpar</span>
                </el-button>
                <list-export @export="onListExport"
                             :fields="exportFields"
                             :sort-fields="exportSortFields"
                             :base64-pdf="pdfBase64StringExport"
                             :extra-formats="[
                               {
                                 value: 'xml',
                                 label: 'XML',
                                 exec: () => this.onExport(),
                                 description: 'Essa ação irá exportar um arquivo zip com as notas filtradas e enviará o arquivo para o email do usuário que solicitou a exportação'
                               }
                           ]"/>
              </div>
            </div>
            <el-form :inline="true" :model="filters">
              <el-form-item label="Tipo">
                <el-select v-model="filters.type"
                           clearable
                           placeholder="Filtrar por Tipo">
                  <el-option label="Não filtrar" value="all"/>
                  <el-option label="Produto" value="nfce" v-if="hasCommercialReceiptModule"/>
                  <el-option label="Serviço" value="nfse" v-if="hasCommercialInvoiceModule"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Status">
                <el-select v-model="filters.status"
                           placeholder="Filtrar por status"
                           empty-text="Nenhum registro encontrado">
                  <el-option label="Não filtrar" value="all"/>
                  <el-option label="Aguardando" value="created"/>
                  <el-option label="Aprovada" value="approved"/>
                  <el-option label="Não Autorizada" value="not_authorized"/>
                  <el-option label="Cancelada" value="canceled"/>
                  <el-option label="Em Fila" value="queued"/>
                </el-select>
                <div class="text-center mt-1">
                  <el-form-item label="Mostrar Arquivadas">
                    <el-switch v-model="filters.show_archived"
                               active-text="SIM"
                               inactive-text="Não">
                    </el-switch>
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item label="Ambiente">
                <el-select v-model="filters.env"
                           clearable
                           placeholder="Filtrar por Ambiente">
                  <el-option label="Não filtrar" value="all"/>
                  <el-option label="Produção" value="production"/>
                  <el-option label="Homologação" value="staging"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Período">
                <el-date-picker
                    v-model="filters.dates"
                    type="daterange"
                    format="dd/MM/yyyy"
                    placeholder="Filtrar por datas">
                </el-date-picker>
                <div class="text-center pt-3">
                  <el-form-item>
                    <el-radio v-model="filters.date_field" label="created_at">Dt. Solicitação</el-radio>
                    <el-radio v-model="filters.date_field" label="approved_at">Dt. Aprovação</el-radio>
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item label="Cliente">
                <el-input v-model="filters.client_name"
                          placeholder="Filtrar por Cliente"
                          @keydown.enter.native.prevent="onFilter"/>
              </el-form-item>
              <el-form-item label="CPF/CNPJ">
                <el-input v-model="filters.cpf"
                          placeholder="Filtrar por CPF/CNPJ"
                          @keydown.enter.native.prevent="onFilter"/>
              </el-form-item>
              <el-form-item label="Número">
                <el-input v-model="filters.number"
                          placeholder="Filtrar por Número"
                          @keydown.enter.native.prevent="onFilter"/>
              </el-form-item>
            </el-form>
          </el-card>

          <div class="filters-credits" v-if="false" style="min-width: 150px;">
            <div class="list-card" v-if="hasCommercialReceiptModule">
              <div class="text-center">
                <strong>Créditos para NFCe:</strong>
                <div :class="['value', {'text-success font-weight-bold': credits.nfce > 0}]">{{ credits.nfce }}</div>
              </div>
            </div>
            <div class="list-card" v-if="hasCommercialInvoiceModule">
              <div class="text-center">
                <strong>Créditos para NFSe:</strong>
                <div :class="['value', {'text-success font-weight-bold': credits.nfse > 0}]">{{ credits.nfse }}</div>
              </div>
            </div>
            <router-link class="el-button el-button--success el-button--mini el-select-full"
                         :to="{name: 'tenant_settings.show', params: {tab: 'creditPacks'}}">
              Comprar Créditos
            </router-link>
          </div>
        </div>

      </div>

      <div slot="headerTitle">
        <span>Notas Fiscais</span>
        <div class="entries-selected d-inline-block">
          <el-dropdown :disabled="selectedList.length === 0"
                       @command="selectedAction">
            <el-button :type="selectedList.length === 0 ? 'default' : 'primary'" size="small">
              <span>Com selecionados:</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  :command="{label: 'Arquivar Notas Selecionadas', action: 'archive', type: 'execute'}">
                <i class="fas fa-archive"></i>
                <span class="ml-2">Arquivar</span>
              </el-dropdown-item>
              <el-dropdown-item :command="{label: 'Emitir Notas Selecionadas', action: 'process', type: 'execute'}">
                <i class="fas fa-satellite-dish"></i>
                <span class="ml-2">Emitir</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th class="list-table-check">
              <el-checkbox @change="checkAll" :value="isAllChecked"/>
            </th>
            <th>Tipo</th>
            <th>Ambiente</th>
            <th width="40">
              <list-thead-column field="number"
                                 label="Número"
                                 align="left"
                                 @update="onFilter"/>
            </th>
            <th :width="hasInvoiceNotApproved ? 300 : 70">Status</th>
            <th>
              <list-thead-column field="created_at"
                                 label="Data Solicitação"
                                 align="left"
                                 @update="onFilter"/>
            </th>
            <th>
              <list-thead-column field="approved_at"
                                 label="Data Aprovação"
                                 align="left"
                                 @update="onFilter"/>
            </th>
            <th>Cliente</th>
            <th align="right">Valor Total</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <th class="list-table-check">
              <el-tooltip effect="dark"
                          :content="`ID ${item.id}`"
                          placement="top">
                <el-checkbox @change="checkItem(item)"
                             v-model="item.checked"
                             :disabled="!!item.archived_at || item.status === 'approved'"/>
              </el-tooltip>
            </th>
            <td class="text-center">
              <invoice-type-label :type="item.type"/>
            </td>
            <td class="text-center">
              <invoice-env-label :env="item.env"/>
            </td>
            <td class="text-center">{{ item.number }}</td>
            <td>
              <div>
                <invoice-status-label :status="item.status"/>
                <v-chip :value="!!item.archived_at"
                        small
                        title="Nota Arquivada"
                        outlined>
                  <i class="fas fa-archive"></i>
                </v-chip>
              </div>
              <div class="pt-1" v-if="item.status === 'not_authorized' || item.status === 'queued'">
                <strong>Motivo:</strong>
                {{ item.error_message || item.response?.mensagem_sefaz || '' }}
              </div>
            </td>
            <td>{{ item.created_at | dateTimeEnToBrFull }}</td>
            <td>
              <span v-if="item.approved_at">{{ item.approved_at | dateTimeEnToBrFull }}</span>
            </td>
            <td>
              {{ item.client_name || 'Consumidor não identificado' }}
              <span v-if="item.cpf"> ({{ item.cpf }})</span>
            </td>
            <td align="right">
              <span v-if="item.amount_en">{{ item.amount_en | currencyFormatter }}</span>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <template v-if="!item.archived_at">
                  <template v-if="['canceled', 'not_authorized'].includes(item.status)">
                    <el-button type="warning"
                               @click="openGenerateInvoicesModal(item.entry.portions[0], item.entry.type)"
                               title="Regerar Nota (NFCe / NFSe)">
                      <i class="fas fa-sync"></i>
                    </el-button>
                  </template>
                  <el-button type="info"
                             v-if="['canceled', 'not_authorized'].includes(item.status) && (item.type === 'nfse' || (item.type === 'nfce' && item.error_message === 'Autorizado o uso da NF-e'))"
                             @click="checkStatus(item)"
                             title="Verificar Status da Nota">
                    <i class="far fa-check-square"></i>
                  </el-button>
                  <el-button type="danger"
                             v-if="item.status === 'created' || item.status === 'approved'"
                             @click="handleCancelModal(item)"
                             title="Cancelar Nota">
                    <i class="fas fa-times"></i>
                  </el-button>
                </template>
                <template v-else>
                  <el-button type="info"
                             @click="onUnarchive(item)"
                             title="Desarquivar Nota">
                    <i class="fas fa-box-open"></i>
                  </el-button>
                </template>
                <el-button type="warning"
                           v-if="item.status === 'approved'"
                           @click="showInvoicePdf(item)"
                           title="Visualizar PDF Nota">
                  <i class="fas fa-file-invoice-dollar"></i>
                </el-button>
                <el-button type="info"
                           v-if="item.status === 'approved'"
                           @click="showInvoiceXml(item)"
                           title="Visualizar XML Nota">
                  <i class="fas fa-file-invoice-dollar"></i>
                </el-button>
                <el-button type="default"
                           size="small"
                           @click="entryIdSelected = item.entry_id"
                           title="Detalhes do Lançamento">
                  <i class="fas fa-search"></i>
                </el-button>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th align="right" colspan="8">Total:</th>
            <th align="right">{{ amountTotal | currencyFormatter }}</th>
            <th></th>
          </tr>
          </tfoot>
        </table>

      </div>

    </ListMain>

    <gripo-dialog :model.sync="cancelModal.show"
                  width="60vw">
      <template slot="header">Cancelar Nota: #{{ cancelModal.invoice.number }}</template>
      <template slot="content">
        <el-form ref="form"
                 :model="cancelModal"
                 label-position="top">
          <el-form-item label="Cliente">{{ cancelModal.invoice.client_name }}</el-form-item>
          <el-form-item label="Data de Solicitação">
            {{ cancelModal.invoice.created_at | dateTimeEnToBrFull }}
          </el-form-item>
          <el-form-item label="Data de Aprovação">
            {{ cancelModal.invoice.approved_at | dateTimeEnToBrFull }}
          </el-form-item>
          <el-form-item label="Motivo do Cancelamento"
                        class="is-required">
            <el-input type="textarea"
                      ref="cancelModalReason"
                      :rows="3"
                      v-model="cancelModal.reason"/>
          </el-form-item>
        </el-form>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="cancelInvoice"
                   :disabled="isSubmitted || $v.cancelModal.$invalid">Confirmar Cancelamento
        </el-button>
      </template>
    </gripo-dialog>

    <show-entry :entry-id.sync="entryIdSelected" entry-type="revenue"/>

    <gripo-dialog :model.sync="showModalGenerateInvoice"
                  :actions-close-btn="false"
                  width="50vw">
      <template slot="header">Gerar Nota Fiscal</template>
      <template slot="content">
        <el-row :gutter="20">
          <el-col :span="12" v-if="hasCommercialReceiptModule">
            <h3 class="mb-1">Nota Fiscal de Produto (NFCe)</h3>
            <el-switch v-model="generateInvoiceData.nfce"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
            <el-switch v-model="generateInvoiceData.nfce_include_cpf"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
            <el-switch v-model="generateInvoiceData.nfce_send_notification"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
          </el-col>
          <el-col :span="12" v-if="hasCommercialInvoiceModule">
            <h3 class="mb-1">Nota Fiscal de Serviço (NFSe)</h3>
            <el-switch v-model="generateInvoiceData.nfse"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Incluir CPF/CNPJ</h3>
            <el-switch v-model="generateInvoiceData.nfse_include_cpf"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
            <h3 class="mt-4 mb-1">Enviar Nota por Email/Whatsapp</h3>
            <el-switch v-model="generateInvoiceData.nfse_send_notification"
                       :active-value="true"
                       :inactive-value="false"
                       active-text="SIM"
                       inactive-text="NÃO"/>
          </el-col>
        </el-row>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <el-button type="primary"
                   :disabled="isSubmitted"
                   @click="generateInvoice">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="archiveInvoices.isModalVisible"
                  width="40vw">
      <template slot="header">Arquivar Todas Notas</template>
      <template slot="content">
        <el-form ref="form"
                 :model="archiveInvoices"
                 label-position="top">
          <el-form-item label="Tipo de Notas">
            <el-checkbox-group v-model="archiveInvoices.type">
              <el-checkbox label="nfce" border v-if="hasCommercialReceiptModule">NFCe</el-checkbox>
              <el-checkbox label="nfse" border v-if="hasCommercialInvoiceModule">NFSe</el-checkbox>
            </el-checkbox-group>
            <div class="el-form-item__error" v-if="archiveInvoices.type.length === 0">
              É necessário selecionar pelo menos um tipo de nota fiscal
            </div>
          </el-form-item>
          <el-form-item label="Status">
            <el-checkbox-group v-model="archiveInvoices.status">
              <el-checkbox label="not_authorized" border>Não Autorizadas</el-checkbox>
              <el-checkbox label="canceled" border>Canceladas</el-checkbox>
            </el-checkbox-group>
            <div class="el-form-item__error" v-if="archiveInvoices.status.length === 0">
              É necessário selecionar pelo menos um status de nota fiscal
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="onArchiveInvoices"
                   :disabled="isSubmitted || archiveInvoices.type.length === 0 || archiveInvoices.status.length === 0">
          Confirmar
        </el-button>
      </template>
    </gripo-dialog>

  </div>
</template>
