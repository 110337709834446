<script>
import FormSaveButton from '../../../../components/form/SaveButton'
import GripoDialog from '../../../../components/common/Dialog'
import TenantFinancial from './Financial'
import Whatsapp from './whatsapp/Index'
import TenantConfig from './config/Index'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import {get as getCep} from '../../../../services/cepService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapActions, mapGetters} from 'vuex'

import {email, required} from 'vuelidate/lib/validators'
import {cloneDeep} from 'lodash'
import {defaultConfigData, getTenantData} from '@app/domains/system/tenant/service'
import {get} from '@utils/storage'
import {confirmDialog, warningDialog} from '@utils/flash'
import DataService from "../../../../services/dataService";
import CreditPack from "./credit_pack/Index.vue";

export default {
  mixins: [helpers],
  components: {CreditPack, FormSaveButton, TenantFinancial, Whatsapp, TenantConfig, GripoDialog},
  data() {
    return {
      tenantBlocked: false,
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingCity: false,
      showPassword: false,
      activateClient: {
        enabled: false,
        showModal: false,
        modules: {
          basic: false,
          professional: false,
          tournament: true,
        }
      },
      plans: {
        basic: {
          key: 'basic',
          active: false,
          icon: 'fas fa-sun',
          colorType: 'secondary',
          name: 'Gestão Básica',
          price: 138.00,
          items: [
            'Reservas e Aulas',
            'Financeiro (Parcial)',
            'Pagamento Online',
            'Notas Fiscais (NFSe)',
            'Reconhecimento Facial',
          ]
        },
        professional: {
          key: 'professional',
          active: false,
          colorType: 'success',
          icon: 'fas fa-sun',
          name: 'Gestão Profissional',
          price: 198.00,
          items: [
            'Reservas e Aulas',
            'Comandas, Produtos e Estoque',
            'Financeiro e Caixa',
            'Planos e Pacotes',
            'Pagamento Online',
            'Notas Fiscais (NFCe e NFSe)',
            'Reconhecimento Facial',
            'Totem de Atendimento',
          ]
        },
        tournament: {
          key: 'tournament',
          active: true,
          colorType: 'info',
          icon: 'fas fa-trophy',
          name: 'Gestão de Torneios',
          pair: 4.80,
          single: 3.40,
        }
      },
      activeTab: 0,
      entriesOverdueCount: 0,
      form: {...defaultConfigData},
      dependencies: {
        cities: [],
        countries: [],
      },
    }
  },
  validations: {
    form: {
      country_id: {required},
      cpf_cnpj: {required},
      name: {required},
      financial_email: {email},
      contact_email: {email}
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    isLegalClient() {
      return this.form.cpf_cnpj ? this.onlyNumbers(this.form.cpf_cnpj).toString().length > 11 : true
    },
    trialTime() {
      return this.tenant && this.tenant.status === 'trial_time'
    },
    hasValidDocument() {
      return !!this.form.cpf_cnpj
    },
    hasValidAddress() {
      return !!this.form.address.cep && !!this.form.address.codigo_ibge
    }
  },
  watch: {
    async 'form.address.cep'(value, oldValue) {
      const cep = this.onlyNumbers(value)
      const cepOldValue = this.onlyNumbers(oldValue)
      if (value && cepOldValue && cepOldValue.length === 7) {
        if (cep.length === 8) {
          const cepFound = await getCep(cep)
          if (cepFound) {
            this.$refs.formAddressNumber.focus()
            this.form.address.street = cepFound.logradouro
            this.form.address.neighborhood = cepFound.bairro
            this.form.address.complement = cepFound.complemento
            this.form.address.codigo_ibge = cepFound.ibge
          }
        } else {
          this.form.address.street = ''
          this.form.address.neighborhood = ''
          this.form.address.number = ''
          this.form.address.complement = ''
          this.form.address.codigo_ibge = ''
        }
      }
    }
  },
  async mounted() {
    this.getDependencies()
    this.tenantBlocked = await get('blocked')
    const activeTab = this.$route.params.tab || null
    const activate = this.$route.query.activate || null
    if (activeTab && !this.trialTime) {
      switch (activeTab) {
        case 'data':
          this.activeTab = 0
          break
        case 'payment':
          this.activeTab = 1
          break
        case 'creditPacks':
          this.activeTab = 4
          break
        default:
          this.activeTab = 0
      }
    }
    if (activate && activate === 'y' && this.trialTime) {
      this.activateClient.enabled = true
    }
    this.getData()
  },
  methods: {
    ...mapActions(['setTenant', 'setLoadingSession']),
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'country'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    handleTenantData(data) {
      if (!data.address) {
        data.address = {...this.form.address}
      }
      if (data.tenant_payment === null) {
        data.tenant_payment = {...cloneDeep(this.form.tenant_payment)}
      } else {
        data.tenant_payment = {...cloneDeep(this.form.tenant_payment), ...data.tenant_payment}
      }
      if (data.tenant_stone_payment === null) {
        data.tenant_stone_payment = {...cloneDeep(this.form.tenant_stone_payment)}
      } else {
        data.tenant_stone_payment = {...cloneDeep(this.form.tenant_stone_payment), ...data.tenant_stone_payment}
      }
      if (data.tenant_nf === null) {
        data.tenant_nf = {...cloneDeep(this.form.tenant_nf)}
      } else {
        data.tenant_nf = {...cloneDeep(this.form.tenant_nf), ...data.tenant_nf}
      }
      if (data.tenant_nf.certificate === null) {
        data.tenant_nf.certificate = {...cloneDeep(this.form.tenant_nf.certificate)}
      }
      if (data.tenant_nf.nfce === null) {
        data.tenant_nf.nfce = {...cloneDeep(this.form.tenant_nf.nfce)}
      }
      if (data.tenant_nf.nfse === null) {
        data.tenant_nf.nfse = {...cloneDeep(this.form.tenant_nf.nfse)}
      }
      this.form = {...cloneDeep(this.form), ...cloneDeep(data)}
      if (this.form.city) {
        this.dependencies.cities.splice(0)
        this.form.address.city_id = data.city_id
        this.form.address.city = {...data.city}
        this.dependencies.cities.push(this.form.city)
      }
    },
    getData(tenant) {
      return new Promise((resolve, reject) => {
        if (tenant !== undefined) {
          this.handleTenantData(tenant)
          resolve()
        } else {
          this.isLoadingData = true
          bus.$emit('show-loader')
          getTenantData()
              .then(response => {
                this.handleTenantData(response)
                if (this.trialTime) {
                  this.plans.basic.price = response.plans.basic
                  this.plans.professional.price = response.plans.professional
                  this.plans.tournament.pair = response.plans.tournament.pair
                  this.plans.tournament.single = response.plans.tournament.single
                }
                bus.$emit('hide-loader')
                this.isLoadingData = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                bus.$emit('hide-loader')
                this.isLoadingData = false
                reject(e)
              })
        }
      })
    },
    onSearchCity(query) {
      return new Promise((resolve, reject) => {
        this.dependencies.cities.splice(0)
        if (query !== '') {
          this.isSearchingCity = true
          let data = {query}
          restfulService.post('city', 'autocomplete', null, data)
              .then((response) => {
                this.dependencies.cities.splice(0)
                this.dependencies.cities = [...this.dependencies.cities, ...response]
                this.isSearchingCity = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                reject()
              })
        }
      })
    },
    async updatePlan($event, plan) {
      if (plan === 'basic' && $event) {
        this.plans['professional'].active = false
      }
      if (plan === 'professional' && $event) {
        this.plans['basic'].active = false
      }
    },
    async onReloadData(tenant) {
      bus.$emit('show-loader')
      await this.getData(tenant)
    },
    async onSend() {
      this.isSubmitted = true
      this.setLoadingSession(true)
      const data = {...cloneDeep(this.form)}
      if (data.cpf_cnpj) {
        data.cpf_cnpj = this.onlyNumbers(data.cpf_cnpj)
      }
      if (data.address.cep) {
        data.address.cep = this.onlyNumbers(data.address.cep)
      }
      if (data.contact_phone) {
        data.contact_phone = this.onlyNumbers(data.contact_phone)
      }
      if (data.contact_email) {
        data.contact_email = data.contact_email.toLowerCase().trim()
      }
      if (data.financial_phone) {
        data.financial_phone = this.onlyNumbers(data.financial_phone)
      }
      if (data.financial_email) {
        data.financial_email = data.financial_email.toLowerCase().trim()
      }
      if (this.trialTime && this.activateClient.enabled) {
        if (!this.plans.basic.active && !this.plans.professional.active && !this.plans.tournament.active) {
          warningDialog({
            title: 'Você precisa selecionar pelo menos 1 módulo para ativar seu clube',
          })
          this.isSubmitted = false
          return
        }
        this.isSubmitted = true
        const {value: confirm} = await confirmDialog({
          title: 'Deseja realmente ativar o seu clube?',
          text: 'Após a confirmação da ativação, a cobrança da mensalidade irá ser gerada!',
          icon: 'warning',
        })
        if (!confirm) {
          this.isSubmitted = false
          return
        }
        data.activate_client = {...this.activateClient}
        if (data.activate_client.enabled) {
          data.activate_client.modules.basic = false
          data.activate_client.modules.professional = false
          data.activate_client.modules.tournament = true
          if (this.plans.basic.active) {
            data.activate_client.modules.basic = true
          } else if (this.plans.professional.active) {
            data.activate_client.modules.professional = true
          }
        }
      }
      bus.$emit('show-loader')
      restfulService.post('tenant', 'data', null, data)
          .then(async (response) => {
            const {title, message} = response
            if (!title && !message) {
              notifyService.success({hideLoader: false})
            }
            await this.getData()
            bus.$emit('reload-session-info', () => {
              this.isSubmitted = false
              this.setLoadingSession(false)
              bus.$emit('hide-loader')
              if (title && message) {
                notifyService.success({hideLoader: false, title, message})
              }
            })
          })
          .catch(e => {
            this.isSubmitted = false
            this.setLoadingSession(false)
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async activatePlan() {
      const data = {
        plan: 'basic'
      }
      let planName = this.plans.basic.name
      let planPrice = this.plans.basic.price
      if (this.plans.professional.active) {
        data.plan = 'professional'
        planName = this.plans.professional.name
        planPrice = this.plans.professional.price
      }
      planPrice = this.currencyFormatter(planPrice)
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente contratar esse plano?',
        text: `Plano: ${planName} | ${planPrice}`,
        icon: 'warning',
      })
      if (!confirm) {
        return
      }
      this.isSubmitted = true
      bus.$emit('show-loader')
      restfulService.post('tenant', 'upgrade-plan', null, data)
          .then((response) => {
            const {title, message} = response
            notifyService.success({hideLoader: false, title, message})
            this.getData()
            bus.$emit('reload-session-info')
            this.activateClient.showModal = false
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    async upgradePlan() {
      const planName = this.plans.professional.name
      const planPrice = this.currencyFormatter(this.plans.professional.price)
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente migrar o seu plano para?',
        text: `Plano: ${planName} | ${planPrice}`,
        icon: 'warning',
      })
      if (!confirm) {
        return
      }
      this.isSubmitted = true
      const data = {plan: 'professional'}
      bus.$emit('show-loader')
      restfulService.post('tenant', 'upgrade-plan', null, data)
          .then((response) => {
            const {title, message} = response
            notifyService.success({hideLoader: false, title, message})
            this.getData()
            bus.$emit('reload-session-info')
            this.activateClient.showModal = false
            this.isSubmitted = false
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <v-tabs v-model="activeTab"
            fixed-tabs
            class="mt-4"
            color="transparent"
            slider-color="secondary">
      <v-tab key="data" :disabled="tenantBlocked && !trialTime">
        <i class="fas fa-suitcase"></i>
        <span class="ml-1">Dados Gerais</span>
      </v-tab>
      <v-tab key="payment" :disabled="trialTime">
        <v-badge color="red" :value="entriesOverdueCount > 0">
          <template v-slot:badge>
            <span>{{ entriesOverdueCount }}</span>
          </template>
          <i class="fas fa-money-bill"></i>
          <span class="ml-1">Financeiro</span>
        </v-badge>
        <span></span>
      </v-tab>
      <v-tab key="configs" :disabled="tenantBlocked || trialTime">
        <i class="fas fa-cog"></i>
        <span class="ml-1">Configurações</span>
      </v-tab>
      <v-tab key="whatsapp" :disabled="tenantBlocked || trialTime">
        <i class="fab fa-whatsapp"></i>
        <span class="ml-1">Whatsapp</span>
      </v-tab>
      <v-tab key="creditPacks" :disabled="tenantBlocked || trialTime">
        <i class="far fa-check-circle"></i>
        <span class="ml-1">Créditos</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item key="data">
        <el-form ref="form"
                 :model="form"
                 label-position="top"
                 @submit.prevent="onSend">

          <el-card class="el-card__save_form box-card mb-4" v-if="trialTime" ref="activate">
            <div slot="header">
              <span>Ativar meu Clube</span>
              <el-switch v-model="activateClient.enabled"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </div>
            <el-alert type="warning" show-icon :closable="false" class="mb-3 pt-2 pb-2"
                      v-if="activateClient.enabled && (!hasValidDocument || !hasValidAddress)">
              <div class="fs-16" v-if="!hasValidDocument">
                Preencha corretamente o CNPJ/CPF de cadastro para ativar o seu clube
              </div>
              <div class="fs-16" v-if="!hasValidAddress">
                Preencha corretamente o endereço de cadastro para ativar o seu clube
              </div>
            </el-alert>
            <div :class="{'disabled': !hasValidDocument || !hasValidAddress}" v-if="activateClient.enabled">
              <h3 class="mb-0 text-center">Selecione o plano que deseja contratar</h3>
              <v-container class="pl-0 pr-0 mb-0" fluid>
                <v-layout class="gap-15 plans-list" wrap>
                  <v-flex sm4 xs12 :class="['text-center v-card plan-item -basic', {'-active': plans.basic.active}]">
                    <div class="v-card__text">
                      <h4 class="title">{{ plans.basic.name }}</h4>
                      <el-switch v-model="plans.basic.active"
                                 @change="updatePlan($event, 'basic')"
                                 :active-value="true"
                                 :inactive-value="false"
                                 active-text="Ativar">
                      </el-switch>
                      <div class="text-center mt-3">
                        <el-tag type="default" effect="dark">
                          <span class="fs-18">
                            <strong>{{ plans.basic.price | currencyFormatter }}/mês</strong>
                          </span>
                        </el-tag>
                      </div>
                      <v-list dense class="plans-list-items">
                        <template v-for="(item, index) in plans.basic.items">
                          <v-list-tile :key="item" avatar>
                            <v-list-tile-content>
                              <v-list-tile-title>{{ item }}</v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                          <v-divider :key="index + 1" v-if="(index + 1) < plans.basic.items.length"/>
                        </template>
                      </v-list>
                    </div>
                  </v-flex>
                  <v-flex sm4 xs12
                          :class="['text-center v-card plan-item -professional', {'-active': plans.professional.active}]">
                    <div class="v-card__text">
                      <h4 class="title">{{ plans.professional.name }}</h4>
                      <el-switch v-model="plans.professional.active"
                                 @change="updatePlan($event, 'professional')"
                                 :active-value="true"
                                 :inactive-value="false"
                                 active-text="Ativar">
                      </el-switch>
                      <div class="text-center mt-3">
                        <el-tag type="success" effect="dark">
                          <span class="fs-18">
                            <strong>{{ plans.professional.price | currencyFormatter }}/mês</strong>
                          </span>
                        </el-tag>
                      </div>
                      <v-list dense class="plans-list-items">
                        <template v-for="(item, index) in plans.professional.items">
                          <v-list-tile :key="item" avatar>
                            <v-list-tile-content>
                              <v-list-tile-title>{{ item }}</v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                          <v-divider :key="index + 1" v-if="(index + 1) < plans.professional.items.length"/>
                        </template>
                      </v-list>
                    </div>
                  </v-flex>
                  <v-flex sm4 xs12 class="text-center v-card plan-item -active">
                    <div class="v-card__text">
                      <h4 class="title">{{ plans.tournament.name }}</h4>
                      <el-switch v-model="plans.tournament.active"
                                 disabled
                                 :active-value="true"
                                 :inactive-value="false"
                                 active-text="Ativar">
                      </el-switch>

                      <div v-if="plans.tournament.active" class="mt-4">
                        <div>
                          <strong>O módulo de torneios é ativado por padrão em todos os clubes.</strong>
                          <p>Cobramos uma taxa por torneio realizado, essa taxa é calculada pelo número de duplas (para
                            categorias em duplas) ou por atleta inscrito (para categorias simples).</p>
                        </div>
                        <div class="mt-3">
                          <el-tag type="success">
                            <span class="fs-18"><strong>{{ plans.tournament.pair | currencyFormatter }}</strong> por dupla inscrita</span>
                          </el-tag>
                        </div>
                        <div class="mt-1">
                          <el-tag type="warning">
                            <span class="fs-18"><strong>{{ plans.tournament.single | currencyFormatter }}</strong> por atleta inscrito</span>
                          </el-tag>
                        </div>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
              <el-alert type="warning" show-icon :closable="false" class="pt-3 pb-3">
                <div class="fs-16">
                  Ao realizar a ativação do seu clube o sistema irá automaticamente iniciar a cobrança da mensalidade
                  usando a data de ativação como o dia de vencimento, para o módulo dos torneios a cobrança é feita após
                  o fim do torneio.<br>
                  Após a ativação do clube você poderá acessar suas cobranças ativas e seu histórico financeiro na
                  aba<strong> Financeiro</strong>.
                </div>
              </el-alert>
            </div>
          </el-card>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-card class="el-card__save_form box-card">
                <div slot="header">
                  <span>Dados do Clube</span>
                </div>
                <div class="mb-3" v-if="!trialTime">
                  <h4 class="mb-1">Módulos Ativos:</h4>
                  <v-layout wrap class="gap-10 el-alert-plans">
                    <template v-for="plan in Object.values(plans)">
                      <v-flex xs6 v-if="form.modules[plan.key]" :key="plan.key">
                        <div role="alert"
                             :class="[`el-alert el-alert--${plan.colorType} is-light is-center`]">
                          <span class="el-alert__icon is-big">
                            <i :class="plan.icon"></i>
                          </span>
                          <div class="el-alert__content text-center">
                            <span class="el-alert__title is-bold pl-2">
                              {{ plan.name }}
                            </span>
                          </div>
                        </div>
                      </v-flex>
                    </template>
                    <v-flex xs6 v-if="form.modules.basic">
                      <button role="alert"
                              type="button"
                              @click="upgradePlan"
                              :class="[`el-alert el-alert--warning plan-request is-light is-center pt-2 pb-2`]">
                        <span class="el-alert__icon">
                          <i class="fas fa-random"></i>
                        </span>
                        <div class="el-alert__content text-center">
                            <span class="el-alert__title is-bold">
                              Migrar para Profissional
                            </span>
                        </div>
                      </button>
                    </v-flex>
                    <v-flex xs6 v-if="!form.modules.basic && !form.modules.professional">
                      <button role="alert"
                              type="button"
                              @click="activateClient.showModal = true"
                              :class="[`el-alert el-alert--warning plan-request is-light is-center`]">
                        <span class="el-alert__icon is-big">
                          <i class="fas fa-plus"></i>
                        </span>
                        <div class="el-alert__content text-center">
                            <span class="el-alert__title is-bold">
                              Contratar Plano de Gestão
                            </span>
                        </div>
                      </button>
                    </v-flex>
                  </v-layout>
                </div>
                <el-form-item label="País"
                              class="is-required mb-0"
                              :class="{ 'el-form-item--error': $v.form.country_id.$error }">
                  <v-select v-model="form.country_id"
                            :items="dependencies.countries"
                            item-value="id"
                            item-text="name"
                            outline
                            single-line
                            :error-messages="validationMessageField($v.form.country_id)"
                            @input="$v.form.country_id.$touch()"
                            @blur="$v.form.country_id.$touch()">
                    <template slot="item" slot-scope="data">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ data.item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-tile-avatar>
                        <img :src="`/static/flags/${item.flag_icon}`" alt="">
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ item.name }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </template>
                  </v-select>
                  <div class="el-form-item__error" v-if="$v.form.country_id.$error">
                    Campo obrigatório
                  </div>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="CPF/CNPJ">
                      <el-input v-model="form.cpf_cnpj"
                                v-mask="['###.###.###-##', '##.###.###/####-##']"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Nome do Clube">
                      <el-input v-model="form.club_name"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="isLegalClient ? 12 : 24">
                    <el-form-item :label="isLegalClient ? 'Nome Fantasia' : 'Nome do Cliente'">
                      <el-input v-model="form.name"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="isLegalClient">
                    <el-form-item label="Razão Social">
                      <el-input v-model="form.legal_name"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Email de Contato"
                                  :class="{ 'el-form-item--error': $v.form.contact_email.$error }">
                      <el-input v-model="form.contact_email"
                                type="email"
                                @input="$v.form.contact_email.$touch()"/>
                      <div class="el-form-item__error" v-if="$v.form.contact_email.$error">
                        Email inválido
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Telefone de Contato">
                      <el-input v-model="form.contact_phone"
                                type="email"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Email Financeiro"
                                  :class="{ 'el-form-item--error': $v.form.financial_email.$error }">
                      <el-input v-model="form.financial_email"
                                type="email"
                                @input="$v.form.financial_email.$touch()"/>
                      <div class="el-form-item__error" v-if="$v.form.financial_email.$error">
                        Email inválido
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Telefone Financeiro">
                      <el-input v-model="form.financial_phone"
                                type="email"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="el-card__save_form box-card">
                <div slot="header">
                  <span>Endereço do Clube</span>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="CEP">
                      <el-input v-model="form.address.cep"
                                v-mask="'#####-###'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Endereço">
                      <el-input v-model="form.address.street"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Número">
                      <el-input v-model="form.address.number" ref="formAddressNumber"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Bairro">
                      <el-input v-model="form.address.neighborhood"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Complemento">
                      <el-input v-model="form.address.complement"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Código IBGE">
                      <el-input v-model="form.address.codigo_ibge"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="Cidade/Estado">
                  <el-select v-model="form.city_id"
                             clearable
                             filterable
                             remote
                             readonly
                             :remote-method="onSearchCity"
                             :loading="isSearchingCity"
                             placeholder="Selecione uma cidade"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full">
                    <el-option v-for="item in dependencies.cities"
                               :key="item.id"
                               :label="item.name_with_state"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-card>
            </el-col>
          </el-row>

          <el-card class="el-card__save_form box-card mt-4">
            <form-save-button :event-click="onSend"
                              :is-disabled="isSubmitted || $v.form.$invalid"
                              :is-submitted="isSubmitted"/>
          </el-card>
        </el-form>
      </v-tab-item>
      <v-tab-item key="payment">
        <tenant-financial :entries-overdue-count="entriesOverdueCount"
                          v-if="activeTab === 1"
                          :active="activeTab === 1"/>
      </v-tab-item>
      <v-tab-item key="configs">
        <tenant-config v-if="form.id && activeTab === 2"
                       :active="activeTab === 2"
                       :form="form"
                       @onSend="onSend"
                       @reloadData="onReloadData"/>
      </v-tab-item>
      <v-tab-item key="whatsapp">
        <whatsapp :entries-overdue-count="entriesOverdueCount"
                  :active="activeTab === 3"
                  v-if="activeTab === 3"/>
      </v-tab-item>
      <v-tab-item key="creditPacks">
        <credit-pack :active="activeTab === 4"
                     v-if="activeTab === 4"/>
      </v-tab-item>
    </v-tabs-items>

    <gripo-dialog :model.sync="activateClient.showModal"
                  width="70vw">
      <template v-slot:header>Selecione o plano desejado</template>
      <template v-slot:content>
        <v-container class="pl-0 pr-0 mb-0" fluid>
          <v-layout class="gap-15 plans-list -center" wrap>
            <v-flex xs6 :class="['text-center v-card plan-item -basic', {'-active': plans.basic.active}]">
              <div class="v-card__text">
                <h4 class="title">{{ plans.basic.name }}</h4>
                <el-switch v-model="plans.basic.active"
                           @change="updatePlan($event, 'basic')"
                           :active-value="true"
                           :inactive-value="false"
                           active-text="Ativar">
                </el-switch>
                <div class="text-center mt-3">
                  <el-tag type="default" effect="dark">
                          <span class="fs-18">
                            <strong>{{ plans.basic.price | currencyFormatter }}/mês</strong>
                          </span>
                  </el-tag>
                </div>
                <v-list dense class="plans-list-items">
                  <template v-for="(item, index) in plans.basic.items">
                    <v-list-tile :key="item" avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ item }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider :key="index + 1" v-if="(index + 1) < plans.basic.items.length"/>
                  </template>
                </v-list>
              </div>
            </v-flex>
            <v-flex xs6
                    :class="['text-center v-card plan-item -professional', {'-active': plans.professional.active}]">
              <div class="v-card__text">
                <h4 class="title">{{ plans.professional.name }}</h4>
                <el-switch v-model="plans.professional.active"
                           @change="updatePlan($event, 'professional')"
                           :active-value="true"
                           :inactive-value="false"
                           active-text="Ativar">
                </el-switch>
                <div class="text-center mt-3">
                  <el-tag type="success" effect="dark">
                    <span class="fs-18">
                      <strong>{{ plans.professional.price | currencyFormatter }}/mês</strong>
                    </span>
                  </el-tag>
                </div>
                <v-list dense class="plans-list-items">
                  <template v-for="(item, index) in plans.professional.items">
                    <v-list-tile :key="item" avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ item }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider :key="index + 1" v-if="(index + 1) < plans.professional.items.length"/>
                  </template>
                </v-list>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="text-center" style="max-width: 50vw; margin: 0 auto;">
          <h3>
            Consulte o nosso site para conferir as taxas adicionais para os módulos de: Pagamento Online,
            Notas Fiscais, Reconhecimento Facial e Mensagens do Whatsapp
          </h3>
          <div>
            <a class="el-button el-button--warning" href="https://www.gripo.com.br/#taxas-adicionais" target="_blank">
              Visitar Site
            </a>
          </div>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer/>
        <el-button type="success"
                   :disabled="isSubmitted || (!plans.basic.active && !plans.professional.active)"
                   @click="activatePlan">
          <i class="fas fa-check"></i>
          <span class="ml-1">Confirmar Contratação do Plano</span>
        </el-button>
      </template>
    </gripo-dialog>

  </div>
</template>
