<script>
import GripoDialog from '@components/common/Dialog'
import CreditPackTypeLabel from '../labels/CreditPackTypeLabel'
import CreditPackSenderTypeLabel from '../labels/CreditPackSenderTypeLabel'
import CreditPackNfTypeLabel from '../labels/CreditPackNfTypeLabel'
import CreditPackStatusLabel from '../labels/CreditPackStatusLabel'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import QRCode from 'qrcode-svg'
import {confirmDialog} from '@utils/flash'

export default {
  components: {
    GripoDialog,
    CreditPackTypeLabel,
    CreditPackSenderTypeLabel,
    CreditPackNfTypeLabel,
    CreditPackStatusLabel,
  },
  name: 'credit-pack',
  data() {
    return {
      isSubmitted: false,
      packs: {
        filters: {
          dates: [],
          type: '',
          status: 'active',
        },
        data: [],
        credits: 0,
        pendingMessages: 0,
      },
      dependencies: {
        quantities: [100, 500, 1000, 5000],
        credit_packs_types: [
          {id: 'message', name: 'Mensagens do Whatsapp'},
          {id: 'nf', name: 'Notas Fiscais'},
          {id: 'facial_id', name: 'Reconhecimento Facial'},
        ]
      },
      showAddPack: false,
      newPack: {
        type: 'message',
        client_id: '',
        status: 'created',
        target_id: '',
        target_type: '',
        amount: 0,
        quantity: 1000,
        custom_quantity: 10000,
        expires_at: '',
        settings: {
          nf_type: 'nfce'
        }
      },
      payment: {
        amount: 0,
        boleto: {
          showModal: false,
          base64Pdf: '',
          urlPdf: '',
          generateTries: 0
        },
        pix: {
          showModal: false,
          base64Image: '',
          qrcode: ''
        }
      },
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasCommercialReceiptModule', 'hasCommercialInvoiceModule', 'hasFaceReconModuleEnabled']),
    showCustomQuantity() {
      return this.newPack.quantity === '0'
    },
    packDiscount() {
      let quantity = this.newPack.quantity
      if (this.showCustomQuantity) {
        quantity = this.newPack.custom_quantity
      }
      if (quantity >= 500 && quantity < 1000) {
        return 0.05
      } else if (quantity >= 1000 && quantity < 5000) {
        return 0.1
      } else if (quantity >= 5000) {
        return 0.15
      } else {
        return 0
      }
    },
    packAmount() {
      let amount = this.newPack.quantity * this.creditCost
      if (this.showCustomQuantity) {
        amount = this.newPack.custom_quantity * this.creditCost
      }
      if (this.packDiscount > 0) {
        amount = amount - (amount * this.packDiscount)
      }
      return amount
    },
    tenantSettingsSenderType() {
      return this.tenant.settings?.whatsapp_sender?.type || 'gripo'
    },
    creditCost() {
      if (this.newPack.type === 'message') {
        return this.tenantSettingsSenderType === 'gripo' ? 0.2 : 0.1
      } else if (this.newPack.type === 'nf') {
        if (this.newPack.settings.nf_type === 'nfse') {
          return 0.1
        }
        return 0.04
      } else if (this.newPack.type === 'facial_id') {
        return 0.15
      }
      return 0
    },
    creditPacksTypesFiltered() {
      return this.dependencies.credit_packs_types.filter(item => {
        if (item.id === 'nf' && !this.hasCommercialReceiptModule && !this.hasCommercialInvoiceModule) {
          return false
        } else if (item.id === 'facial_id' && !this.hasFaceReconModuleEnabled) {
          return false
        }
        return true
      })
    }
  },
  async mounted() {
    this.loadCreditPacks()
  },
  methods: {
    loadCreditPacks() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        const data = {...this.packs.filters}
        restfulService.post('tenant', 'credit-packs', null, data)
            .then(async (response) => {
              this.packs.data = [...response.packs]
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(e => {
              bus.$emit('hide-loader')
              console.log(e)
              reject()
            })
      })
    },
    async tenantPayment(pack) {
      this.isSubmitted = true
      bus.$emit('show-loader', 'Aguarde...<br>Estamos gerando seu PIX')
      const data = {
        entry_portion_id: pack.entry_portion_id,
        payment_method: 'pix'
      }
      try {
        const response = await restfulService.post('tenant', 'entry-payment', null, data)
        this.handleTransaction(response)
      } catch (e) {
        this.isSubmitted = false
        bus.$emit('hide-loader')
        console.log(e)
      }
    },
    handleTransaction(transaction) {
      this.payment.amount = transaction.amount
      this.payment.pix.base64Image = transaction.base64_image
      this.payment.pix.qrcode = transaction.pix_qr_code
      this.payment.pix.showModal = true
      setTimeout(() => {
        const svg = (new QRCode({
          content: transaction.pix_qr_code,
          padding: 0,
          width: 256,
          height: 256,
          color: '#000000',
          background: '#ffffff',
          ecl: 'M',
        })).svg()
        this.$refs['pixContainer'].innerHTML = svg
        this.isSubmitted = false
        bus.$emit('hide-loader')
      }, 200)
    },
    resetPayment() {
      this.payment.amount = 0
      this.payment.pix.base64Image = ''
      this.payment.pix.qrcode = ''
    },
    resetPaymentPix() {
      this.resetPayment('pix')
    },
    copyText(ref) {
      const codeToCopy = this.$refs[ref]
      navigator.clipboard.writeText(codeToCopy.value)
      this.$notify.success({
        title: 'Sucesso!',
        message: 'Código QR Code copiado!',
        duration: 3000,
      })
    },
    onAddPack() {
      this.newPack.quantity = 500
      this.showAddPack = !this.showAddPack
    },
    async purchaseMessagePack() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente comprar esse pacote?',
        icon: 'warning',
      })
      if (confirm) {
        bus.$emit('show-loader')
        const data = {...this.newPack}
        if (this.showCustomQuantity) {
          data.quantity = data.custom_quantity
          delete data.custom_quantity
        }
        restfulService.post('tenant', 'purchase-credit-pack', null, data)
            .then((response) => {
              notifyService.success({message: 'O registro de compra do pacote for registrado, realize o pagamento para ativar o pacote'})
              this.loadCreditPacks()
              this.onAddPack()
              this.handleTransaction(response)
            })
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              bus.$emit('hide-loader')
            })
      }
    }
  }
}
</script>

<template>
  <div>
    <el-card :class="['el-card__save_form']">
      <div slot="header">
        <span>Pacotes de Créditos</span>
        <div>
          <el-button size="mini" type="success" @click="onAddPack">
            <i class="fas fa-plus"></i>
            <span>Comprar Novo Pacote</span>
          </el-button>
        </div>
      </div>
      <el-form :inline="true" :model="packs.filters">
        <h4 class="mb-1">Filtrar por:</h4>
        <el-form-item label="Tipo de Pacote">
          <el-select v-model="packs.filters.type"
                     @change="loadCreditPacks"
                     placeholder="Filtrar por Tipo"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full">
            <el-option label="Não filtrar" value=""/>
            <el-option v-for="item in creditPacksTypesFiltered"
                       :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Status">
          <el-select v-model="packs.filters.status"
                     @change="loadCreditPacks"
                     placeholder="Filtrar por Status"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full">
            <el-option label="Ativo" value="active"/>
            <el-option label="Utilizado/Expirado" value="expired"/>
            <el-option label="Aguardando Pagamento" value="created"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Data de Criação" v-if="packs.filters.status === 'expired'">
          <el-date-picker
              v-model="packs.filters.dates"
              @change="loadCreditPacks"
              type="monthrange"
              format="MMM/yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Filtrar por Data de Criação">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <table class="custom-table-list responsive-data-table" v-if="packs.data.length">
        <thead>
        <tr>
          <th class="text-center">Tipo de Pacote</th>
          <th class="text-center">Status</th>
          <th class="text-center">Quantidade / Utilizado</th>
          <th align="right">Valor</th>
          <th class="text-center">Data de Criação</th>
          <th class="text-center">Data de Ativação</th>
          <th class="text-center">Expira em</th>
          <th class="btn-actions">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in packs.data" :class="{'overdue': item.overdue}">
          <template v-if="!$vuetify.breakpoint.xs">
            <td class="text-center">
              <credit-pack-type-label :type="item.type"/>
              <credit-pack-sender-type-label :sender-type="item.settings.sender_type"
                                             v-if="item.type === 'message'"
                                             class="ml-1"/>
              <credit-pack-nf-type-label :nf-type="item.settings.nf_type"
                                         v-if="item.type === 'nf'"
                                         class="ml-1"/>
            </td>
            <td class="text-center">
              <credit-pack-status-label :status="item.status"/>
            </td>
            <td class="text-center">{{ item.quantity }} / {{ item.quantity_used }}</td>
            <td align="right">
              <template v-if="item.settings.group === 'purchase'">
                {{ item.amount | currencyFormatter }}
              </template>
              <template v-else>
                Sem custo
              </template>
            </td>
            <td class="text-center">{{ item.stored_at | dateTimeEnToBr }}h</td>
            <td class="text-center">
              <template v-if="item.activated_at">
                {{ item.activated_at | dateTimeEnToBr }}h
              </template>
            </td>
            <td class="text-center">
              <el-tag type="danger" size="mini">
                <template v-if="item.settings.group === 'monthly_free'">
                  {{ item.expires_at | dateTimeEnToBr }}h
                </template>
                <template v-else>Não expira</template>
              </el-tag>
            </td>
            <td class="list-table-nowrap">
              <template v-if="item.settings.group === 'purchase' && item.status === 'created'">
                <el-button type="success"
                           size="small"
                           @click="tenantPayment(item, 'pix')"
                           :disabled="isSubmitted"
                           title="Pagar com  PIX">
                  <i class="fas fa-qrcode"></i>
                  <span>Pagar com PIX</span>
                </el-button>
              </template>
            </td>
          </template>
          <template v-else>
            <td>
              <ul class="flex-content">
                <li class="flex-item" data-label="Tipo">
                  <credit-pack-type-label :type="item.type"/>
                  <credit-pack-sender-type-label :sender-type="item.settings.sender_type"
                                                 v-if="item.type === 'message'"
                                                 class="ml-1"/>
                  <credit-pack-nf-type-label :nf-type="item.settings.nf_type"
                                             v-if="item.type === 'nf'"
                                             class="ml-1"/>
                </li>
                <li class="flex-item" data-label="Status">
                  <credit-pack-status-label :type="item.status"/>
                </li>
                <li class="flex-item" data-label="Utilização">
                  {{ item.quantity_used }} de {{ item.quantity }}
                </li>
                <li class="flex-item" data-label="Valor" v-if="item.settings.group === 'purchase'">
                  {{ item.amount | currencyFormatter }}
                </li>
                <li class="flex-item" data-label="Data de Ativação">
                <span v-if="item.activated_at">
                  {{ item.activated_at | dateTimeEnToBr }}h
                </span>
                </li>
                <li class="flex-item" data-label="Expira em">
                  <el-tag type="danger" size="mini">
                    <template v-if="item.settings.group === 'monthly_free'">
                      {{ item.expires_at | dateTimeEnToBr }}h
                    </template>
                    <template v-else>Não expira</template>
                  </el-tag>
                </li>
                <li class="flex-item" data-label="Ações" v-if="item.status === 'created'">
                  <el-button type="success"
                             size="small"
                             @click="tenantPayment(item, 'pix')"
                             :disabled="isSubmitted"
                             title="Pagar com PIX">
                    <i class="fas fa-qrcode"></i>
                    <span>Pagar com PIX</span>
                  </el-button>
                </li>
              </ul>
            </td>
          </template>
        </tr>
        </tbody>
      </table>
      <div v-else class="text-center">
        <el-divider/>
        Nenhuma pacote foi encontrado!
      </div>
    </el-card>

    <gripo-dialog :model.sync="showAddPack"
                  width="70vw">
      <template v-slot:header>
        Comprar novo pacote
      </template>
      <template v-slot:content>
        <el-form :model="newPack" ref="form" label-position="top">
          <el-row :gutter="20">
            <el-col :span="14">
              <el-form-item label="Tipo de Pacote"
                            class="is-required">
                <el-radio-group v-model="newPack.type">
                  <template v-for="type in creditPacksTypesFiltered">
                    <el-radio-button :key="type.id"
                                     :label="type.id">
                      {{ type.name }}
                    </el-radio-button>
                  </template>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-alert type="warning"
                        v-if="newPack.type === 'message'"
                        show-icon
                        :closable="false">
                <div class="fs-16">
                  Seu clube está atualmente configurado para
                  enviar mensagens utilizando o número do
                  <strong>{{ tenantSettingsSenderType === 'gripo' ? 'Gripo' : 'Clube' }}</strong>
                </div>
              </el-alert>
              <el-form-item label="Tipo de Nota Fiscal"
                            class="is-required"
                            v-if="newPack.type === 'nf'">
                <el-radio-group v-model="newPack.settings.nf_type">
                  <el-radio-button label="nfce">NFCe</el-radio-button>
                  <el-radio-button label="nfse">NFSe</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mt-3">
            <el-col :span="14">
              <el-form-item label="Quantidade"
                            class="is-required">
                <el-radio-group v-model="newPack.quantity">
                  <template v-for="quantity in dependencies.quantities">
                    <el-radio-button :key="quantity"
                                     :label="quantity">
                      {{ quantity }}
                    </el-radio-button>
                  </template>
                  <el-radio-button label="0">Customizado</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Quantidade Customizada"
                            style="max-width: 300px;"
                            v-if="showCustomQuantity"
                            class="is-required">
                <el-input-number v-model="newPack.custom_quantity" :min="1" :step="1"/>
              </el-form-item>
            </el-col>
            <el-col :span="10" class="text-center">
              <div class="fs-16 mb-1">
                <span class="font-weight-normal">Valor por Crédito:</span>
                <strong class="ml-1">{{ creditCost | currencyFormatter }}</strong>
              </div>
              <div class="fs-16 mb-1" v-if="packDiscount > 0">
                <span class="font-weight-normal">Desconto:</span>
                <strong class="ml-1">{{ 100 * packDiscount }}%</strong>
              </div>
              <div class="fs-18">
                <span class="font-weight-normal">Valor Total:</span>
                <strong class="ml-1">{{ packAmount | currencyFormatter }}</strong>
              </div>
              <el-alert type="success"
                        show-icon
                        class="mt-4"
                        :closable="false">
                <div class="fs-16">
                  Após a confirmação da compra do pacote, será gerado um PIX para que o pagamento seja efetuado!
                </div>
              </el-alert>
              <el-alert type="info"
                        show-icon
                        class="mt-2"
                        :closable="false">
                <div class="fs-16">
                  Pacotes comprados não expiram, aproveite os descontos para comprar um pacote de créditos grande!
                </div>
              </el-alert>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer/>
        <el-button type="success"
                   :disabled="isSubmitted"
                   @click="purchaseMessagePack">
          <i class="fas fa-check"></i>
          <span class="ml-1">Confirmar Compra do Pacote</span>
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="payment.pix.showModal"
                  max-width="450"
                  :actions-close-btn="false"
                  :persistent="false"
                  :on-close="resetPaymentPix">
      <template v-slot:header>Pagar via PIX</template>
      <template v-slot:content>
        <h2 class="text-center mb-5">
          Valor: {{ payment.amount | currencyFormatter }}
        </h2>
        <div ref="pixContainer" class="text-center">
          Gerando QR Code...
        </div>
        <div class="mt-4">
          <h4>Código PIX</h4>
          <p>{{ payment.pix.qrcode }}</p>
        </div>
        <input type="text" :value="payment.pix.qrcode" ref="pixCopyCodeText"
               style="opacity: 0;position: absolute;">
        <v-btn color="blue" block class="white--text" @click="copyText('pixCopyCodeText')">
          Copiar Código PIX
        </v-btn>
      </template>
    </gripo-dialog>
  </div>
</template>
