<script>
import NotificationList from './List'
import WhatsappSettings from '../config/whatsapp/Index.vue'

export default {
  components: {
    NotificationList,
    WhatsappSettings
  },
  name: 'whatsapp',
  props: ['active']
}
</script>

<template>
  <div>
    <whatsapp-settings />
    <notification-list v-if="active" class="mt-4"/>
  </div>
</template>
