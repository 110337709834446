import {setSession, setToken} from '../http'
import * as TYPES from './types'

export default {
    [TYPES.APP_LOADED](state, payload) {
        state.appLoaded = payload
    },
    [TYPES.IDLE](state, payload) {
        state.idle = payload
    },
    [TYPES.LOADING_SESSION](state, payload) {
        state.isLoadingSession = payload
    },
    // Global loader
    [TYPES.LOADER_SHOW](state, payload) {
        state.isLoading = payload
    },
    [TYPES.LOADER_MESSAGE](state, payload) {
        state.loadingMessage = payload
    },
    // Sidebar
    [TYPES.TOGGLE_SIDEBAR](state, payload) {
        state.showSidebar = payload
    },
    // Snackbar notification
    [TYPES.NOTIFICATION](state, payload) {
        state.notification = payload
    },
    // Auth
    [TYPES.AUTH_USER](state, payload) {
        state.user = payload
    },
    [TYPES.AUTH_TOKEN](state, payload) {
        setToken(payload)
        state.token = payload
    },
    [TYPES.AUTH_TENANT](state, payload) {
        state.tenant = payload
    },
    [TYPES.AUTH_SESSION](state, payload) {
        setSession(payload)
        state.session = payload
    },
    [TYPES.AUTH_PERMISSIONS](state, payload) {
        state.permissions = payload
    },
    [TYPES.AUTH_ROLES](state, payload) {
        state.roles = payload
    },
    // Main list component
    [TYPES.LIST_OPTIONS](state, payload) {
        state.listStore.options = payload
    },
    [TYPES.LIST_DATA](state, payload) {
        state.listStore.listData = payload
    },
    [TYPES.LIST_SORT](state, payload) {
        state.listStore.listSort = payload
    },
    [TYPES.LIST_FILTERS](state, payload) {
        state.listStore.listFilters = payload
    },
    [TYPES.LIST_CURRENT_PAGE](state, payload) {
        state.listStore.listCurrentPage = payload
    },
    [TYPES.LIST_MODE](state, payload) {
        state.listStore.listMode = payload
    },
    // Dependencies
    [TYPES.GLOBAL_DEPENDENCIES](state, payload) {
        state.globalDependencies = payload
    }
}
