<script>
export default {
  name: 'credit-pack-sender-type-label',
  props: {
    senderType: {
      type: String,
      default: 'gripo'
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  computed: {
    label() {
      if (this.senderType === 'gripo') {
        return 'Gripo'
      }
      return 'Clube'
    }
  }
}
</script>

<template>
  <el-tag :size="size" type="warning">{{ label }}</el-tag>
</template>
